import { createSlice } from "@reduxjs/toolkit";
export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    inputValues: {
      couponCode: "",
    },
    inputErrors: {
      couponCodeErr: "",
    },
    isLoading: false,
    products: [],
    cartItems: [],
    faq:[],
    savingCart: false,
    test:1,
    productImages:[],
    percentages:[],
    currencies:[],
    sellers:[],
    copounCodes:[],
    CopounDiscount:[],
    checkUsers:[],
    couponSucessMessage:"",
    error:"",
    shippingValue:{},
    shippingStatus:false,
    statusCart:false,
    savewoutLogincart:false,
    statusOrder:false,
    EumemberStatus:false
  },
  reducers: {
    setEUMemberStatus:(state, action) => {
      state.EumemberStatus = action.payload;
    },
    setFaq: (state, action) => {
      state.faq = action.payload;
    },
    setStatusOrder: (state, action) => {
      state.statusOrder = action.payload;
    },
    setStatuscart: (state, action) => {
      state.statusCart = action.payload;
    },
    setSavewoutLogincart:(state, action) => {
      state.savewoutLogincart = action.payload;
    },
    setSingleShipping:(state, action) => {
      state.shippingValue = action.payload;
    },
    setShippingStatus:(state, action) => {
      state.shippingStatus = action.payload;
    },
    setCouponCode: (state, action) => {
      state.inputValues.couponCode = action.payload;
    },
    setError:(state,action) => {
      state.error = action.payload;
  },
    setCouponSucessMessage: (state, action) => {
      state.couponSucessMessage = action.payload;
    },
    setcouponCodeErr: (state, action) => {
      state.inputErrors.couponCodeErr = action.payload;
    },
    setcopounCodes: (state, action) => {
      state.copounCodes = action.payload;
    },
    setCopounDiscount: (state, action) => {
      state.CopounDiscount = action.payload;
    },
    setCheckuserId: (state, action) => {
      state.checkUsers = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    addProduct: (state, action) => {
      state.products.push(action.payload);
    },
    setSavingCart: (state, action) => {
      state.savingCart = action.payload;
    },
    setPercentageDiscounts: (state, action) => {
      state.percentages = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setSellers: (state, action) => {
      state.sellers = action.payload;
    },
    setTest: (state, action) => {
      state.savingCart = action.payload;
    },
    addProductImage: (state, action) => {
      state.productImages.push(action.payload)  ;
    },
    clearCart: (state) => {
      state.cartItems =[]
    },
    
    addCartItem: (state, action) => {
      let itemExist = false;

      state.cartItems.forEach((cartItem) => {
        if (cartItem.product.id === action.payload.product.id) itemExist = true;
      });
      if (!itemExist)
       state.cartItems.push(action.payload);
    },
    removeCartItem: (state, action) => {
      const newCartItems = state.cartItems.filter(
        (cartItem) => cartItem.product.id !== action.payload
      );
      state.cartItems = newCartItems;
    },
    increaseQuantity: (state, action) => {
      state.cartItems.forEach((cartItem) => {
        if (cartItem.product.id === action.payload) {
          cartItem.quantity += 1;
        }
      });
    },
    decreaseQuantity: (state, action) => {
      state.cartItems.forEach((cartItem) => {
        if (cartItem.product.id === action.payload) {
          if (cartItem.quantity > 1) {
            cartItem.quantity -= 1;
          }
        }
      });
    },
  },
});
