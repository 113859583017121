import React from "react";
import cartWhite from "../../new-assets/cart-white.png";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { cartSlice } from "../../slices/cart";
import { imageurl } from "../../utils/url";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import star from "../../new-assets/start.png";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { adminServiceApi } from "../serviceApi/adminServiceApi";
import { rightDrawerSlice } from "../../slices/right_drawer";
import '../scss-styles/newProductCard.scss'
import { useTranslation } from 'react-i18next';
import { productsApi } from "../serviceApi/productsApi";
const shoppingCartIcon = <FontAwesomeIcon icon={faShoppingCart} />;
const ProductCard = ({
  details, }) => {
  const dispatch = useDispatch();
  const rightDrawerActions = rightDrawerSlice.actions;
  const md = useMediaQuery({ query: "(max-width: 992px)" });
  const currencies = useSelector((state) => state.cart.currencies);
  const cartActions = cartSlice.actions;
  const [productImage, setProductImage] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    userServiceApi.getParamMethod("getProductImages", details.id).then(
      (response) => {
        if (response.data.productImages.length > 0) {
          setProductImage(
            `${imageurl}images/${response.data.productImages[0].product_image}`
          );
        }
      },
      (error) => {  dispatch(cartActions.setError(error));}
    );
    adminServiceApi.getmethod("getLatestCurrency").then(
      (response) => {
        dispatch(cartActions.setCurrencies(response.data.currency));
      },
      (error) => {
        dispatch(cartActions.setError(error));
      }
    );
    productsApi.getmethod("getPercentageDiscount").then(
      (response) => {
        dispatch(cartActions.setPercentageDiscounts(response.data.result));
      },
      (error) => {
        dispatch(cartActions.setError(error));
      }
    );
  }, []);
  const productRating = [];
  for (let m = 0; m < details.totalRate / 20; m++) {
    productRating.push({ rates: m })
  }
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  function dec2hex (dec) {
    return dec.toString(16).padStart(2, "0")
  }
  function generateId (len) {
    var arr = new Uint8Array(( len|| 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }
  const generatedID=generateId(20);
  // const languages = useSelector((state) => state.rightDrawer.languages);
  const languages=localStorage.getItem("lang");
  const percentages = useSelector((state) => state.cart.percentages);
  const totalVat = () => {
      var totalVat = percentages.percentage;
    return totalVat;
  };
  return (
    <div className="newProductCard">
      {languages==="de"?<Link to={`/Produkt-detail/${generatedID+"."+details.id}`}>
        {productImage ? (
          <img className="productImage" src={productImage} alt="" />
        ) : (
          ""
        )}
      </Link>:<Link to={`/Product-Detail/${generatedID+"."+details.id}`}>
        {productImage ? (
          <img className="productImage" src={productImage} alt="" />
        ) : (
          ""
        )}
      </Link>} 
      <div>
        <span className="name">{details.Name}</span>
        <p className="desc"></p>
        <div className="rating">
          {/* {productRating.length === 0
            ? ""
            : productRating.map((item) => (
              <img src={star} alt={item.rates} className="star" />
            ))} */}
        </div>
        <div className="price">
          <p style={{width:"80%"}}>{currencies.CurrencySymbol}{details.Price}{" "}{t('order.inc')}{" "}{totalVat()}%{" "}{t('order.vatDescription')}</p>
          <div className="green" onClick={(e) => {
            // if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
            //   showRightDrawer("login");
            //   return;
            // } else {
              e.stopPropagation();
              dispatch(cartActions.addProduct(details));
              dispatch(
                cartActions.addCartItem({ product: details, quantity: 1 })
              );
              toast("Product added to cart", {
                position: "top-right",
                autoClose: 1700,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            // }
          }}>
            <img src={cartWhite} alt="add to cart" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
