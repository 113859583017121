import { createSlice } from "@reduxjs/toolkit";
export const reviewSlice = createSlice({
  name: "review",
  initialState: {
    inputValues: {
      message: "",
      name:"",
      email:"",
    },

    inputErrors: {
      messageErr: "",
    },
    isLoading: false,
    product:[],
    productD:[],
    success:"",
    error:""
  },
  reducers: {
    setMessage: (state, action) => {
      state.inputValues.message = action.payload;
    },
    setError:(state,action) => {
      state.error = action.payload;
  },
    setName: (state, action) => {
      state.inputValues.name = action.payload;
    },
    setEmail: (state, action) => {
      state.inputValues.email = action.payload;
    },
    setMessageErr: (state, action) => {
      state.inputErrors.messageErr = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProduct:(state,action) => {
      state.product = action.payload;
    },
    setProductD:(state,action) => {
      state.productD = action.payload;
    },
    setSuccess:(state,action) => {
      state.success = action.payload;
    },
  },
});
