import { useEffect } from "react";
import { cartSlice } from "../../slices/cart";
import { useDispatch, useSelector } from "react-redux";
import { productsApi } from "../serviceApi/productsApi";
const FetchData = () => {
  const cartActions = cartSlice.actions;
  const isUserLogged = useSelector((state) => state.login.isUserLogged);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchCart();
  }, [isUserLogged]);
  const fetchCart = () => {
    // dispatch(cartActions.clearCart());
    if (!localStorage.getItem("user_id") || !localStorage.getItem("token"))
      return;
    const userId = localStorage.getItem("user_id");
      productsApi.getCarts(userId).then(
      (response) => {
        if (response.data.User) {
          let cartFromDb = response.data.User;
          let cartItemsForState = [];
          if (cartFromDb.length > 0) {
            cartFromDb.forEach((item) => {
              // axios.get(`${url}product/getProduct/${item.product_id}`, cartFromDb)
              productsApi.getProduct(item.product_id,cartFromDb).then(
                  (response) => {
                    if (response.data.result) {
                      let product = response.data.result;
                      const cartItem = {
                        product: product,
                        quantity: parseInt(item.quantity),
                      };
                      dispatch(cartActions.addCartItem(cartItem));
                    }
                  },
                  (error) => {
                    dispatch(cartActions.setError(error));
                  }
                );
            });
          }
        }
      },
      (error) => {
        dispatch(cartActions.setError(error));
      }
    );
  };
  return <></>;
};

export default FetchData;
