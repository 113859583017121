import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './index.scss';
// import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from './reportWebVitals';
import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit';
import { rightDrawerSlice } from './slices/right_drawer';
import { Provider } from 'react-redux';
import { productsSlice } from './slices/products';
import { registerSlice } from './slices/register';
import { loginSlice } from './slices/login';
import { cartSlice } from './slices/cart';
import { profileSlice } from './slices/profile';
import { verifySlice } from './slices/verfiy';
import { slipSlice } from './slices/slip';
import { teamSlice } from './slices/team';
import { contactSlice } from './slices/contact';
import { orderSlice } from './slices/order';
import { paymentSlice } from './slices/paymentCompleted';
import { stripeSlice } from './slices/stripePayment';
import { aboutSlice } from './slices/aboutus';
import { recipeSlice } from './slices/recipe';
import { partnerSlice } from './slices/partner';
import { reviewSlice } from './slices/review';
import { categorySlice } from './slices/category';
import './i18n/languageconfig'
const store = configureStore({
  reducer:{
    rightDrawer:rightDrawerSlice.reducer,
    products:productsSlice.reducer,
    register:registerSlice.reducer,
    login:loginSlice.reducer,
    cart:cartSlice.reducer,
    profile:profileSlice.reducer,
    verify:verifySlice.reducer,
    slip:slipSlice.reducer,
    team:teamSlice.reducer,
    contact:contactSlice.reducer,
    order:orderSlice.reducer,
    payment:paymentSlice.reducer,
    stripePayment:stripeSlice.reducer,
    aboutus:aboutSlice.reducer,
    recipe:recipeSlice.reducer,
    partners:partnerSlice.reducer,
    review:reviewSlice.reducer,
    category:categorySlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}> <App /></Provider> 
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
