import { useEffect} from "react";
import {Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { teamSlice } from "../../slices/team";
import Card from "react-bootstrap/Card";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { CircularProgress } from "@material-ui/core";
const EnviornmentalPolicyContent = ({ }) => {
  const policyActions = teamSlice.actions;
  const dispatch = useDispatch();
  useEffect(() => {
    const langugess=localStorage.getItem("lang");
    if(langugess=="en"){
      var documentNames="Enviornmental Policy(en)";
    }
      else{
       documentNames="Enviornmental Policy(de)";
    }
    userServiceApi.getParamMethod("getDocuments",documentNames).then(
      (response) => {
        dispatch(policyActions.setArePoliciesLoading(false));
        dispatch(policyActions.setPoliciesDocument(response.data.result));
      },
      (error) => {
        dispatch(policyActions.setError(error));
      }
    );
  },[]);
  const documentpolicies = useSelector((state) => state.team.documentpolicies);
  const { t } = useTranslation();
  window.scrollTo(0, 0)
  const md = useMediaQuery({ query: "(max-width: 1300px)" });
  return (
    <>
    {md ?<div className="mobilePrivacyContent">
    <Card>
    {/* <Card.Header> <Row><b>{t('privacyPolicy.privacy')}</b></Row></Card.Header> */}
      <div className="contents">
      <Card.Body>
        <Row>{!documentpolicies? "No Document Found!.":
          <div className="post__description" dangerouslySetInnerHTML={{ __html: documentpolicies.Description}}  />}
        </Row>
        </Card.Body>
      </div>
      </Card>
    </div>:
    <div className="privacyContent">
     	<Card className="cardDiemension">
    {/* <Card.Header> <Row><b>{t('privacyPolicy.privacy')}</b></Row></Card.Header> */}
      <div className="contents">
      <Card.Body>
        <Row>{!documentpolicies? <b>{ <CircularProgress />}</b>:
          <div className="post__description" dangerouslySetInnerHTML={{ __html: documentpolicies.Description}}  />}
        </Row>
        </Card.Body>
      </div>
      </Card>
    </div>}
    </>
  );
}

export default EnviornmentalPolicyContent;