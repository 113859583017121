    import { useEffect } from "react";
    import queryString from "query-string";
    import { useHistory } from "react-router";
    const Auth = (props) => {
        const history = useHistory();
      useEffect(() => {
        const value = queryString.parse(props.location.search);
        const token = value.token;
        const user_id = value.user_id;
        if (token && user_id) {
          localStorage.setItem("token", token);
          localStorage.setItem("user_id", user_id);
          history.push("/");
        }
      }, []);
      return "";
    };
    export default Auth;
