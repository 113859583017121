import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { userServiceApi } from "../serviceApi/userServiceApi";
import { profileSlice } from "../../slices/profile";
import { useDispatch, useSelector } from "react-redux";
const History = () => {
  var [history, setHistory] = useState([]);
  const profileActions = profileSlice.actions;
  const dispatch = useDispatch();
  const formatHistory = (data) => {
    let curTimestamp = data[0].timeStamp;
    let prevTimestamp = data[0].timeStamp;
    let price = 0;
    let products = 0;
    for (let i = 0; i < data.length; i++) {
      curTimestamp = data[i].timeStamp;
      if (curTimestamp === prevTimestamp) {
        products += 1;
        price = price + parseInt(data[i].Price);
      } else {
        let historyItem = {
          date: data[i].date,
          products: products,
          total: price,
          Name:data[i].Name,
          enName:data[i].enName
        };
        let newHistory = history;
        newHistory.push(historyItem);
        setHistory(newHistory);
        products = 1;
        price = parseInt(data[i].Price);
      }
      if (i === data.length - 1) {
        let historyItem = {
          date: data[i].date,
          products: products,
          total: price,
          Name:data[i].Name,
          enName:data[i].enName
        };
        let newHistory = history;
        newHistory.push(historyItem);
        setHistory(newHistory);
      }
      prevTimestamp = data[i].timeStamp;
    }
  };
  useEffect(() => {
    let userId = localStorage.getItem("user_id");
      userServiceApi.getAllHistory(userId).then(
      (response) => {
        if (response.data.length > 0) {
          formatHistory(response.data);
        }
      },
      (error) => {
        dispatch(profileActions.setError(error));
      }
    );
  }, []);
  const { t } = useTranslation();
  const currentlang=localStorage.getItem("lang");
  if(currentlang=="en"){
    history = JSON.parse(JSON.stringify(history).split('"enName":').join('"Name":'));  
  }
  return (
    <div className="history item">
      <div className="content">
        <table className="table table-bordered">
          <thead>
            <tr>
            <th scope="col">{t("profile.productName")}</th>
              <th scope="col">{t('profile.Date')}</th>
              <th scope="col">{t('profile.nproduct')}</th>
            </tr>
          </thead>
          <tbody>
            {history.map((item) => {
              return (
                <tr>
                  <td>{item.Name}</td>
                  <td>{item.date}</td>
                  <td>{item.products}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default History;
