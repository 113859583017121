import { useDispatch, useSelector } from "react-redux";
import { contactSlice } from "../../slices/contact";
import { infoapi } from "../serviceApi/infoapi"
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import '../scss-styles/humanform.scss'
import { useState } from "react";
const ComplainForm = () => {
  const contactActions = contactSlice.actions;
  const {
    fullName,
    email,
    phone,
    message,
  } = useSelector((state) => state.contact.inputValues);
  const {
    fullNameErr,
    emailErr,
    phoneErr,
  } = useSelector((state) => state.contact.inputErrors);
  const isLoading = useSelector((state) => state.contact.isLoading);
  const [savedMessages, setMessage] = useState("");
  const dispatch = useDispatch();
  const validate = (e) => {
    e.preventDefault();
    dispatch(contactActions.setFullNameErr(""));
    dispatch(contactActions.setEmailErr(""));
    dispatch(contactActions.setPhoneErr(""));
    let isValid = true;
    if (fullName.length < 2) {
      dispatch(
        contactActions.setFullNameErr(
          "Full Name must be atleast 2 characters!"
        )
      );
      isValid = false;
    }
    if (phone.replaceAll(' ', '').length !== 13) {
      dispatch(
        contactActions.setPhoneErr(
          "Invalid phone number"
        )
      );
      isValid = false;
    }
    if (isValid) {
      sendRequest();
    }
  };
  const sendRequest = () => {
    dispatch(contactActions.setIsLoading(true));
    dispatch(contactActions.setRegistrationSuccessful(false));
    const contactData = { fullName: fullName, phone: phone.replaceAll(' ', ''), email: email, message: message }
    infoapi.createContact(contactData).then((response) => {
      dispatch(contactActions.setIsLoading(false));
      if (response.data) {
        dispatch(contactActions.setRegistrationSuccessful(true));
        document.body.style.overflow = "visible";
      } else if (response.data.errs) {
        if (response.data.errs === "email duplicated credentials!.") {
          dispatch(contactActions.setEmailErr("Email already exists"));
        }
      }
    },
      (error) => {
        dispatch(contactActions.setError(error));
        dispatch(contactActions.setIsLoading(false));
      }
    );
  };
  const md = useMediaQuery({ query: "(max-width: 800px)" });
  const { t } = useTranslation();
  const savebutton = () => {
    setMessage("Data saved Successfully.")
  }
  // window.scrollTo(0, 0)
  return (
    <div className="complaintDetails">
      {md ? <>{localStorage.getItem("lang") === "de" ? <div className="contactMobile">
        <h3><b>Menschenrechts- und Umweltbeschwerdeformular</b></h3><br />
        Die Adey Meselesh GmbH verpflichtet sich, alle internen und externen Bedenken anzusprechen, die sowohl von Mitarbeitern als auch von externen Parteien geäußert werden. Die Unternehmensregeln und -vorschriften auf unserer Website erklären, dass es unsere Priorität ist, sicherzustellen, dass die Sicherheit unserer Mitarbeiter, Kunden und der breiten Öffentlichkeit gewährleistet ist.
        Bitte füllen Sie dieses Formular im Falle von Umweltvorfällen und Menschenrechtsverletzungen im Zusammenhang mit unserem Unternehmen aus und beachten Sie die zur Korrektur Ihrer Beschwerde getroffene Lösung. Die auf diesem Formular bereitgestellten Informationen sind vertraulich und werden nur zu Ermittlungszwecken verwendet.
        <br /><br /><h5>I.	Persönliche Daten des Beschwerdeführers (optional)</h5><br />
        Die gesammelten personenbezogenen Daten werden verwendet, um zusätzliche Informationen zu erhalten und nach gründlicher Untersuchung des Verstoßes Beschwerdeführer über die beabsichtigten Maßnahmen des Unternehmens zu informieren.
        <br />
        <div className="leftalign">
          <form>
            <span>Name:<input></input>

              Kontakt Nr.:  <input></input>
            </span>
            <br />
            Adresse: <input></input>
            <br /><br />
            E-Mail-Addresse:<input></input>
            <br />     <br />
            Aus Interaktion mit Adey Meselesh GmbH:<input></input>
            <br /><br /></form>
          <span>Konzerngesellschaft  <input type="checkbox" ></input>        Kundin  <input type="checkbox" ></input>       Anbieterin  <input type="checkbox" ></input>       Andere  <input type="checkbox" ></input></span>
          <br />
        </div><br /><br />
        <h5>II.	Beschwerdeinformationen</h5>
        <br />
        Bitte machen Sie genaue Angaben und lesen Sie die nachstehende Beschreibung.
        <br /><br />
        <b>Verletzung der Menschenrechte</b> kann erniedrigende Behandlung oder Bestrafung, Sklaverei und Zwangsarbeit, willkürliche Verhaftung oder Inhaftierung, willkürliche Eingriffe in die Privatsphäre, Diskriminierung, Befürwortung von rassistischem oder religiösem Hass und mehr umfassen. <br /><br />
        <b>Environmental Violation</b> kann Beschwerden über das Arbeitsumfeld bei der Adey Meselesh GmbH oder unsachgemäße Abfallentsorgung, den Einsatz illegaler Pestizide in der Landwirtschaft, die Freisetzung von Feinstaub, die Zerstörung von Feuchtgebieten, Verbrennungen und mehr umfassen.
        Welche Art von Beschwerde möchten Sie melden?
        <br /><br />
        <div className="leftalign">
          Menschenrechtsverletzung  <input type="checkbox" ></input>
          <br />
          Umweltverletzung  <input type="checkbox" ></input>
          <br />
          Menschenrechtsverletzung und Umweltverletzung  <input type="checkbox" ></input>
        </div><br /><br />
        <h5><b>III.	Menschenrechtsverletzung</b></h5><div className="leftalign">

          Zeitrahmen des Auftretens des Vorfalls: (Datum/Monat/Jahr - Datum/Monat/Jahr)<br /><br />
          Dauer des Auftretens:<form><input></input></form>   <br /><br />
          Welche Art von Menschenrechtsverletzung möchten Sie melden?<br /><br />
          Diskriminierung  <input type="checkbox" ></input>
          Belästigung  <input type="checkbox" ></input>
          <br /><br />
          Menschenrechtsverletzung aufgrund von Umweltrisiken  <input type="checkbox" ></input>
          <br /><br />
          <form>
            Bitte beschreiben Sie genau, wie sich der oben genannte Vorfall auf Sie ausgewirkt hat:
          </form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <br /><br />
          Bitte beschreiben Sie detailliert den Vorfall, der diesen Beschwerdebericht ausgelöst hat:
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <br /><br />
          Wenn es eine bestimmte Abteilung gibt, die Sie bezüglich dieser Konformität ansprechen möchten
          <br /><br />
          Abteilung:<form><input></input></form>
          <br /><br />
        </div><br /><br />
        <h5><b>IV.	Umweltverletzung</b></h5>
        <div className="leftalign">
          Welche Art von Umweltverstoß möchten Sie melden?<br /><br />
          Air  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" style={{ background: "black" }}></input>      Lärm  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>          Abfall  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>           Andere <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input><br /><br />
          Bitte geben Sie die Adresse des Vorfalls an:
          Address:<form><input></input></form>
          <br /><br />
          Erläutern Sie unten die Einzelheiten des Vorfalls, der zu diesem Beschwerdebericht geführt hat:</div>
        <div className="tabular">
          <table>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>

          </table>
        </div>
        <br /><br /><form>
          Bitte geben Sie die Dauer des Vorfalls an: (Datum/Monat/Jahr - Datum/Monat/Jahr)
          <br /><br />
          Dauer des Auftretens:<input></input>  </form>

        <div className="leftalign">   <br /><br /><form>
          <h5><b>V.	Bitte geben Sie oben Ihren persönlichen Kommentar zum Umgang mit dem Vorfall ein.</b></h5><br />
          Bitte listen Sie unten auf, ob Sie eine Empfehlung haben, wie Sie dieses Problem angehen können. (Optional)
          <input></input>
          <input></input>

          <br /><br />

          Bitte geben Sie alle zusätzlichen Kommentare an, die das Unternehmen bei der Untersuchung Ihrer Beschwerde berücksichtigen soll: (optional)
          <input></input>
          <br /><br />
          <b>Vertraulichkeit:</b><br /><br /></form>
          Ich fordere die Adey Meselesh GmbH auf, meine Identität während der Untersuchung dieser Angelegenheit nicht preiszugeben  <input type="checkbox" ></input>
          <br /><br /><form>
            Unterschrift:<input></input>                                 Datum:<input></input> <div className="savebutton" onClick={savebutton}>Save</div>
          </form></div> {savedMessages}
      </div> : <div className="contactMobile">
        <h3><b>Human right and Environmental Complaint Form</b></h3><br />
        Adey Meselesh GmbH is committed to address all internal and external concerns raised by both employees and external parties. The company rule and regulation on our website explains that our priority is to make sure the safety of our employees, customers and the mass public is secured.
        Please feel free to fill this form in case of any environmental incidents and Human right violations related to our company and be aware the resolution taken to correct your complaint. Information provided on this form is confidential and will only be used for investigation purposes.
        <br /><br /><h5>I.	Personal Information of the Complainant (Optional)</h5><br />
        Personal information collected will be used to obtain additional information and after thorough investigation into the compliant, to notify complainants of the companies intended action.
        <br />
        <div className="leftalign">
          <form>
            <span>Name:<input></input>

              Contact No.:  <input></input>
            </span>
            <br />
            Address: <input></input>
            <br /><br />
            E-mail Address:<input></input>
            <br />     <br />
            From of interaction with Adey Meselesh GmbH:<input></input>
            <br /><br /></form>
          <span>Corporate company <input type="checkbox" ></input>         Customer  <input type="checkbox" style={{ background: "black" }}></input>        Supplier  <input type="checkbox" ></input>        Other  <input type="checkbox" ></input></span>
          <br />
        </div><br /><br />
        <h5>II.	Complaint Information</h5>
        <br />
        Please be accurate about the information you give and read the description below.
        <br /><br />
        <b>Human Right Violation</b> may include degrading treatment or punishment, slavery and forced labor, arbitrary arrest or detention, arbitrary interference with privacy, discrimination, advocacy of racial or religious hatred and more. <br /><br />
        <b>Environmental Violation</b> may include complains about the working environment at Adey Meselesh GmbH or improper waste disposal, the use of illegal pesticides in agriculture, releasing particulates, the destruction of wetlands, burning and more.
        What type of complaint do you want to report?
        <br /><br />
        <div className="leftalign">
          Human right violation   <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>
          <br />
          Environmental Violation   <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>
          <br />
          Human right violation and environmental violation  <input type="checkbox" ></input>
        </div><br /><br />
        <h5><b>III.	Human right violation</b></h5><div className="leftalign">
          Incident occurrence time frame: (date/month/year - date/month/year)<br /><br />
          Duration of occurrence:<form><input></input></form>   <br /><br />
          What type of human right violation do you want to report?<br /><br />
          Discrimination   <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>
          Harassment   <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>
          <br /><br />
          Human right violation resulting from environmental risk  <input type="checkbox" ></input>
          <br /><br />
          <form>
            Please describe specifically how the incident above has affected you:
          </form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <br /><br />
          Please describe in detail about the incident that initiate this complaint report:
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <br /><br />
          If there is a specific department you want to address regarding this compliant
          <br /><br />
          Department:<form><input></input></form>
          <br /><br />
        </div><br /><br />
        <h5><b>IV.	Environmental Violation</b></h5>
        <div className="leftalign">
          What type of environmental violation do you want to report?<br /><br />
          Air  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>      Noise  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>          Waste  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>           Other <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input><br /><br />
          Please specify the address of the incident:
          Address:<form><input></input></form>
          <br /><br />
          Explain below detail of incident which resulted this complaint report:</div>
        <div className="tabular">
          <table>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>

          </table>
        </div>
        <br /><br /><form>
          Please list incident's duration of occurrence: (date/month/year - date/month/year)
          <br /><br />
          Duration of occurrence:<input></input>  </form>

        <div className="leftalign">   <br /><br /><form>
          <h5><b>V.	Please fill your personal comment on how to deal with the incident above.</b></h5><br />
          Please list down below if you have any recommendation on how to address this Issue? (optional)
          <input></input>
          <input></input>

          <br /><br />

          Please provide any additional comments you wish the company to consider while investigating your complaint:(optional)
          <input></input>
          <br /><br />
          <b>Confidentiality:</b><br /><br /></form>
          I request that Adey Meselesh GmbH does not disclose my identity during its investigation of this matter  <input type="checkbox" ></input>
          <br /><br /><form>
            Signature:<input></input>                                 Date:<input></input>   <br /><br /> <div className="savebutton" onClick={savebutton}>Save</div>
          </form></div>
        {savedMessages}
      </div>}
      </> : <>{localStorage.getItem("lang") === "de" ? <div className="contact">
        <h3><b>Menschenrechts- und Umweltbeschwerdeformular</b></h3><br />
        Die Adey Meselesh GmbH verpflichtet sich, alle internen und externen Bedenken anzusprechen, die sowohl von Mitarbeitern als auch von externen Parteien geäußert werden. Die Unternehmensregeln und -vorschriften auf unserer Website erklären, dass es unsere Priorität ist, sicherzustellen, dass die Sicherheit unserer Mitarbeiter, Kunden und der breiten Öffentlichkeit gewährleistet ist.
        Bitte füllen Sie dieses Formular im Falle von Umweltvorfällen und Menschenrechtsverletzungen im Zusammenhang mit unserem Unternehmen aus und beachten Sie die zur Korrektur Ihrer Beschwerde getroffene Lösung. Die auf diesem Formular bereitgestellten Informationen sind vertraulich und werden nur zu Ermittlungszwecken verwendet.
        <br /><br /><h5>I.	Persönliche Daten des Beschwerdeführers (optional)</h5><br />
        Die gesammelten personenbezogenen Daten werden verwendet, um zusätzliche Informationen zu erhalten und nach gründlicher Untersuchung des Verstoßes Beschwerdeführer über die beabsichtigten Maßnahmen des Unternehmens zu informieren.
        <br />
        <div className="leftalign">
          <form>
            <span>Name:<input></input>

              Kontakt Nr.:  <input></input>
            </span>
            <br />
            Adresse: <input></input>
            <br /><br />
            E-Mail-Addresse:<input></input>
            <br />     <br />
            Aus Interaktion mit Adey Meselesh GmbH:<input></input>
            <br /><br /></form>
          <span>Konzerngesellschaft  <input type="checkbox" ></input>        Kundin  <input type="checkbox" ></input>       Anbieterin  <input type="checkbox" ></input>       Andere  <input type="checkbox" ></input></span>
          <br />
        </div><br /><br />
        <h5>II.	Beschwerdeinformationen</h5>
        <br />
        Bitte machen Sie genaue Angaben und lesen Sie die nachstehende Beschreibung.
        <br /><br />
        <b>Verletzung der Menschenrechte</b> kann erniedrigende Behandlung oder Bestrafung, Sklaverei und Zwangsarbeit, willkürliche Verhaftung oder Inhaftierung, willkürliche Eingriffe in die Privatsphäre, Diskriminierung, Befürwortung von rassistischem oder religiösem Hass und mehr umfassen. <br /><br />
        <b>Environmental Violation</b> kann Beschwerden über das Arbeitsumfeld bei der Adey Meselesh GmbH oder unsachgemäße Abfallentsorgung, den Einsatz illegaler Pestizide in der Landwirtschaft, die Freisetzung von Feinstaub, die Zerstörung von Feuchtgebieten, Verbrennungen und mehr umfassen.
        Welche Art von Beschwerde möchten Sie melden?
        <br /><br />
        <div className="leftalign">
          Menschenrechtsverletzung  <input type="checkbox" ></input>
          <br />
          Umweltverletzung  <input type="checkbox" ></input>
          <br />
          Menschenrechtsverletzung und Umweltverletzung  <input type="checkbox" ></input>
        </div><br /><br />
        <h5><b>III.	Menschenrechtsverletzung</b></h5><div className="leftalign">

          Zeitrahmen des Auftretens des Vorfalls: (Datum/Monat/Jahr - Datum/Monat/Jahr)<br /><br />
          Dauer des Auftretens:<form><input></input></form>   <br /><br />
          Welche Art von Menschenrechtsverletzung möchten Sie melden?<br /><br />
          Diskriminierung  <input type="checkbox" ></input>
          Belästigung  <input type="checkbox" ></input>
          <br /><br />
          Menschenrechtsverletzung aufgrund von Umweltrisiken  <input type="checkbox" ></input>
          <br /><br />
          <form>
            Bitte beschreiben Sie genau, wie sich der oben genannte Vorfall auf Sie ausgewirkt hat:
          </form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <br /><br />
          Bitte beschreiben Sie detailliert den Vorfall, der diesen Beschwerdebericht ausgelöst hat:
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <br /><br />
          Wenn es eine bestimmte Abteilung gibt, die Sie bezüglich dieser Konformität ansprechen möchten
          <br /><br />
          Abteilung:<form><input></input></form>
          <br /><br />
        </div><br /><br />
        <h5><b>IV.	Umweltverletzung</b></h5>
        <div className="leftalign">
          Welche Art von Umweltverstoß möchten Sie melden?<br /><br />
          Air  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" style={{ background: "black" }}></input>      Lärm  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>          Abfall  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>           Andere <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input><br /><br />
          Bitte geben Sie die Adresse des Vorfalls an:
          Address:<form><input></input></form>
          <br /><br />
          Erläutern Sie unten die Einzelheiten des Vorfalls, der zu diesem Beschwerdebericht geführt hat:</div>
        <div className="tabular">
          <table>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>

          </table>
        </div>
        <br /><br /><form>
          Bitte geben Sie die Dauer des Vorfalls an: (Datum/Monat/Jahr - Datum/Monat/Jahr)
          <br /><br />
          Dauer des Auftretens:<input></input>  </form>

        <div className="leftalign">   <br /><br /><form>
          <h5><b>V.	Bitte geben Sie oben Ihren persönlichen Kommentar zum Umgang mit dem Vorfall ein.</b></h5><br />
          Bitte listen Sie unten auf, ob Sie eine Empfehlung haben, wie Sie dieses Problem angehen können. (Optional)
          <input></input>
          <input></input>

          <br /><br />

          Bitte geben Sie alle zusätzlichen Kommentare an, die das Unternehmen bei der Untersuchung Ihrer Beschwerde berücksichtigen soll: (optional)
          <input></input>
          <br /><br />
          <b>Vertraulichkeit:</b><br /><br /></form>
          Ich fordere die Adey Meselesh GmbH auf, meine Identität während der Untersuchung dieser Angelegenheit nicht preiszugeben  <input type="checkbox" ></input>
          <br /><br /><form>
            Unterschrift:<input></input>                                 Datum:<input></input> <div className="savebutton" onClick={savebutton}>Save</div>
          </form> </div> {savedMessages}
      </div> : <div className="contact">
        <h3><b>Human right and Environmental Complaint Form</b></h3><br />
        Adey Meselesh GmbH is committed to address all internal and external concerns raised by both employees and external parties. The company rule and regulation on our website explains that our priority is to make sure the safety of our employees, customers and the mass public is secured.
        Please feel free to fill this form in case of any environmental incidents and Human right violations related to our company and be aware the resolution taken to correct your complaint. Information provided on this form is confidential and will only be used for investigation purposes.
        <br /><br /><h5>I.	Personal Information of the Complainant (Optional)</h5><br />
        Personal information collected will be used to obtain additional information and after thorough investigation into the compliant, to notify complainants of the companies intended action.
        <br />
        <div className="leftalign">
          <form>
            <span>Name:<input></input>

              Contact No.:  <input></input>
            </span>
            <br />
            Address: <input></input>
            <br /><br />
            E-mail Address:<input></input>
            <br />     <br />
            From of interaction with Adey Meselesh GmbH:<input></input>
            <br /><br /></form>
          <span>Corporate company <input type="checkbox" ></input>         Customer  <input type="checkbox" style={{ background: "black" }}></input>        Supplier  <input type="checkbox" ></input>        Other  <input type="checkbox" ></input></span>
          <br />
        </div><br /><br />
        <h5>II.	Complaint Information</h5>
        <br />
        Please be accurate about the information you give and read the description below.
        <br /><br />
        <b>Human Right Violation</b> may include degrading treatment or punishment, slavery and forced labor, arbitrary arrest or detention, arbitrary interference with privacy, discrimination, advocacy of racial or religious hatred and more. <br /><br />
        <b>Environmental Violation</b> may include complains about the working environment at Adey Meselesh GmbH or improper waste disposal, the use of illegal pesticides in agriculture, releasing particulates, the destruction of wetlands, burning and more.
        What type of complaint do you want to report?
        <br /><br />
        <div className="leftalign">
          Human right violation   <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>
          <br />
          Environmental Violation   <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>
          <br />
          Human right violation and environmental violation  <input type="checkbox" ></input>
        </div><br /><br />
        <h5><b>III.	Human right violation</b></h5><div className="leftalign">
          Incident occurrence time frame: (date/month/year - date/month/year)<br /><br />
          Duration of occurrence:<form><input></input></form>   <br /><br />
          What type of human right violation do you want to report?<br /><br />
          Discrimination   <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>
          Harassment   <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>
          <br /><br />
          Human right violation resulting from environmental risk  <input type="checkbox" ></input>
          <br /><br />
          <form>
            Please describe specifically how the incident above has affected you:
          </form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <br /><br />
          Please describe in detail about the incident that initiate this complaint report:
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <form><input></input></form>
          <br /><br />
          If there is a specific department you want to address regarding this compliant
          <br /><br />
          Department:<form><input></input></form>
          <br /><br />
        </div><br /><br />
        <h5><b>IV.	Environmental Violation</b></h5>
        <div className="leftalign">
          What type of environmental violation do you want to report?<br /><br />
          Air  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>      Noise  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>          Waste  <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input>           Other <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="checkbox"></input><br /><br />
          Please specify the address of the incident:
          Address:<form><input></input></form>
          <br /><br />
          Explain below detail of incident which resulted this complaint report:</div>
        <div className="tabular">
          <table>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>
            <tr><form>
              <input></input></form>
            </tr>

          </table>
        </div>
        <br /><br /><form>
          Please list incident's duration of occurrence: (date/month/year - date/month/year)
          <br /><br />
          Duration of occurrence:<input></input>  </form>

        <div className="leftalign">   <br /><br /><form>
          <h5><b>V.	Please fill your personal comment on how to deal with the incident above.</b></h5><br />
          Please list down below if you have any recommendation on how to address this Issue? (optional)
          <input></input>
          <input></input>

          <br /><br />

          Please provide any additional comments you wish the company to consider while investigating your complaint:(optional)
          <input></input>
          <br /><br />
          <b>Confidentiality:</b><br /><br /></form>
          I request that Adey Meselesh GmbH does not disclose my identity during its investigation of this matter  <input type="checkbox" ></input>
          <br /><br /><form>
            Signature:<input></input>                                 Date:<input></input>   <br /><br /> <div className="savebutton" onClick={savebutton}>Save</div>
          </form>  </div>
        {savedMessages}
      </div>}
      </>}
    </div>
  );
}

export default ComplainForm;