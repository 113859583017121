import profileIcon from "../../icons/profile.svg";
import "react-toastify/dist/ReactToastify.css";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import History from "./history";
import Cart from "./cart";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { imageurl } from "../../utils/url";
import { loginSlice } from "../../slices/login";
import { profileSlice } from "../../slices/profile";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { useTranslation,Trans } from 'react-i18next';
import { userServiceApi } from "../serviceApi/userServiceApi";
import { useMediaQuery } from "react-responsive";
const Profile = () => {
  const dispatch = useDispatch();
  const loginActions = loginSlice.actions;
  const isUserLogged = useSelector((state) => state.login.isUserLogged);
  const profileActions = profileSlice.actions;
  const profileUpdated = useSelector((state) => state.profile.profileUpdated);
  const history = useHistory();
  let historyData = []
  const user = useSelector((state) => state.login.loggedUser);
  const rightDrawerActions = rightDrawerSlice.actions;
  window.scroll(0,0)
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  useEffect(() => {
    if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
      history.push("/");
      showRightDrawer("login");
      return;
    } else {
        const userId = localStorage.getItem("user_id");
        const token = localStorage.getItem("token");
        userServiceApi.getParamMethod("getChekedProfile", token).then(
          (response) => {
            if (response.data.result) {
              if (response.data.result.token == token && response.data.result.user_id == userId) {
                  userServiceApi.getAllProfile(userId).then(
                  (response) => {
                    if (response.data.User) {
                      dispatch(loginActions.setIsUserLogged(true));
                      dispatch(loginActions.setLoggedUser(response.data.User[0]));
                    }
                    dispatch(loginActions.setIsLoading(false));
                  },
                  (error) => {
                    dispatch(loginActions.setError(error));
                  }
                );
              }
              else {
                localStorage.clear()
                dispatch(loginActions.setIsUserLogged(false));
                dispatch(loginActions.setLoggedUser([]));
                history.push("/");
              }
            }
          },
          (error) => { dispatch(loginActions.setError(error));}
        );
    }
  }, [isUserLogged, profileUpdated]);
  const emailIcon = <FontAwesomeIcon icon={faEnvelope} size="lg" />;
  const phoneIcon = <FontAwesomeIcon icon={faPhone} size="lg" />;
  const addressIcon = <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />;
  const showEditProfileModal = () => {
    dispatch(profileActions.setShowEditProfile(true));
    document.body.style.overflow = "hidden";
  };
  const showChangePasswordModal = () => {
    dispatch(profileActions.setShowChangePassword(true));
    document.body.style.overflow = "hidden";
  };
  const { t } = useTranslation();
  const mobileDevice=useMediaQuery({ query: "(max-width:600px)" });
  return (
    <div className="profile">
      <div className="image">
        <img
          src={
            user.profilePicture
              ? `${imageurl}images/${user.profilePicture}`
              : profileIcon
          }
          alt=""
        />
      </div>
      <div className="name">
        {user.firstName} {user.lastName}
      </div>
      <div className="edit-profile">
        {" "}
        <div className="profile-btn" onClick={showEditProfileModal}>
          {t('profile.EditProfile')}
        </div>
        <div className="profile-btn" onClick={showChangePasswordModal}>
          {t('profile.ChangePassword')}
        </div>
      </div>
      {mobileDevice ?<div className="mobilecontact-list1">
        <div className="contact-item1">
         {phoneIcon}{" "}
          {user.phone}
        </div>
        <br/>
        <div className="contact-item1">
          {emailIcon}{" "}
          {user.email}
        </div>
        <br/>
        <div className="contact-item1">
          {addressIcon}{" "}
          {user.address}
        </div>
      </div>:<div className="contact-list">
        <div className="contact-item">
          <div className="icon">{phoneIcon}</div>
          <div className="text">{user.phone}</div>
        </div>
        {""}
        <div className="contact-item">
          <div className="icon">{emailIcon}</div>{"    "}
          <div className="text">{user.email}</div>
        </div>
        <div className="contact-item">
          <div className="icon">{addressIcon}</div>{"    "}
          <div className="text">{user.address}</div>
        </div>
      </div>}
      <div className="dashboard">
      <Row>
          <Col md={8} className="historyHeader">
          {t('profile.historyHeader')}
          </Col>
          </Row>
          <div className="bottomHistory">
        <Row>
          <Col md={12}>
            <History history={historyData} />
          </Col>
          </Row>
          <Row>
            <Cart />
          </Row>
          </div>
      </div>
    </div>
  );
};
export default Profile;
