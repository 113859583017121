import React, { useRef, useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel2";
import topImage from "../../new-assets/pexels-shantanu-pal-2802527 1.png";
import News from "../news/news.component";
import bottom from "../../new-assets/hue12-photography-8rTwokBwz1w-unsplash 1.png";
import star from "../../new-assets/start.png";
import ProductsSlider from "../products-slider/products-slider.component";
import { useDispatch, useSelector } from "react-redux";
import { productsSlice } from "../../slices/products";
import { cartSlice } from "../../slices/cart";
import { reviewSlice } from "../../slices/review";
import { imageurl } from "../../utils/url";
import { Rating } from 'react-simple-star-rating'
import { toast } from "react-toastify";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import { productsApi } from "../serviceApi/productsApi";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { adminServiceApi } from "../serviceApi/adminServiceApi";
import { useMediaQuery } from "react-responsive";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import '../scss-styles/productDetails.scss'
const shoppingCartIcon = <FontAwesomeIcon icon={faShoppingCart} />;
const ProductDetails = (props) => {
  const location = props.location.pathname;
  window.scrollTo(0, 0)
  const productDatas = location.substring(location.lastIndexOf("/") + 1);
  const filOne = productDatas.split(".");
  const productID = filOne[1];
  const productActions = productsSlice.actions;
  const cartActions = cartSlice.actions;
  const reviewActions = reviewSlice.actions;
  const rightDrawerActions = rightDrawerSlice.actions;
  const history = useHistory();
  const currencies = useSelector((state) => state.cart.currencies);
  const dispatch = useDispatch();
  const productImages = useSelector((state) => state.products.images);
  const totalReview = useSelector(state => state.products.totalReview)
  const successMessage = useSelector(state => state.review.success)
  const {
    message,
    name,
    email,
  } = useSelector((state) => state.review.inputValues);
  const {
    messageErr,
  } = useSelector((state) => state.review.inputErrors);
  const isLoading = useSelector((state) => state.review.isLoading);
  useEffect(() => {
    productsApi.getProductDetail(productID).then(
      (response) => {
        if (response.data) {
          dispatch(productActions.setProduct(response.data));
          productsApi.getProductDetailImages(productID).then(
            (response) => {
              if (response.data.productImages.length > 0) {
                dispatch(productActions.setImages(response.data.productImages));
              }
            },
            (error) => { dispatch(productActions.setError(error)); }
          );
        }
      },
      (error) => {
        dispatch(productActions.setError(error));
      }
    );
    productsApi.getParamMethod("getTotalReview", productID).then(
      (response) => {
        if (response.data) {
          let totalreviews = response.data;
          if (totalreviews.result.length == 0) { dispatch(productActions.setTotalReview(0)); }
          else { dispatch(productActions.setTotalReview(totalreviews.result[0].totalReview)); }
        }
      },
      (error) => {
        dispatch(productActions.setError(error));
      }
    );
    adminServiceApi.getmethod("getLatestCurrency").then(
      (response) => {
        dispatch(cartActions.setCurrencies(response.data.currency));
      },
      (error) => {
        dispatch(productActions.setError(error));
      }
    );
    productsApi.getmethod("getPercentageDiscount").then(
      (response) => {
        dispatch(cartActions.setPercentageDiscounts(response.data.result));
      },
      (error) => {
        dispatch(cartActions.setError(error));
      }
    );
  }, [productID]);
  const [rating, setRating] = useState(0)
  var product = useSelector((state) => state.products.product);
  const handleRating = (rate) => {
    const userId = localStorage.getItem("user_id");
    // if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
    //   history.push("/");
    //   showRightDrawer("login");
    //   return;
    // }
    setRating(rate)
    const data = {
      ratingValues: rate,
      user_id: userId,
      product_id: product.id,
    }
    productsApi.postmethods(data)
      .then(
        (response) => {
          dispatch(reviewActions.setIsLoading(false));
          if (response.data) {
            toast.info("Product Rating added Successfully! ", {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
        },
        (error) => {
          dispatch(reviewActions.setError(error));
          dispatch(reviewActions.setIsLoading(false));
        }
      );
  }
  const [number, setNumber] = useState(1);
  const pro = useRef(null);
  const options = {
    items: 1,
    nav: true,
    loop: true,
    autoplay: true,
    touchDrag: true,
    mergeFit: true,
    autoplayTimeout: 5000,
    margin: 20,
    responsiveRefreshRate: 200,
    navText: [
      '<svg class="black-right-arrow fill-black"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
      ' <svg class="black-left-arrow fill-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
    ],
    // navText: [
    //   '<svg class="black-right-arrow fill-black"size="lg"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 5012"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
    //   ' <svg class="black-left-arrow fill-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 5012"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
    // ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    dots: true,
  };
  const events = {
  };
  const arrow1 = useMediaQuery({ query: "(min-width: 880px)" });
  const arrow2 = useMediaQuery({ query: "(max-width: 1245px)" });
  const arrow=arrow1 && arrow2;
  if(arrow){
      options.navText[0]='<svg class="black-right-arrow fill-black"size="lg"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 5012"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>'
      options.navText[1]=' <svg class="black-left-arrow fill-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 5012"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>'
  }
  const productRating = [];
  for (let i = 0; i < product.totalRate / 20; i++) {
    productRating.push({ rates: i })
  }
  const validate = (e) => {
    e.preventDefault();
    dispatch(reviewActions.setMessageErr(""));
    let isValid = true;
    if (message.length < 1) {
      dispatch(
        reviewActions.setMessageErr(
          "Message is required!"
        )
      );
      isValid = false;
    }
    if (isValid) {
      sendRequest();
    }
  };
  const sendRequest = () => {
    const userId = localStorage.getItem("user_id");
    if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
      history.push("/");
      showRightDrawer("login");
      return;
    }
    dispatch(reviewActions.setIsLoading(true));
    const data = {
      name: name,
      email: email,
      text: message,
      user_id: userId,
      product_id: product.id
    }
    productsApi.postReview(data).then(
      (response) => {
        if (response.data.success) { dispatch(reviewActions.setSuccess("Message Sent Successfully!.")); }
        else { dispatch(reviewActions.setSuccess("Message Not Sent try again.")); }
        dispatch(reviewActions.setIsLoading(false));
      },
      (error) => {
        dispatch(reviewActions.setError(error));
        dispatch(reviewActions.setIsLoading(false));
      }
    );
  };
  const md = useMediaQuery({ query: "(max-width: 600px)" });
  if (md) {
    options.dots = true;
    options.items = 1;
  }
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  const currentlang = localStorage.getItem("lang");
  if (currentlang == "en") {
    product = JSON.parse(JSON.stringify(product).split('"enName":').join('"Name":'));
    product = JSON.parse(JSON.stringify(product).split('"enDescription":').join('"Description":'));
    product = JSON.parse(JSON.stringify(product).split('"enpackingDescription":').join('"packingDescription":'));
    product = JSON.parse(JSON.stringify(product).split('"eningredients":').join('"ingredients":'));
  }
  const { t } = useTranslation();
  var firstDeescription = "";
  if (product.Description) {
    var Descr = product.Description.split(".");
    firstDeescription = Descr[0] + ".";
  }
  const percentages = useSelector((state) => state.cart.percentages);
  const totalVat = () => {
      var totalVat = percentages.percentage;
    return totalVat;
  };
  const smallDevices = useMediaQuery({ query: "(max-width: 384px)" });
  return (
    <div className="productDetails">
      <div className="top">
        <img src={topImage} alt="top img" />
      </div>
      <div className="product-preview">
        <a href="/" className="back">
          <svg
            class="back-arrow"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
          >
            <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
          </svg>
          {t('productDetail.back')}
        </a>
        <div className="slider">
          <OwlCarousel ref={pro} options={options} events={events} className="rel"
          >
            {productImages.length === 0
              ? ""
              : productImages.map((item) => (
                <img src={`${imageurl}images/${item.product_image}`} alt="product" className="img" />
              ))}
          </OwlCarousel>
        </div>
        <div className="mobile">
          <h4 className="titles">{product.Name}</h4>
          <div className="review">
            <div className="stars">
              {/* {!productRating
                ? ""
                : productRating.map((item) => (
                  <img src={star} alt={item.rates} />
                ))} */}
            </div>
            {totalReview ? <p>{totalReview}{" "}{t('productDetail.Bewertungen')}</p> : ""}
          </div>
          {/* <p> {firstDeescription}</p> */}
          {" "}<p  dangerouslySetInnerHTML={{ __html: firstDeescription}}></p>
          <p>{t('productDetail.Produktenthält')}{" "}{product.size}</p>
          <p translate="no"> {product.Price ? currencies.CurrencySymbol + " " + product.Price : ''}{" "}{t('order.inc')}{" "}{totalVat()}%{" "}{t('order.vatDescription')}</p>
          {md ? <div className="addtocartMobile">
              <button className="green-buttonMobileview" onClick={() => {
                // if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
                //   showRightDrawer("login");
                //   return;
                // } else {
                  dispatch(
                    cartActions.addCartItem({ product: product, quantity: 1 })
                  );
                  toast("Product added to cart", {
                    position: "top-right",
                    autoClose: 1700,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                // }
              }}>{shoppingCartIcon}{t('productDetail.INDENWARENKORB')}</button></div>:""}
          <div className="bottom-container">
            {/* <div className="number">
              <div className="stars">
                <div className="form-grp">
                  <div className='rating'><b>{t('productDetail.BewertenSiediesesProdukt')}</b></div>
                  <div className='ratingv'>
                    <Rating onClick={handleRating} ratingValue={rating} size={30} fillColorActive="red"
                      fillColorInactive="blue" />
                  </div></div>
              </div>
            </div> */}
            {md?"":  <div className="addtocart">
              {localStorage.getItem("lang")==="de"?<>
              <button className="green-buttonde" onClick={() => {
                // if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
                //   showRightDrawer("login");
                //   return;
                // } else {
                  dispatch(
                    cartActions.addCartItem({ product: product, quantity: 1 })
                  );
                  toast("Product added to cart", {
                    position: "top-right",
                    autoClose: 1700,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                // }
              }}>{shoppingCartIcon}{t('productDetail.INDENWARENKORB')}</button>
              </>:<>
              <button className="green-button" onClick={() => {
                // if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
                //   showRightDrawer("login");
                //   return;
                // } else {
                  dispatch(
                    cartActions.addCartItem({ product: product, quantity: 1 })
                  );
                  toast("Product added to cart", {
                    position: "top-right",
                    autoClose: 1700,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                // }
              }}>{shoppingCartIcon}{t('productDetail.INDENWARENKORB')}</button>
              </>}
              </div>}   
          </div>
        </div>
      </div>
      {/* <div className="description"> */}
      {/* </div> */}
      <div className="description">
        <h4>
          {!product
            ? ""
            :
            <p className="Names">{product.Name}</p>}
        </h4>
        {!product
          ? ""
          :
          <p  dangerouslySetInnerHTML={{ __html: product.Description}}></p>
          }
      </div>
      <div className="sectioned">
        <div>
          <h4>{t('productDetail.Zutaten')}</h4>
          {!product
            ? "" :
            <p dangerouslySetInnerHTML={{ __html: product.ingredients}}></p>}
        </div>
       {smallDevices ?<><br/></>:""}
        <div>
          <h4>{t('productDetail.WeitereInformationen')}</h4>
          <ul>
            {!product.size
              ? "" :
              <li><p>{product.size}</p></li>}
            {!product.packingSize
              ? "" :
              <li><p>{product.packingSize}</p></li>}
          </ul>
        </div>
      </div>
      {/* <h4 style={{ marginLeft: "10%", marginBottom: "40px" }}>
        {t('productDetail.Kundenbewertungen')}
      </h4> */}
      {/* <form onSubmit={validate}>
        <div className="contact-form">
          <div className="left">
            <input type="text" className="name" value={name}
              onChange={(e) => {
                dispatch(reviewActions.setName(e.target.value));
              }} placeholder="Enter your Name" />
            <input type="email" className="email" value={email}
              onChange={(e) => {
                dispatch(reviewActions.setEmail(e.target.value));
              }} placeholder="Enter your Email" />
            <p>{t('productDetail.E-Mail-Adressewirdnichtveröffentlicht')}</p>
          </div>
          <div className="right">
            <textarea
              type="text"
              id="message"
              required
              value={message}
              onChange={(e) => {
                dispatch(reviewActions.setMessage(e.target.value));
              }}
              placeholder="Enter text message"
            />
            <div className="form-error">{messageErr}</div>
            <div className="loading" translate="no">{isLoading && <CircularProgress />}</div>
            <input type="submit" value="Submit Review" className="green-button" />
            <p>{successMessage}</p>
          </div>
        </div>
      </form> */}
      <div className="bottom-separeter">
        <img src={bottom} alt="top img" />
      </div>
      <div>
        <h3 className="bottom-title">{t('productDetail.Daskönntedirauchgefallen')}</h3>
        <ProductsSlider />
      </div>
      <News />
    </div>
  );
};
export default ProductDetails;
