import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import topImage from "../../new-assets/shop-top-image.png";
import Featured from "../featured/featured.component";
import News from "../news/news.component";
import { Col } from "react-bootstrap";
import { productsSlice } from "../../slices/products";
import { useDispatch, useSelector } from "react-redux";
import { categorySlice } from "../../slices/category";
import NewProductCard from "../newproduct-card/product.component";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { useTranslation } from 'react-i18next';
import '../scss-styles/shopOverview.scss'
import { useMediaQuery } from "react-responsive";
const ShopOverview = () => {
  const dispatch = useDispatch();
  const productsActions = productsSlice.actions;
  const sortMethod = useSelector((state) => state.products.sortMethod);
  const categoryActions = categorySlice.actions;
  const [loading, setloading] = useState(0)
  useEffect(() => {
    dispatch(productsActions.setIsLoading(true));
    dispatch(categoryActions.setAreProductsLoading(true));
    let isMounted = true;
    userServiceApi.getmethod("getAllNewProducts").then(
      (response) => {
        if (isMounted) {
          dispatch(productsActions.setIsLoading(false));
          if (response.data.products)
            dispatch(productsActions.setProducts(response.data.products));
        }
      },
      (error) => { dispatch(productsActions.setError(error)); }
    );
    userServiceApi.getmethod("getAllCatagory").then(
      (response) => {
        if (isMounted) {
          dispatch(categoryActions.setAreProductsLoading(false));
          dispatch(categoryActions.setCategories(response.data.catagory));
        }
      },
      (error) => {
        dispatch(productsActions.setError(error));
      });
    return () => {
      isMounted = false;
    };
  }, []);
  var productsUn = useSelector((state) => state.products.products);
  const currentlang = localStorage.getItem("lang");
  if (currentlang == "en") {
    productsUn = JSON.parse(JSON.stringify(productsUn).split('"enName":').join('"Name":'));
    productsUn = JSON.parse(JSON.stringify(productsUn).split('"enDescription":').join('"Description":'));
  }
  var categories = useSelector((state) => state.category.categories);
  const activeCategorID = useSelector((state) => state.category.activeCategoryId);
  if (sortMethod == "Name") {
    const data2 = [...productsUn].sort((a, b) =>
      a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1
    );
    var products = data2;
  }
  else if (sortMethod == "date") {
    let sortedData = productsUn.slice().sort((a, b) => b.id - a.id);
    var products = sortedData
  }
  else {
    var products = productsUn;
  }
  const getproductByCat = (activeCategorID) => {
    if (activeCategorID) {
                dispatch(productsActions.setIsLoading(true));
                if(activeCategorID==11223311.1111){
                  userServiceApi.getmethod("getAllNewProducts").then(
                    (response) => {
                        dispatch(productsActions.setIsLoading(false));
                        if (response.data.products)
                          dispatch(productsActions.setProductsByCategory(response.data.products));
                    },
                    (error) => { dispatch(productsActions.setError(error)); }
                  );
                }
                else{
      dispatch(productsActions.setIsLoadingProductCategory(true));
      userServiceApi.getParamMethod("getAllNewProductsByCategory", activeCategorID).then(
        (response) => {
          dispatch(productsActions.setIsLoadingProductCategory(false));
          if (response.data.products)
            dispatch(productsActions.setProductsByCategory(response.data.products));
        },
        (error) => { dispatch(productsActions.setError(error)); }
      );
    }}
  }
  var ProductsByCategory = useSelector((state) => state.products.ProductsByCategory);
  if (currentlang == "en") {
    ProductsByCategory = JSON.parse(JSON.stringify(ProductsByCategory).split('"enName":').join('"Name":'));
    ProductsByCategory = JSON.parse(JSON.stringify(ProductsByCategory).split('"enDescription":').join('"Description":'));
    categories = JSON.parse(JSON.stringify(categories).split('"encatagory_Name":').join('"catagory_Name":'));
  }
  const { t } = useTranslation();
  window.scrollTo(0, 0)
  // const md1 = useMediaQuery({ query: "(max-width: 1368px)" });
  const md1 = useMediaQuery({ query: "(max-width: 1200px)" });
  const md2 = useMediaQuery({ query: "(min-width: 601px)" });
  const smallDevices = useMediaQuery({ query: "(max-width: 400px)" });
  const smallDevicesProduct1 = useMediaQuery({ query: "(min-width: 400px)" });
  const smallDevicesProduct2 = useMediaQuery({ query: "(max-width: 600px)" });
  const smallDevicesproduct=smallDevicesProduct1 && smallDevicesProduct2;
  const md = md1 && md2
  return (
    <div className="shopOverview">
      {md ? <div className="ipadview">
        <div className="top">
          <img src={topImage} alt="top img" />
          <h4 translate="no">{t('shop.title')}</h4>
          <p translate="no">
            {" "}
            {t('shop.content1')}
          </p>
        </div>
        {/* <Featured /> */}
        <div className="filter">
          <DropdownButton
            variant="outline-secondary"
            title={t('shop.spicecategory')}
            id="input-group-dropdown-1"
            className="left--100"
          >
            {categories.map((category) => {
              return (
                <div className="item">
                  {" "}
                  <Dropdown.Item
                    onClick={() => {
                      // dispatch(categoryActions.setActiveCategoryId(category.id));
                      getproductByCat(category.id)
                    }}
                  >
                    {category.catagory_Name}
                  </Dropdown.Item >
                </div>
              );
            })}
          </DropdownButton>
          <DropdownButton
            variant="outline-secondary"
            title={t('shop.sortBy')}
            id="input-group-dropdown-1"
            className="left-100"
          >
            <Dropdown.Item onClick={() => {
              dispatch(productsActions.setSortMethod("Name"));
            }}
            >
              {t('shop.bycategory')}</Dropdown.Item>
            <Dropdown.Item onClick={() => {
              dispatch(productsActions.setSortMethod("date"));
            }}
            >
              {t('shop.bydate')}</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="ipadproduct-list">
          {ProductsByCategory.length === 0
            ? products.map((product) => {
              return (
                <Col key={product.id}>
                  <NewProductCard
                    details={product}
                  />
                </Col>
              );
            })
            : ProductsByCategory.map((product) => {
              return (
                <Col key={product.id}>
                  <NewProductCard
                    details={product}
                  />
                </Col>
              );
            })}
        </div>
        <News />
      </div> :
        <>
          <div className="top">
            <img src={topImage} alt="top img" />
            <h4 translate="no">{t('shop.title')}</h4>
            <p translate="no">
              {" "}
              {t('shop.content1')}
            </p>
          </div>
          {smallDevices ? <div className="smallDevicesfilter">
            <div className="smallfilter">
          <DropdownButton
            variant="outline-secondary"
            title={t('shop.spicecategory')}
            id="input-group-dropdown-1"
            className="left--100"
          >
            {categories.map((category) => {
              return (
                <div className="item">
                  {" "}
                  <Dropdown.Item
                    onClick={() => {
                      getproductByCat(category.id)
                    }}
                  >
                    {category.catagory_Name}
                  </Dropdown.Item >
                </div>
              );
            })}
          </DropdownButton>
          <DropdownButton
            variant="outline-secondary"
            title={t('shop.sortBy')}
            id="input-group-dropdown-1"
            className="left-100"
          >
            <Dropdown.Item onClick={() => {
              dispatch(productsActions.setSortMethod("Name"));
            }}
            >
              {t('shop.bycategory')}</Dropdown.Item>
            <Dropdown.Item onClick={() => {
              dispatch(productsActions.setSortMethod("date"));
            }}
            >
              {t('shop.bydate')}</Dropdown.Item>
          </DropdownButton>
        </div></div>:<div className="filter">
          <DropdownButton
            variant="outline-secondary"
            title={t('shop.spicecategory')}
            id="input-group-dropdown-1"
            className="left--100"
          >
            {categories.map((category) => {
              return (
                <div className="item">
                  {" "}
                  <Dropdown.Item
                    onClick={() => {
                      // dispatch(categoryActions.setActiveCategoryId(category.id));
                      getproductByCat(category.id)
                    }}
                  >
                    {category.catagory_Name}
                  </Dropdown.Item >
                </div>
              );
            })}
          </DropdownButton>
          <DropdownButton
            variant="outline-secondary"
            title={t('shop.sortBy')}
            id="input-group-dropdown-1"
            className="left-100"
          >
            <Dropdown.Item onClick={() => {
              dispatch(productsActions.setSortMethod("Name"));
            }}
            >
              {t('shop.bycategory')}</Dropdown.Item>
            <Dropdown.Item onClick={() => {
              dispatch(productsActions.setSortMethod("date"));
            }}
            >
              {t('shop.bydate')}</Dropdown.Item>
          </DropdownButton>
        </div>}
          {/* <Featured /> */}
          {smallDevicesproduct ?<><div className="smallDevicesproduct2">
          <div className="product-list">
            {ProductsByCategory.length === 0
              ? products.map((product) => {
                return (
                  <Col key={product.id}>
                    <br/>
                    <NewProductCard
                      details={product}
                    />
                  </Col>
                );
              })
              : ProductsByCategory.map((product) => {
                return (
                  <Col key={product.id}>
                    <br/>
                    <NewProductCard
                      details={product}
                    />
                  </Col>
                );
              })}
          </div></div></>:<>
          {smallDevices ?<div className="smallDevicesproduct">
          <div className="product-list">
            {ProductsByCategory.length === 0
              ? products.map((product) => {
                return (
                  <Col key={product.id}>
                    <br/>
                    <NewProductCard
                      details={product}
                    />
                  </Col>
                );
              })
              : ProductsByCategory.map((product) => {
                return (
                  <Col key={product.id}>
                    <br/>
                    <NewProductCard
                      details={product}
                    />
                  </Col>
                );
              })}
          </div></div>:<div className="product-list">
            {ProductsByCategory.length === 0
              ? products.map((product) => {
                return (
                  <Col key={product.id}>
                    <NewProductCard
                      details={product}
                    />
                  </Col>
                );
              })
              : ProductsByCategory.map((product) => {
                return (
                  <Col key={product.id}>
                    <NewProductCard
                      details={product}
                    />
                  </Col>
                );
              })}
          </div>}
          </>}
          <News />
        </>
      }
    </div>
  );
};

export default ShopOverview;
