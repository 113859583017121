import { useEffect} from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { teamSlice } from "../../slices/team";
import Card from "react-bootstrap/Card";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import {
  faDownload
    } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
  const downloadIcon = <FontAwesomeIcon icon={faDownload} size="50px" />;
const ImprintContent = ({ }) => {
  const policyActions = teamSlice.actions;
  const dispatch = useDispatch();
  useEffect(() => {
    const langugess=localStorage.getItem("lang");
		if(langugess=="en"){
			var documentNames="imprint(en)";
		}
		else{
			 documentNames="imprint";
		}
    userServiceApi.getParamMethod("getDocuments",documentNames).then(
      (response) => {
        dispatch(policyActions.setArePoliciesLoading(false));
        dispatch(policyActions.setPoliciesDocument(response.data.result));
      },
      (error) => {
        dispatch(policyActions.setError(error));
      }
    );
  },[]);
  const documentpolicies = useSelector((state) => state.team.documentpolicies);
  const { t } = useTranslation();
  const md = useMediaQuery({ query: "(max-width: 1300px)" });
  window.scrollTo(0, 0)
  return (
    <>
      {md ?
    <div className="mobilePrivacyContent">
    <Card className="cardDiemension">
    {/* <Card.Header> <Row><b>{t('imprint.ImprintSummary')}</b></Row></Card.Header> */}
      <div className="contents">
      <Card.Body>
        <Row>{!documentpolicies? <b>{ <CircularProgress />}</b>:
          <div className="post__description" dangerouslySetInnerHTML={{ __html: documentpolicies.Description}}  />}
        </Row>
        </Card.Body>
      </div>
      </Card>
    </div>:<div className="privacyContent">
    <Card className="cardDiemension">
    {/* <Card.Header> <Row><b>{t('imprint.ImprintSummary')}</b></Row></Card.Header> */}
      <div className="contents">
      <Card.Body>
        <Row>{!documentpolicies? <b>{ <CircularProgress />}</b>:
          <div className="post__description" dangerouslySetInnerHTML={{ __html: documentpolicies.Description}}  />}
        </Row>
        </Card.Body>
      </div>
      </Card>
    </div>}
    </>
  );
}

export default ImprintContent;