import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../utils/url";
import { partnerSlice } from "../../slices/partner";
import { CircularProgress } from "@material-ui/core";
import { infoapi } from "../serviceApi/infoapi"
import {loginSlice} from "../../slices/login"
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import '../scss-styles/partnerStyles.scss';
import { useMediaQuery } from "react-responsive";
const Partners = () => {
  const partnerActions = partnerSlice.actions;
  const loginActions = loginSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(partnerActions.setArePartnersLoading(true));
    dispatch(loginActions.setLoadError(false));
    infoapi.getmethod("getNewPartner").then(
      (response) => {
        dispatch(partnerActions.setArePartnersLoading(false));
        dispatch(partnerActions.setPartners(response.data.partners));
      },
      (error) => {
        dispatch(loginActions.setLoadError(true));
        dispatch(partnerActions.setError(error));
      }
    );
  }, []);
  const { t } = useTranslation();
  const currentlang = localStorage.getItem("lang");
  var partners = useSelector((state) => state.partners.partners);
  if (currentlang == "en") {
    partners = JSON.parse(JSON.stringify(partners).split('"enName":').join('"Name":'));
    partners = JSON.parse(JSON.stringify(partners).split('"enDescription":').join('"Description":'));
  }
  const languages=localStorage.getItem("lang");
  const md = useMediaQuery({ query: "(max-width: 600px)" });
  // const md2 = useMediaQuery({ query: "(min-width: 830px)" });
  // const md3 = useMediaQuery({ query: "(max-width: 829px)" });
  // const md = md1 && md2
  return (
    <>
{md ? <div className="partnerStylesMobile">
      <div className="partlist">
        {!partners ? <b>{<CircularProgress />}</b>
          : partners.map((partner) => {
            return (
              <div className="ss" key={partner.id}>
                <h4 dangerouslySetInnerHTML={{ __html:partner.Name}}></h4>
                {currentlang=="de"?<><p dangerouslySetInnerHTML={{ __html:partner.Description}}></p></>:<><p dangerouslySetInnerHTML={{ __html:partner.Description}}>{}</p></>}
                <div className="gallery">
                  <img src={`${imageurl}images/${partner.Image}`} alt="" className="partner" />
                </div><br/></div>
            )
          })}
      </div>
      <button onClick={() => {
        if(languages==="de"){
           history.push("/Alle Partner");
             }
           else{history.push("/AllPartners");}
      }}>{t('partners.Mehrerfahren')}</button>
    </div>:<div className="partnerStyles">
      <div className="partlist">
        {!partners ? <b>{<CircularProgress />}</b>
          : partners.map((partner) => {
            return (
              <div className="ss" key={partner.id}>
                <h4 dangerouslySetInnerHTML={{ __html:partner.Name}}></h4>
                {currentlang=="de"?<><p dangerouslySetInnerHTML={{ __html:partner.Description}}></p></>:<><p dangerouslySetInnerHTML={{ __html:partner.Description}}>{}</p></>}
                <div className="gallery">
                  <img src={`${imageurl}images/${partner.Image}`} alt="" className="partner" />
                </div><br/></div>
            )
          })}
      </div>
      <button onClick={() => {
        if(languages==="de"){
           history.push("/Alle Partner");
             }
           else{history.push("/AllPartners");}
      }}>{t('partners.Mehrerfahren')}</button>
    </div>}
</>
  );
};
export default Partners;
