import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../utils/url";
import { productsSlice } from "../../slices/products";
import { contactSlice } from "../../slices/contact";
import {infoapi} from "../serviceApi/infoapi"
import { useTranslation} from 'react-i18next';
import '../scss-styles/newStyle.scss'
import { dataArray } from "../../i18n/staticDatafile";
import {loginSlice} from "../../slices/login"
const News = () => {
  const contactActions = contactSlice.actions;
  const loginActions = loginSlice.actions;
  const {
    email
  } = useSelector((state) => state.contact.inputValues);
  const productsActions = productsSlice.actions;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.login.isLoading);
  const validate = (e) => {
    e.preventDefault();
    dispatch(contactActions.setEmailErr(""));
    let isValid = true;
    if (isValid) {
      requestSubscribe();
    }
  };
  const requestSubscribe = () => {
    dispatch(contactActions.setIsLoading(true));
    dispatch(contactActions.setSubscriptionSuccessful(false));
    dispatch(loginActions.setLoadError(false));
    infoapi.postmethod("subscribe/",
    {
        email: email,
      }).then(
        (response) => {
          dispatch(contactActions.setIsLoading(false));
          if(response.data)
          {dispatch(contactActions.setSubscriptionSuccessful(true));
            document.body.style.overflow = "visible";}
        },
        (error) => {
          dispatch(contactActions.setError(error));
          dispatch(contactActions.setIsLoading(false));
          dispatch(loginActions.setLoadError(true));
        }
      );
  };
  useEffect(() => {
    dispatch(productsActions.setAreForcompanyLoading(true));
    dispatch(loginActions.setLoadError(false));
    infoapi.getmethod("getAllCompany").then(
      (response) => {
        dispatch(productsActions.setAreForcompanyLoading(false));
        dispatch(productsActions.setForcompany(response.data.company));
      },
      (error) => {
        dispatch(loginActions.setLoadError(true));
        dispatch(productsActions.setError(error));
      }
    );
  },[]);
  var forcompanies = useSelector((state) => state.products.forcompanies);
  const { t } = useTranslation();
  const currentlang=localStorage.getItem("lang");
  if(currentlang=="en"){
    forcompanies = JSON.parse(JSON.stringify(forcompanies).split('"enTitle":').join('"Title":'));
    forcompanies = JSON.parse(JSON.stringify(forcompanies).split('"enDescription":').join('"Description":'));   
  }
  return (
    <div className="newStyle">
      <div className="top-section">
        {" "}
        <h4>{t('newsubscribe.title')}</h4>
        <p>{t('newsubscribe.subtitle')}</p>
        <form onSubmit={validate}>
          <div className="form-grp">
            <input
              className="input"
              type="email"
              id="email"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => {
                dispatch(contactActions.setEmail(e.target.value));
              }}
            />
          </div>
          <div className="form-grp">
            {" "}
            <div className="loading"></div>
            <input type="submit" value={t('newsubscribe.newslettersButton')} className="btn btn-block" />
          </div>
        </form>
      </div>
      <div className="three-col">
        {forcompanies.length===0
          ? dataArray.map((array) => {
            return (
              <div className="column" key={array.id}>
                <img src={array.im1} />
                <h3>{array.Title}</h3>
                <span>
                  {array.Description}
                </span>
              </div>
            );
          }) 
          : forcompanies.map((company) => {
            return (
              <div className="column"key={company.id}>
                <img src={`${imageurl}images/${company.Image}`} />
                <h3>{company.Title}</h3>
                <span>
                  {company.Description}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default News;
