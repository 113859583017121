import breake from "../../new-assets/breake.png";
import cup from "../../new-assets/cup-and-stick.png";
import News from "../news/news.component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../utils/url";
import { teamSlice } from "../../slices/team";
import { infoapi } from "../serviceApi/infoapi"
import { useTranslation } from 'react-i18next';
import { loginSlice } from "../../slices/login"
import '../scss-styles/aboutuStyles.scss'
const AboutUs = () => {
  const teamActions = teamSlice.actions;
  const dispatch = useDispatch();
  const loginActions = loginSlice.actions;
  useEffect(() => {
    dispatch(teamActions.setAreTeamsLoading(true));
    dispatch(loginActions.setLoadError(false));
    infoapi.getmethod("getAllTeams").then(
      (response) => {
        dispatch(teamActions.setAreTeamsLoading(false));
        dispatch(teamActions.setTeams(response.data.teams));
      },
      (error) => {
        dispatch(loginActions.setLoadError(true));
        dispatch(teamActions.setError(error));
      }
    );
    infoapi.getmethod("getAllAboutAdey").then(
      (response) => {
        dispatch(teamActions.setAreAboutAdyLoading(false));
        dispatch(teamActions.setAboutAdy(response.data.about));
      },
      (error) => {
        dispatch(teamActions.setError(error));
      }
    );
    infoapi.getmethod("getAllAboutAdvertsie").then(
      (response) => {
        dispatch(teamActions.setAreAboutAdvertLoading(false));
        dispatch(teamActions.setAboutAdvert(response.data.aboutadvert));
      },
      (error) => {
        dispatch(teamActions.setError(error));
      }
    );
    infoapi.getmethod("getAllAboutus").then(
      (response) => {
        dispatch(teamActions.setAreAboutusLoading(false));
        dispatch(teamActions.setAboutus(response.data.aboutus));
      },
      (error) => {
        dispatch(teamActions.setError(error));
      }
    );
  }, []);
  const { t } = useTranslation();
  var teams = useSelector((state) => state.team.teams);
  var AboutAdy = useSelector((state) => state.team.AboutAdy);
  var AboutAdvert = useSelector((state) => state.team.AboutAdvert);
  const Aboutus = useSelector((state) => state.team.Aboutus);
  const currentlang = localStorage.getItem("lang");
  if (currentlang == "en") {
    teams = JSON.parse(JSON.stringify(teams).split('"enTitle":').join('"Title":'));
    teams = JSON.parse(JSON.stringify(teams).split('"enDescription":').join('"Description":'));
    AboutAdy = JSON.parse(JSON.stringify(AboutAdy).split('"enDescription":').join('"Description":'));
    AboutAdy = JSON.parse(JSON.stringify(AboutAdy).split('"enName":').join('"Name":'));
    AboutAdy = JSON.parse(JSON.stringify(AboutAdy).split('"enTitle":').join('"Title":'));
    AboutAdvert = JSON.parse(JSON.stringify(AboutAdvert).split('"enTitle":').join('"Title":'));
    AboutAdvert = JSON.parse(JSON.stringify(AboutAdvert).split('"enDescription":').join('"Description":'));
  }
  window.scrollTo(0, 0)
  return (
    <div className="aboutuStyles">
      {!AboutAdy
        ? ""
        : AboutAdy.map((about) => {
          return (
            <div className="d">
              <h1 className="red-title">{about.Title}</h1>
              <h4>{about.Name}</h4>
              <img src={`${imageurl}images/${about.Image}`} alt="adey meselssh" className="round-image" />
              <p>
                {about.Description}
              </p></div>
          );
        })}
      <p>{t('aboutus.ceo')}</p>
      <img src={breake} alt="brekae" className="breake" />
      <div className="grayed-section">
        <h2>{t('aboutus.m1')}</h2>
        <h3>{t('aboutus.m2')}</h3>
        <p className="top-bottom-50">
          {t('aboutus.m3')}
        </p>

        <p>
          {t('aboutus.m4')}
        </p>
        <h3 className="top-bottom-50">{t('aboutus.m5')}</h3>
        <img src={cup} alt="cup" className="cup" />
      </div>
      <div className="grid-4">
        {!AboutAdvert
          ? ""
          : AboutAdvert.map((advert) => {
            return (
              <div>
                <img
                  src={`${imageurl}images/${advert.Image}`}
                  alt=""
                  className="icon"
                />
                <h4 className="text">
                  {advert.Title}
                </h4>
                <p>
                  {advert.Description}
                </p>
              </div>
            );
          })}
      </div>
      <img
        src={
          require("../../new-assets/hue12-photography-8rTwokBwz1w-unsplash 1.png")
            .default
        }
        alt=""
        className="breake"
      />
      <div className="grayed-section">
        <h2 className="green">{t('aboutus.m6')}</h2>
        <p>{t('aboutus.m7')}</p>
        <div className="grid-4 gg-20">
          {!teams
            ? ""
            : teams.map((team) => {
              return (
                <div className="member-container">
                  <img
                    src={`${imageurl}images/${team.Image}`}
                    alt="Daniel Melese"
                    className="person"
                  />
                  <div className="descri">
                    <h5 className="green">{team.Name} </h5>
                    <b><span className="gray"> {team.Title}</span></b>
                    <br></br>
                    <span className="whitesmoke"> {team.Description}</span>
                  </div>
                </div>
              );
            })}

        </div>
      </div>
      <News />
    </div>
  );
};

export default AboutUs;
