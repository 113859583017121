import { Link } from "react-router-dom";
import { useTranslation} from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import '../scss-styles/footerStyles.scss';
import { useEffect,useState} from "react";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { useDispatch, useSelector } from "react-redux";
import { teamSlice } from "../../slices/team";
// import { imageurl } from "../../utils/url";
// import { fileurl } from "../../utils/url";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Footer = () => {
  const [fileName, setDocumentName] = useState("");
  const policyActions = teamSlice.actions;
  const dispatch = useDispatch();
  useEffect(() => {
    const langugess=localStorage.getItem("lang");
  },[]);
  const Documents = (documentNames) => {
    userServiceApi.getParamMethod("getDocuments",documentNames).then(
      (response) => {
        setDocumentName(response.data.result.fileName);
      },
      (error) => {
        dispatch(policyActions.setError(error));
      }
    );
  };
  const { t } = useTranslation();
  if(!localStorage.getItem("lang")){
    localStorage.setItem("lang", "de");
  }
  const facebookIcon = <FontAwesomeIcon icon={faFacebook} size={"lg"} />;
  const linkedinInIcon = <FontAwesomeIcon icon={faLinkedin} size={"lg"} />;
  const instagramIcon = <FontAwesomeIcon icon={faInstagram} size={"lg"} />;
  const md = useMediaQuery({ query: "(max-width: 600px)" });
  // const md2 = useMediaQuery({ query: "(max-width: 880px)" });
  // const md = md1 && md2;
  // const languages = useSelector((state) => state.rightDrawer.languages);
  const languages=localStorage.getItem("lang");
  return (
    <div className="all"><br/>
      {md ? <div className="mobilefooterStyles">
      <div className="first">
        <h4>{t('footer.pleaseContactUs')}</h4>
        <span className="addreses">{t('footer.telephone')}</span>
        <br/>
        <span className="addreses">{t('footer.mail')}</span >
        <br/><br/>
        <span className="addreses">{t('footer.callus')}</span >
        <br/>
        <span className="addreses">{t('footer.montofri')}</span>
      </div>
      <div className="second">
        <h4>{t('footer.addressTitle')}</h4>
        <span className="addreses">{t('footer.address1')}</span>
        <br/>
        <span className="addreses">{t('footer.address2')}</span>
        <br/>
        <span className="addreses">{t('footer.address3')}</span>
      </div>
      <div className="third">
        <h4>{t('footer.third')}</h4>
        {languages==="de"?<><Link to="/Impressum">{t('footer.imprint')}</Link>
        <br />
        <Link to="/Datenschutz-Bestimmungen">{t('footer.privacy')}</Link>
        <br/>
        <Link to="/Kontakt">{t('footer.contacts')}</Link>
        <br/><Link to="/Umweltpolitik">{t('footer.enviornmental')}</Link>
        <br />
        <Link to="/RegelnundVorschriften">{t('footer.rules')}</Link>
        <br/>
        <Link to="/humanformde">{t('footer.humanright')}</Link>
        {/* <Link to="/Menschenrechtsbeschwerde">{t('footer.humanright')}</Link> */}
        <br/></>:<><Link to="/imprint">{t('footer.imprint')}</Link>
        <br />
        <Link to="/Privacy Policy">{t('footer.privacy')}</Link>
        <br/>
        <Link to="/contacts">{t('footer.contacts')}</Link>
        <br/>
        <Link to="/EnviornmentalPolicy">{t('footer.enviornmental')}</Link>
        <br />
        <Link to="/Rulesandregulations">{t('footer.rules')}</Link>
        <br/>
        {/* <Link to="/Humanrightcomplaint">{t('footer.humanright')}</Link> */}
        <Link to="/humanform">{t('footer.humanright')}</Link>
        <br/>
        </>}
      </div>
      <div className="fourth">
        <h4>{t('footer.followus')}</h4>
        <div className="icons">
        <a href="https://www.facebook.com/profile.php?id=100086541909752"target="_blank"className="facebook">{facebookIcon}</a>
        <a href="/"className="instgram"target="_blank">{instagramIcon}</a>
        <a href="https://www.linkedin.com/showcase/adey-spices/about/"className="linkedin"target="_blank">{linkedinInIcon}</a>
        </div>
      </div>
    </div>:
    <div className="footerStyles">
      <div className="first">
        <h4>{t('footer.pleaseContactUs')}</h4>
        <span className="addreses">{t('footer.telephone')}</span>
        <br/>
        <span className="addreses">{t('footer.mail')}</span >
        <br/><br/>
        <span className="addreses">{t('footer.callus')}</span >
        <br/>
        <span className="addreses">{t('footer.montofri')}</span>
      </div>
      <div className="second">
        <h4>{t('footer.addressTitle')}</h4>
        <span className="addreses">{t('footer.address1')}</span>
        <br/>
        <span className="addreses">{t('footer.address2')}</span>
        <br/>
        <span className="addreses">{t('footer.address3')}</span>
      </div>
      <div className="third">
        <h4>{t('footer.third')}</h4>
        {languages==="de"?<><Link to="/Impressum">{t('footer.imprint')}</Link>
        <br />
        <Link to="/Datenschutz-Bestimmungen">{t('footer.privacy')}</Link>
        <br/>
        <Link to="/Kontakt">{t('footer.contacts')}</Link>
        <br/><Link to="/Umweltpolitik">{t('footer.enviornmental')}</Link>
        <br />
        <Link to="/RegelnundVorschriften">{t('footer.rules')}</Link>
        <br/>
        <Link to="/humanformde">{t('footer.humanright')}</Link>
        {/* <Link to="/Menschenrechtsbeschwerde">{t('footer.humanright')}</Link> */}
        <br/></>:<><Link to="/imprint">{t('footer.imprint')}</Link>
        <br />
        <Link to="/Privacy Policy">{t('footer.privacy')}</Link>
        <br/>
        <Link to="/contacts">{t('footer.contacts')}</Link>
        <br/><Link to="/EnviornmentalPolicy">{t('footer.enviornmental')}</Link>
        <br />
        <Link to="/Rulesandregulations">{t('footer.rules')}</Link>
        <br/>
        <Link to="/humanform">{t('footer.humanright')}</Link>
        {/* <Link to="/Humanrightcomplaint">{t('footer.humanright')}</Link> */}
        {/* <br/>
         <a href={fileurl+`${imageurl+fileName}`}
         onClick={() => {
                            Documents("Code Of Conduct(en)");
                            }}
                            target="_blank">Code Of Conduct</a> */}
        <br/></>}
      </div>
      <div className="fourth">
        <h4>{t('footer.followus')}</h4>
        <div className="icons">
        <a href="https://www.facebook.com/profile.php?id=100086541909752"target="_blank"className="facebook">{facebookIcon}</a>
        <a href="/"className="instgram"target="_blank">{instagramIcon}</a>
        <a href="https://www.linkedin.com/showcase/adey-spices/about/"className="linkedin"target="_blank">{linkedinInIcon}</a>
        </div>
      </div>
    </div>
    }
    </div>
  );
};
export default Footer;

