import { createSlice } from "@reduxjs/toolkit";
export const loginSlice = createSlice({
  name: "login",
  initialState: {
    inputValues: {
      email: "",
      password: "",
    },
    inputErrors: {
      emailErr: "",
      passwordErr: "",
    },
    isLoading:false,
    isUserLogged:false,
    loggedUser:[],
    isOpen: false,
    passwordRecovered:false,
    page: 1,
    error:"",
    loadError:false,
    concentError:false
  },
  reducers: {
    setEmail: (state, action) => {
      state.inputValues.email = action.payload;
    },
    setConcentError:(state,action) => {
      state.concentError = action.payload;
  },
  setLoadError:(state,action) => {
    state.loadError = action.payload;
},
    setError:(state,action) => {
      state.error = action.payload;
  },
    setPassword: (state, action) => {
      state.inputValues.password = action.payload;
    },
    setIsUserLogged: (state, action) => {
      state.isUserLogged = action.payload;
    },
    setLoggedUser: (state, action) => {
      state.loggedUser = action.payload;
    },
    setEmailErr: (state, action) => {
      state.inputErrors.emailErr = action.payload;
    },
    setPasswordErr: (state, action) => {
      state.inputErrors.passwordErr = action.payload;
    },
    setIsLoading:(state,action) => {
      state.isLoading = action.payload;
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPasswordRecovered: (state, action) => {
      state.passwordRecovered = action.payload;
    },
  },
});
