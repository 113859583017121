import React from "react";
import '../scss-styles/recipesCard.scss'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {useSelector } from "react-redux";
const RecipieCard = ({ image, name, description,details }) => {
  var descriptionss= description
  if(description.length>0){ 
    descriptionss= description.substring(0, 116)
    // const [first, ...rest] = description.split('.');
    // /  descriptionss= first
    }
  const { t } = useTranslation();
  function dec2hex (dec) {
    return dec.toString(16).padStart(2, "0")
  }
  function generateId (len) {
    var arr = new Uint8Array(( len|| 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }
  const generatedID=generateId(20)
  // const languages = useSelector((state) => state.rightDrawer.languages);
  const languages=localStorage.getItem("lang");
  return (
    <div className="recipesCard">
      {languages==="de"?<><Link to={`/Rezept-details/${generatedID+"."+details.id}`}>
     <img src={image} alt="recipie" className="porduct" />
     <h3 className="name">{name}</h3>
     </Link>
     <p className="desc">{descriptionss}....<br/><br/><Link to={`/Rezept-details/${generatedID+"."+details.id}`}><span className="showmore">{t('recipes.showmore')}</span></Link></p>
     </>:<><Link to={`/Recipe-Detail/${generatedID+"."+details.id}`}>
     <img src={image} alt="recipie" className="porduct" />
     <h3 className="name">{name}</h3>
     </Link>
     <p className="desc">{descriptionss}....<br/><br/><Link to={`/Recipe-Detail/${generatedID+"."+details.id}`}><span className="showmore">{t('recipes.showmore')}</span></Link></p></>}
    </div>
  );
};

export default RecipieCard;
