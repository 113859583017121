import { Col, Row } from "react-bootstrap";
import { imageurl} from "../../utils/url";
import { useSelector,useDispatch} from "react-redux";
import React, {useEffect, useState } from "react";
import { faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { orderServiceAPI } from "../serviceApi/orderServiceAPI";
import { orderSlice } from "../../slices/order";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useTranslation,Trans } from 'react-i18next';
const OrderItem = ({ cartItem }) => {
  const currencies = useSelector((state) => state.cart.currencies);
  var replacePrice = cartItem.price;
  replacePrice = replacePrice.replace(/,/g, '.')
  const totalPrice=replacePrice;
  const Price = Number(totalPrice);
  const singlePrices= parseFloat(Price).toFixed(2);;
  const totalPricef1=totalPrice * cartItem.quantity;
  const totalPricef=parseFloat(totalPricef1.toFixed(2));
  const [orderDeleted, setOrderDeleted] = useState(false);
  const removeIcon = <FontAwesomeIcon icon={faTrashAlt} />;
  const history = useHistory();
  const dispatch = useDispatch();
  const orderActions = orderSlice.actions;
  const { t } = useTranslation();
  useEffect(() => {  
    if (!localStorage.getItem("user_id") || !localStorage.getItem("token"))
       history.push('/')
    else {
      orderServiceAPI.getUserOrder("getUserOrdered", localStorage.getItem("user_id")).then(
        (response) => {
          dispatch(orderActions.setOrdersLoading(false));
          dispatch(orderActions.setOrderItem(response.data.result));
        },
        (error) => {
          dispatch(orderActions.setError(error));
        }
      );
    }
  }, [orderDeleted]);
  const deleteOrder = (id) => {
    setOrderDeleted(false);
    userServiceApi.deleteOrders(id).then((response) => {
        if (response.data.success) {
          setOrderDeleted(true);
        }
      });
  };
  const md1 = useMediaQuery({ query: "(max-width: 780px)" });
  const md2 = useMediaQuery({ query: "(min-width: 781px)" });
  const md3 = useMediaQuery({ query: "(maxn-width: 1200px)" });
  if(cartItem){
    var totalPriceDisplay=totalPricef.toFixed(2).toString().replace(/\./g, ',');
   var prices= singlePrices.toString().replace(/\./g, ',')
  }
  return (<>
      {md1 ? <><br/><div className="cart-item"><br/>
      <div >
              <div className="productCartMobile" style={{marginLeft:"26%"}}>
                <img className="img-fluid"
                  src={`${imageurl}images/${cartItem.product_image}`}
                  alt=""
                />
                <div className="product-left">
                  {/* <div className="product-title">{cartItem.Name}</div> */}
                  {/* <div className="product-title">{currencies.CurrencySymbol+cartItem.price}</div> */}
                  {/* <div className="remove-button"  onClick={() => {deleteOrder(cartItem.id);}}>{removeIcon}</div> */}
                </div>
              </div>
              <div className="quantity" style={{paddingLeft:"26%",paddingTop:"10px"}}>
                <div className="amount"style={{textTransform:"capitalize"}}><b>{t('order.Product')}:</b>{"  "}{cartItem.Name} </div>
              </div>
              <div className="quantity" style={{paddingLeft:"26%",paddingTop:"10px"}}>
                <div className="amount"><b>{t('order.Quantity')}:</b>{"  "}{cartItem.quantity} </div>
              </div>
            <div className="quantity" style={{paddingLeft:"26%"}}>
                <div className="amount"><b>{t('order.Price')}:</b> {currencies.CurrencySymbol+" "+prices}</div></div>
              <div className="quantity"style={{paddingLeft:"26%"}}><b>{t('order.Total')} :</b> {currencies.CurrencySymbol}{" "}{totalPriceDisplay}</div>
              <div className="quantity" style={{paddingLeft:"26%",paddingTop:"10px"}}>
              <div className="remove-button"  onClick={() => {deleteOrder(cartItem.id);}}>{removeIcon} {t('order.remove')}</div>
              </div>
             </div> </div></> :<div className="cart-item">
            <Row>
            <Col lg={6} sm={6}style={{paddingLeft:"30px"}}>
              <div className="productCart">
                <img className="img-fluid"
                  src={`${imageurl}images/${cartItem.product_image}`}
                  alt=""
                />
                <div className="product-left">
                  <div className="product-title"style={{textTransform:"capitalize"}}>{cartItem.Name}</div>
                  {/* <div className="product-title">{currencies.CurrencySymbol+cartItem.price}</div> */}
                  <div className="remove-button"  onClick={() => {deleteOrder(cartItem.id);}}>{removeIcon}</div>
                </div>
              </div>
            </Col>
            <Col lg={2} sm={2}>
              <div className="quantity">
                <div className="amount">{cartItem.quantity} </div>
              </div>
            </Col>
            <Col lg={2} sm={2}>
            <div className="quantity">
                <div className="amount">{currencies.CurrencySymbol+" "+prices}</div></div>
            </Col>
            <Col lg={2} sm={2}>
              <div className="quantity">{currencies.CurrencySymbol}{" "}{totalPriceDisplay}</div>
            </Col>
          </Row>
          </div>}
    </>
    );
};
export default OrderItem;
