import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
const Cart = () => {
  const cart = useSelector((state) => state.cart.cartItems);
  const { t } = useTranslation();
  return (
    <div className="wishlist item">
      <div className="header" style={{paddingTop:"20px",paddingBottom:"20px"}}><b>{t('profile.cartstatus')}</b></div>
      <div className="content">
        {cart.length === 0 ? (
          <>
            {" "}
            <h6 style={{fontWeight:"bold"}}>{t('cart.m1')}</h6>
            <p>{t('cart.m2')}</p>
          </>
        ) : (
          <>
            {" "}
            {t('cart.m3')}<h1 style ={{display:'inline'}}>{cart.length}</h1>{t('cart.m4')}
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;
