import { createSlice } from "@reduxjs/toolkit";
export const rightDrawerSlice = createSlice({
    name:'rightDrawer',
    initialState: {
        isOpen:false,
        type:'',
        homes:[],
        areHomesLoading:false,
        error:"",
        languages:"de"
    },
    reducers: {
       showDrawer:(state) => {
           state.isOpen = true;
       },
       setLanguages:(state,action)=>{
        state.languages=action.payload;
       },
       setError:(state,action) => {
        state.error = action.payload;
    },
       hideDrawer:(state) => {
           state.isOpen = false;
       },
       setType:(state,action) => {
           state.type = action.payload;
       },
    setHomes:(state,action) => {
        state.homes = action.payload;
    },
    setAreHomesLoading:(state,action) => {
        state.areHomesLoading = action.payload;
    },
    }
});