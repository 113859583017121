import { createSlice } from "@reduxjs/toolkit";
export const stripeSlice = createSlice({
    name: "stripe",
    initialState: {
      inputValues: {
      cardNumber:'',
      cardExpMonth:'',
      cardExpYear:'',
      cardCVC:'',
      country:'',
      postalCode:'',
      city:'',
      streetAddress:'',
      houseNumber:''
      },    
      inputErrors: {
        cardCVCErr: "",
        cardNumberErr: "",
        cardExpMonthErr: "",
        cardExpYearErr: "",
        countryErr: "",
        postalCodeErr: "",
      },
      isLoading: false,
      text: "Payment Failed Try Again !",
      paymentSuccessful:false,
      error:""
    },
    reducers: {
      setHouseNumber:(state, action) => {
        state.inputValues.houseNumber = action.payload;
      },
      setStreetAddress:(state, action) => {
        state.inputValues.streetAddress = action.payload;
      },
      setCardNumber: (state, action) => {
        state.inputValues.cardNumber = action.payload;
      },
      setCity:(state, action) => {
        state.inputValues.city = action.payload;
      },
      setError:(state,action) => {
        state.error = action.payload;
    },
      setCardExpMonth: (state, action) => {
        state.inputValues.cardExpMonth = action.payload;
      },
      setCardExpYear: (state, action) => {
        state.inputValues.cardExpYear = action.payload;
      },
      setCardCVC: (state, action) => {
        state.inputValues.cardCVC = action.payload;
      },
      setCountry: (state, action) => {
        state.inputValues.country = action.payload;
      },
      setPostalCode: (state, action) => {
        state.inputValues.postalCode = action.payload;
      },
      setcardCVCErr: (state, action) => {
        state.inputErrors.cardCVCErr = action.payload;
      },
      setcardNumberErr: (state, action) => {
        state.inputErrors.cardNumberErr = action.payload;
      },
      setcardExpMonthErr: (state, action) => {
        state.inputErrors.cardExpMonthErr = action.payload;
      },
      setcardExpYearErr: (state, action) => {
        state.inputErrors.cardExpYearErr = action.payload;
      },
      setcountryErr: (state, action) => {
        state.inputErrors.countryErr = action.payload;
      },
      setpostalCodeErr: (state, action) => {
        state.inputErrors.postalCodeErr = action.payload;
      },
      setIsLoading: (state, action) => {
        state.isLoading = action.payload;
      },
      setText: (state, action) => {
        state.text = action.payload;
      },
      setPaymentSuccessful: (state, action) => {
        state.paymentSuccessful = action.payload;
      },
    },
});