import axios from "axios";
import { url,smtpurl} from "../../utils/url";
import { configMode } from "./configMode";
const infoapi={
    
   getmethod:(route) => axios.get(`${url}info/${route}`),
   postmethod:(route,data)=>axios.post(`${url}info/${route}`),
   postmail:(route)=>axios.post(`${smtpurl}/${route}`),
   createContact:(data)=>axios.post(`${url}info/createContact/`,data,configMode),
   getAllRecipePagination:(page)=>axios.get(`${url}info/getAllRecipePageination/${page}`),
   getSingleRecipe:(id)=>axios.get(`${url}info/getSingleRecipe/${id}`),
   getAllPartners:()=>axios.get(`${url}info/getAllPartners`),
}
export {infoapi};