import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { loginSlice } from "../../slices/login";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { userServiceApi } from "../serviceApi/userServiceApi";
const RecoverPasswordContent = () => {
  const dispatch = useDispatch();
  const isLoading = false;
  const recoverPasswordActions = loginSlice.actions;
  const page = useSelector((state) => state.login.page);
  const { firstName, lastName, phone, address } = useSelector(
    (state) => state.profile.inputValues
  );
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [codeErr, setCodeErr] = useState("");
  const [password1Err, setPassword1Err] = useState("");
  const [password2Err, setPassword2Err] = useState("");
  const [verificationError,setVerificationError]=useState("");
  const { t } = useTranslation();
  const validate = (e) => {
    e.preventDefault();
  };
  const sendVerificationCode = (e) => {
    e.preventDefault();
    // var code = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
    const currentlang=localStorage.getItem("lang");
    const sentMailData = { email: email, activationCode: code,currentlang:currentlang }
    userServiceApi.sentEmail(sentMailData).then(
      (response) => {
        if (response.data.sent) {
          dispatch(recoverPasswordActions.setPage(2));
        }
        else if(response.data.errorData){
          setVerificationError(t("recoverpaswword.emailnotexist"));
        }
        else if(response.data.emailnotsent){
          setVerificationError(t("recoverpaswword.emailnotsent"));
        }
      },
      (error) => {
        dispatch(recoverPasswordActions.setError(error));
        setVerificationError(t("recoverpaswword.emailnotexist"));
      }
    );
    // const emailSenderdata = {
    //   from: "dev@adeymailserver.de",
    //   to: email,
    //   cc: "yemie008@gmail.com",
    //   bcc: "adeymeselesh@gamil.com",
    //   subject: "Activation code to Reset Password.",
    //   text: '<h1>Welcome</h1><p>This is the Activation code:</p>' + code + "<p><a href=''>Enter the activation code and change password</p>",
    // }
    // userServiceApi.sendEmailUser(emailSenderdata)
    //   .then(
    //     (response) => {
    //     },
    //     (error) => {
    //       dispatch(recoverPasswordActions.setError(error));
    //     }
    //   );
  };
  const changePassword = (e) => {
    e.preventDefault();
    setCodeErr("");
    setPassword1Err("");
    setPassword2Err("");
    if (code.length !== 4) {
      setCodeErr(t("recoverpaswword.codeError"))
      // return;
    }
    if (password1.length < 6) {
      setPassword1Err(t("recoverpaswword.passwordError"));
      // return;
    }
    if (password2.length < 6) {
      setPassword1Err(t("recoverpaswword.passwordError"));
      // return;
    }
    if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{6,}$/.test(password2)) {
      setPassword1Err(t('recoverpaswword.passwordcomplex'));
      } 
    if (password1 !== password2) {
      setPassword2Err(t("recoverpaswword.passwordmatch"));
      // return;
    }
    const recoverData = { email: email, activationCode: code, newPassword: password1 }
    userServiceApi.recoverPassword(recoverData).then(
      (response) => {
        if (response.data.password) {
          dispatch(recoverPasswordActions.setIsOpen(false))
          document.body.style.overflow = "visible";
          dispatch(recoverPasswordActions.setPasswordRecovered(true))
        }
        if (response.data.incorrectCode) {
          setCodeErr(t("recoverpaswword.incorrectverification"))
        }
        else if(response.data.notchanged){
          setCodeErr(t("recoverpaswword.passwordnotchanged"))
        }
      },
      (error) => {
        setCodeErr(t("recoverpaswword.incorrectverification"))
        dispatch(recoverPasswordActions.setError(error));
      }
    );
  };
  const verifyCode = (e) => {
    e.preventDefault();
    dispatch(recoverPasswordActions.setPage(3));
  };
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <div className="recover-password-content">
      {page === 1 ? (
        <form onSubmit={sendVerificationCode}>
          <div className="form-grp">
          <div className="form-error">{verificationError}</div>
            <label htmlFor="email">{t('recoverpaswword.email')}</label>
            <input
              className="input"
              type="email"
              id="email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="form-error"></div>
          </div>
          <div className="form-grp">{t('recoverpaswword.forgetText')}</div>
          <div className="form-grp">
            {" "}
            <div className="loading" translate="no" >{isLoading && <CircularProgress />}</div>
            <input
              type="submit"
              value={t('recoverpaswword.sendVerificationCode')}
              className="btn btn-block"
            />
          </div>
        </form>
      ) : (
        <form onSubmit={changePassword}>
          <div className="form-grp">
            <label htmlFor="code">{t('recoverpaswword.vercode')}</label>
            <input
              className="input"
              type="text"
              id="code"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
            <div className="form-error">{codeErr}</div>
          </div>
          <div className="form-grp">
            <label htmlFor="email">{t('recoverpaswword.password')}</label>
            <input
              className="input"
              type="password"
              id="password1"
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              value={password1}
              onChange={(e) => {
                setPassword1(e.target.value);
              }}
            />
            <div className="form-error">{password1Err}</div>
          </div>
          <div className="form-grp">
            <label htmlFor="email">{t('recoverpaswword.cpassword')}</label>
            <input
              className="input"
              type="password"
              id="password2"
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              value={password2}
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
            />
            <div className="form-error">{password2Err}</div>
          </div>
          <div className="form-grp">
            {" "}
            <div className="loading" translate="no" >{isLoading && <CircularProgress />}</div>
            <input
              type="submit"
              value="Change password"
              className="btn btn-block"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default RecoverPasswordContent;
