import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../utils/url";
import { recipeSlice } from "../../slices/recipe";
import { infoapi } from "../serviceApi/infoapi"
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import topImage from "../../new-assets/pexels-shantanu-pal-2802527 1.png";
import '../scss-styles/recipesDetail.scss'
const RecipeDetail = (props) => {
  const location = props.location.pathname;
  const recipeIDf = location.substring(location.lastIndexOf("/") + 1);
  const filOne = recipeIDf.split(".");
  const recipeID = filOne[1];
  const recipeActions = recipeSlice.actions;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(recipeActions.setAreRecipesLoading(false));
    infoapi.getSingleRecipe(recipeID).then(
      (response) => {
        if (response.data.recipe) {
          dispatch(recipeActions.setAreRecipesLoading(true));
          dispatch(recipeActions.setRecipes(response.data.recipe));
        }
      },
      (error) => {
        dispatch(recipeActions.setError(error));
      }
    );
  }, []);
  var recipe = useSelector((state) => state.recipe.recipes);
  const currentlang = localStorage.getItem("lang");
  if (currentlang == "en") {
    recipe = JSON.parse(JSON.stringify(recipe).split('"enName":').join('"Name":'));
    recipe = JSON.parse(JSON.stringify(recipe).split('"enDescription":').join('"Description":'));
    recipe = JSON.parse(JSON.stringify(recipe).split('"enpreparation":').join('"preparation":'));
    recipe = JSON.parse(JSON.stringify(recipe).split('"eningredients":').join('"ingredients":'));
  }
  const areRecipesLoading = useSelector((state) => state.recipe.areRecipesLoading);
  const { t } = useTranslation();
  const md1 = useMediaQuery({ query: "(max-width: 1368px)" });
  const md2 = useMediaQuery({ query: "(min-width: 650px)" });
  const md = md1 && md2;
  const smallDevice = useMediaQuery({ query: "(max-width: 600px)" });
  window.scrollTo(0, 0)
  var arrayData = []
  if (recipe.preparation) {
    const laPreparation = recipe.preparation
    var dataToArray = laPreparation.split('\n')
    for (let i = 0; i < dataToArray.length; i++) {
      arrayData.push({ Name: dataToArray[i] })
    }
  }
  var ingriedentsData = []
  if (recipe.ingredients) {
    const laIngriedents = recipe.ingredients
    var ingriedentstoArray = laIngriedents.split('\n')
    for (let i = 0; i < ingriedentstoArray.length; i++) {
      ingriedentsData.push({ Name: ingriedentstoArray[i] })
    }
  }
  const languages=localStorage.getItem("lang");
  return (
    <div className="recipeDetails">
      {md ?
        <div className="ipadView">
          <div className="top">
            <img src={topImage} alt="top img" />
          </div>
          <div className="recipesContents">
            {languages==="de"?<a href="/AlleRezepte" className="back">
              <svg
                class="back-arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
              </svg>
              {t('productDetail.back')}
            </a>:<a href="/AllRecipes" className="back">
              <svg
                class="back-arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
              </svg>
              {t('productDetail.back')}
            </a>}
            {smallDevice ?<div className="SmallDevicesslider">
              {!recipe.Image ? "" :
                <img src={`${imageurl}images/${recipe.Image}`} alt="recipe" className="img" />
              }
            </div>:<div className="slider">
              {!recipe.Image ? "" :
                <img src={`${imageurl}images/${recipe.Image}`} alt="recipe" className="img" />
              }
            </div>}
            <div className="mobile">
              <h4 className="titles">{recipe.Name === 'Null' ? "" : recipe.Name}</h4>
              {recipe.Description === 'Null' ? "" : <p className="similarContents">
                {recipe.Description}
              </p>}
            </div>
          </div>
          <div className="ingredeints">
            <h6>{t('recipes.recipeIngredients')}</h6>
            <h4>
            </h4>
            {ingriedentsData.length === 0 ? ""
              : ingriedentsData.map((ingriedents) => {
                return (
                  <p>{ingriedents.Name === "Null" ? "" : <span className="dots1"></span>}{"  "}{ingriedents.Name === "Null" ? "" : <div className="post__description" dangerouslySetInnerHTML={{ __html: ingriedents.Name}}  />}</p>);
              })}
          </div>
          <div className="sectioned">
            <div>
              <h6>{t('recipes.preparationstep')}</h6>
              {arrayData.length === 0 ? ""
                : arrayData.map((description) => {
                  return (
                    <p>{description.Name === "Null" ? "" : <span className="dots"></span>}{"  "}{description.Name === "Null" ? "" : <div className="post__description" dangerouslySetInnerHTML={{ __html: description.Name}}  />}</p>);
                })}
            </div>
          </div>
        </div> :
        <>
          <div className="top">
            <img src={topImage} alt="top img" />
          </div>
          <div className="recipesContents">
          {languages==="de"?<a href="/AlleRezepte" className="back">
              <svg
                class="back-arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
              </svg>
              {t('productDetail.back')}
            </a>:<a href="/AllRecipes" className="back">
              <svg
                class="back-arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
              </svg>
              {t('productDetail.back')}
            </a>}
            {/* <div className="slider">
              {!recipe.Image ? "" :
                <img src={`${imageurl}images/${recipe.Image}`} alt="recipe" className="img" />}
            </div> */}
                        {smallDevice ?<div className="SmallDevicesslider">
              {!recipe.Image ? "" :
                <img src={`${imageurl}images/${recipe.Image}`} alt="recipe" className="img" />
              }
            </div>:<div className="slider">
              {!recipe.Image ? "" :
                <img src={`${imageurl}images/${recipe.Image}`} alt="recipe" className="img" />
              }
            </div>}
            <div className="mobile">
              {/* <h4>{t('recipes.recipeDescription')}</h4> */}
              <h4 className="titles">{recipe.Name}</h4>
              {recipe.Description === 'Null' ? "" : <p className="similarContents">
                {recipe.Description}
              </p>}
            </div>
          </div>
          <div className="ingredeints">
            <h6>{t('recipes.recipeIngredients')}</h6>
            {ingriedentsData.length === 0 ? ""
              : ingriedentsData.map((ingriedents) => {
                return (
                  <p >{ingriedents.Name === "Null" ? "" : <span className="dots1"></span>}{"  "}{ingriedents.Name === "Null" ? "" : <div className="post__description" dangerouslySetInnerHTML={{ __html: ingriedents.Name}}  />}</p>);
              })}
          </div>
          <div className="sectioned">
            <div>
              <h6>{t('recipes.preparationstep')}</h6>
              {arrayData.length === 0 ? ""
                : arrayData.map((description) => {
                  return (
                    <p>{description.Name === "Null" ? "" : <span className="dots"></span>}{"  "}{description.Name === "Null" ? "" :  <div className="post__description" dangerouslySetInnerHTML={{ __html: description.Name}}  />}</p>);
                })}
            </div>
          </div>
        </>
      }
    </div>
  );
};
export default RecipeDetail;
