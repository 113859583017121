import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentSlice } from "../../slices/paymentCompleted";
import { useHistory } from "react-router";
const Payment = (props) => {
  const isLoading = useSelector((state) => state.payment.isLoading);
  const paymentActions = paymentSlice.actions;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(paymentActions.setIsLoading(true));
    dispatch(paymentActions.setIsLoading(false));
    dispatch(paymentActions.setPaymentSuccessful(true));
    dispatch(paymentActions.setText("Payment Completed successfully ! "));
    history.push("/");
        return;
  }, []);
  return (
    <div className="verify">
      <div className="loading" translate="no">{isLoading && <CircularProgress />}</div>
    </div>
  );
};

export default Payment;
