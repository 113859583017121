import { Col, Row } from "react-bootstrap";
import { useTranslation,Trans } from 'react-i18next';
const OrderListHeader = () => {
  const { t } = useTranslation();
   return <div className="cart-list-header">
        <Row>
            <Col lg={6} sm={6} style={{paddingLeft:"30px"}}>
              {t('order.Product')}
            </Col>
            <Col lg={2} sm={2}>
              {t('order.Quantity')}
            </Col>
              <Col lg={2}>
              {t('order.Price')}
            </Col>
            <Col lg={2} sm={2}>
              {t('order.Total')}
            </Col>
        </Row>      
    </div>

}
export default OrderListHeader;