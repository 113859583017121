import Title from "./title"
import Contacts from './contactdetails'
const Contact= (props) => {
     const location = props.location.pathname
     const route = location.substring(location.lastIndexOf('/') + 1)
    return <div className="contact">
       <Title title={route}/>
       <Contacts category={route}/>
    </div>
}
export default Contact;