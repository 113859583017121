import { createSlice } from "@reduxjs/toolkit";
export const partnerSlice = createSlice({
    name:'partners',
    initialState: {
        partners:[],
        arePartnersLoading:false,
        error:"",
        allPartners:[],
    },
    reducers: {
        setPartners:(state,action) => {
            state.partners = action.payload;
        },
        setAllPartners:(state,action) => {
            state.allPartners = action.payload;
        },
        setError:(state,action) => {
            state.error = action.payload;
        },
        setArePartnersLoading:(state,action) => {
            state.arePartnersLoading = action.payload;
        },
    }
});