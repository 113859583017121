import HomeCarousel from "../new-carousel/home-carousel.component";
import ProductsSlider from "../products-slider/products-slider.component";
import Featured from "../featured/featured.component";
import Recipies from "../recipies/recipies.component";
import NewProducts from "../new-products/new-products";
import Partners from "../partners/partners.component";
import News from "../news/news.component";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import '../scss-styles/homecarousel.scss'
const Home = () => {
  const { t } = useTranslation();
  const md1 = useMediaQuery({ query: "(max-width: 1368px)" });
  const md2 = useMediaQuery({ query: "(min-width: 600px)" });
  const md =md1 && md2
    let width = "inherit";
  if(window.innerWidth=="1366"){
    width="90%"
  }
  const style = {
    width: width,
    maxWidth:"2000px"
  };
  return (
    <>
      {md ? <div className="mobilehome">
        <HomeCarousel />
        <div
          style={{
            margin: "100px auto",
            marginBottom: "27px",
            width: "fit-content",
            color: "#8A9A5B",
            // fontSize: "50px",
          }}>
          <h3>{t('homecarousel.popularproducts')}</h3>
        </div>
        <ProductsSlider />
        <Featured />
        <Recipies />
        <NewProducts />
        <Partners />
        <News />
      </div> :
        <div style={style}>
          <HomeCarousel />
          <div
            style={{
              margin: "100px auto",
              marginBottom: "27px",
              width: "fit-content",
              color: "#8A9A5B",
              // fontSize: "34px",
            }}>
            <h3>{t('homecarousel.popularproducts')}
            </h3>
          </div>
          <ProductsSlider />
          <Featured />
          <Recipies />
          <NewProducts />
          <Partners />
          <News />
        </div>
      }
    </>
  );
};
export default Home;
