import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../utils/url";
import { partnerSlice } from "../../slices/partner";
import { CircularProgress } from "@material-ui/core";
import { infoapi } from "../serviceApi/infoapi"
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import '../scss-styles/partnerStyles.scss'
const AllPartners = () => {
  const partnerActions = partnerSlice.actions;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(partnerActions.setArePartnersLoading(true));
    infoapi.getAllPartners().then((response) => {
      if(response.data.partners){
        dispatch(partnerActions.setArePartnersLoading(false));
        dispatch(partnerActions.setAllPartners(response.data.partners));
      }
    },
      (error) => {
        dispatch(partnerActions.setError(error));
      }
    );
  }, []);
  const { t } = useTranslation();
  const currentlang = localStorage.getItem("lang");
  var allPartners = useSelector((state) => state.partners.allPartners);
  if (currentlang == "en") {
    allPartners = JSON.parse(JSON.stringify(allPartners).split('"enName":').join('"Name":'));
    allPartners = JSON.parse(JSON.stringify(allPartners).split('"enDescription":').join('"Description":'));
    var partnerr = [];
    if (allPartners.length < 3) { partnerr = []; }
    else {
      for (let i = 0; i < allPartners.length / 3; i++) {
        var j = i
        partnerr.push({
          Description1: allPartners[j].Description,
          Name1: allPartners[j].Name,
          Image1: allPartners[j].Image,
          Description2: allPartners[j + 1].Description,
          Name2: allPartners[j + 1].Name,
          Image2: allPartners[j + 1].Image,
          Description3: allPartners[j + 2].Description,
          Name3: allPartners[j + 2].Name,
          Image3: allPartners[j + 2].Image
        });
        j = i + 2
      }
    }
  }
  else if (currentlang == "de") {
    var partnerr = [];
    if (allPartners.length < 3) { partnerr = []; }
    else {
      for (let i = 0; i < allPartners.length / 3; i++) {
        var j = i
        partnerr.push({
          Description1: allPartners[j].Description,
          Name1: allPartners[j].Name,
          Image1: allPartners[j].Image,
          Description2: allPartners[j + 1].Description,
          Name2: allPartners[j + 1].Name,
          Image2: allPartners[j + 1].Image,
          Description3: allPartners[j + 2].Description,
          Name3: allPartners[j + 2].Name,
          Image3: allPartners[j + 2].Image
        });
        j = i + 2
      }
    }
  }
  const md1 = useMediaQuery({ query: "(max-width: 1000px)" });
  const md2 = useMediaQuery({ query: "(min-width: 830px)" });
  const md3 = useMediaQuery({ query: "(max-width: 829px)" });
  const md = md1 && md2
  if(md){
    var device="ipadView";
  }
  else if(md3){device="mobileView"}
  else{
    device="Desktop"
  }
  return (
    <>
      <div className="allPartnerMainheader">
        <h1>{t('partners.ourpartner')}</h1>
      </div>
      <div className="allpartnerStyles2">
        {!md3 ?
          <>
            {md ? <div className="ipadView">
              {partnerr.length === 0 ? <b>{<CircularProgress />}</b>
                : partnerr.map((partner) => {
                  return (
                    <div key={partner.id}>
                      <h4 dangerouslySetInnerHTML={{ __html:partner.Name1}}></h4>
                      <p></p>
                      <div className="gallery tow">
                        {/* <div className="top-bottom "> */}
                          <div className="gallery ">
                            <span dangerouslySetInnerHTML={{ __html:partner.Description1}}></span>
                            <img src={`${imageurl}images/${partner.Image1}`} alt="" className="partner" />
                            <span dangerouslySetInnerHTML={{ __html:partner.Description2}}></span>
                            <img src={`${imageurl}images/${partner.Image2}`} alt="" className="partner" />
                            <img src={`${imageurl}images/${partner.Image3}`} alt="" />
                            <span dangerouslySetInnerHTML={{ __html:partner.Description3}}></span>
                          </div>
                        {/* </div> */}
                      </div>
                    </div>
                  )
                })}
            </div> :
              <>
                <div className="Desktop">
                  {!partnerr ? <b>{<CircularProgress />}</b>
                    : partnerr.map((partner) => {
                      return (
                        <div key={partner.id}>
                          <h4 dangerouslySetInnerHTML={{ __html:partner.Name1}}></h4>
                          <p></p>
                          <div className="gallery ">
                            <span dangerouslySetInnerHTML={{ __html:partner.Description1}}></span>
                            <img src={`${imageurl}images/${partner.Image1}`} alt="" className="partner" />
                            <span dangerouslySetInnerHTML={{ __html:partner.Description2}}></span>
                            <img src={`${imageurl}images/${partner.Image2}`} alt="" className="partner" />
                            <img src={`${imageurl}images/${partner.Image3}`} alt="" />
                            <span dangerouslySetInnerHTML={{ __html:partner.Description3}}></span>
                          </div>
                          </div>
                        // </div>
                      )
                    })}
                </div>
              </>
            }</>
          : <div className="mobileView">
            <br />
            {!partnerr ? <b>{<CircularProgress />}</b>
              : partnerr.map((partner) => {
                return (
                  <div key={partner.id}>
                    <h4 dangerouslySetInnerHTML={{ __html:partner.Name1}}></h4>
                    <p></p>
                    <div className="gallery tow">
                      <div className="top-bottom ">
                        <div className="gallery ">
                          <span dangerouslySetInnerHTML={{ __html:partner.Description1}}></span>
                          <img src={`${imageurl}images/${partner.Image1}`} alt="" className="partner" />
                          <span dangerouslySetInnerHTML={{ __html:partner.Description2}}></span>
                          <img src={`${imageurl}images/${partner.Image2}`} alt="" className="partner" />
                          <span dangerouslySetInnerHTML={{ __html:partner.Description3}}></span>
                          <img src={`${imageurl}images/${partner.Image3}`} alt="" /><br /><br />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>}
      </div>
    </>
  );
};
export default AllPartners;
