import { createSlice } from "@reduxjs/toolkit";
export const verifySlice = createSlice({
  name: "verify",
  initialState: {
    isLoading: false,
    text: "Verfiying your account",
    error:""
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError:(state,action) => {
      state.error = action.payload;
  },
    setText: (state, action) => {
      state.text = action.payload;
    },
  },
});
