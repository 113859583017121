import logo from "../../new-assets/AdeySpiceLogol.png";
import React, { useState,useEffect } from "react";
import { faUser, faShoppingCart, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import '../scss-styles/navStyle.scss';
import { useTranslation} from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { set } from "react-ga";
import { cartSlice } from "../../slices/cart";
function NewNav() {
  const cartIcon = <FontAwesomeIcon icon={faShoppingCart} size="lg" />;
  const profileIcon = <FontAwesomeIcon icon={faUser} size="lg" />;
  const caretDownIcon= <FontAwesomeIcon icon={faCaretDown} size="1x" />; 
  const rightDrawerActions = rightDrawerSlice.actions;
  const isUserLogged = useSelector((state) => state.login.isUserLogged);
  var numberOfProductsInCart = useSelector(
    (state) => state.cart.cartItems.length
  );
    /////////////////////////
    const cartActions = cartSlice.actions;
    const numberofProducts=localStorage.getItem("numberofProducts")
    if(numberofProducts>0){
      numberOfProductsInCart=numberofProducts;
    }
    useEffect(() => {
      let storedData = localStorage.getItem("myArray")|| [];
    if(storedData?.length>0){
      let retrievedArray = JSON.parse(storedData);
    retrievedArray.forEach(element => {
      dispatch(
        cartActions.addCartItem({ product: element.product,quantity:element.quantity })
      );
    });}
    if(numberofProducts>0){
      numberOfProductsInCart=numberofProducts;
    }
    })
    //////////////////
  const dispatch = useDispatch();
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const [languagess, setLanguages] = useState("");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  var menus = [{ "shop": "Englisch", "values": "en" }, { "shop": "Deutsch", "values": "de" }]
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  function changeLanguage(e) {
    i18n.changeLanguage(e);
    localStorage.setItem("lang", e);
  }
  if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", "de");
    const currentlang=localStorage.getItem("lang");
    i18n.changeLanguage(currentlang);
  }
  const currentlang=localStorage.getItem("lang");
  if(currentlang=="en"){
     menus = [{ "shop": "English", "values": "en" }, { "shop": "German", "values": "de" }]
  }
  const md1 = useMediaQuery({ query: "(max-width: 1368px)" });
  const md2 = useMediaQuery({ query: "(min-width: 601px)" });
  const smallDevices=useMediaQuery({ query: "(max-width: 600px)" });
  const md =md1 && md2;
  const languages=localStorage.getItem("lang");
  return (
    <div className="navStyle">
      <div className="icons">
        <nav className="nav">
          <Link to="/"> <img src={logo} alt="logo" style={{width: "75px",height: "50px"}}/></Link>
          {smallDevices ? <ul className={active} style={{ paddingTop: "20px"}}>
            {languages==="de" ?<><li className="nav__item">
              <Link to="/Gewürze"onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.shop')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/AlleRezepte" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.recipe')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/Überuns" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.aboutus')}</Link>
            </li></>:
           <> <li className="nav__item">
              <Link to="/shop" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.shop')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/AllRecipes" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.recipe')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/aboutus" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.aboutus')}</Link>
            </li></>}
            <div className="dropdown">
              <div className="dropbtn"><span>{t('contactus.language')}
              </span>
              <span className="caretDown">{caretDownIcon}{''}</span>
              </div>
              <div className="dropdown-content">
                {menus.map((menu, index) => {
                  return (
                    <li className="menu-items" key={index}>
                      <a onClick={() => {
                         setLanguages(menu.values);
                        dispatch(rightDrawerActions.setLanguages(menu.values));
                        changeLanguage(menu.values)
                        setIcon("nav__toggler");
                        setActive("nav__menu")
                      }}>{menu.shop}</a>
                    </li>
                  );
                })}
              </div>
            </div>
          </ul>:<>
          {md ?<ul className={active} style={{ paddingTop: "20px",
          // fontSize:"20px" 
          }}>
            {languages==="de" ?<><li className="nav__item">
              <Link to="/Gewürze"onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.shop')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/AlleRezepte" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.recipe')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/Überuns" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.aboutus')}</Link>
            </li></>:
           <> <li className="nav__item">
              <Link to="/shop" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.shop')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/AllRecipes" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.recipe')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/aboutus" onClick={() => {setIcon("nav__toggler");setActive("nav__menu")}}>{t('header.aboutus')}</Link>
            </li></>}
            <div className="dropdown">
              <div className="dropbtn"><span>{t('contactus.language')}
              </span>
              <span className="caretDown">{caretDownIcon}{''}</span>
              </div>
              <div className="dropdown-content">
                {menus.map((menu, index) => {
                  return (
                    <li className="menu-items" key={index}>
                      <a onClick={() => {
                         setLanguages(menu.values);
                        dispatch(rightDrawerActions.setLanguages(menu.values));
                        changeLanguage(menu.values)
                        setIcon("nav__toggler");
                        setActive("nav__menu")
                      }}>{menu.shop}</a>
                    </li>
                  );
                })}
              </div>
            </div>
          </ul>
          :
          <ul className={active} style={{ paddingTop: "20px" }}>
            {languages==="de" ?<><li className="nav__item">
              <Link to="/Gewürze">{t('header.shop')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/AlleRezepte">{t('header.recipe')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/Überuns">{t('header.aboutus')}</Link>
            </li></>:
           <><li className="nav__item">
              <Link to="/shop">{t('header.shop')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/AllRecipes">{t('header.recipe')}</Link>
            </li>
            <li className="nav__item">
              <Link to="/aboutus">{t('header.aboutus')}</Link>
            </li></>}
            <div className="dropdown">
              <div className="dropbtn"><span>{t('contactus.language')}
              </span>
              <span className="caretDown">{caretDownIcon}{''}</span>
              </div>
              <div className="dropdown-content">
                {menus.map((menu, index) => {
                  return (
                    <li className="menu-items" key={index}>
                      <a onClick={() => {
                        setLanguages(menu.values);
                        dispatch(rightDrawerActions.setLanguages(menu.values));
                        changeLanguage(menu.values)
                      }}>{menu.shop}</a>
                    </li>
                  );
                })}
              </div>
            </div>
          </ul>
          }</>}
          <ul
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: "50px",
              paddingTop: "20px",
            }}>
            <li>
              <div
                className="icon"
                onClick={() => {
                  if (isUserLogged) {
                    showRightDrawer("profile");
                  } else {
                    showRightDrawer("login");
                  }
                }}>
                {profileIcon}
              </div>{" "}
            </li>
            <div
              className="icon"
              onClick={() => {
                // if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
                //   showRightDrawer("login");
                //   return;
                // }
                // else{
                showRightDrawer("cart");
                // }
              }}
              >
              {" "}
              {cartIcon}
              <div className="badge">{numberOfProductsInCart}</div>
            </div>
          </ul>
          <div onClick={navToggle} className={icon}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default NewNav;
