import Title from "./title"
import HumanrightcomplaintContent from './humanrightcomplaintContent'
const Humanrightcomplaint= (props) => {
     const location = props.location.pathname
     const route = location.substring(location.lastIndexOf('/') + 1)
    return <div className="humanrightcomplaint">
       <Title title={route}/>
       <HumanrightcomplaintContent category={route}/>
    </div>
}
export default Humanrightcomplaint;