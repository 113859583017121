import React from "react";
// import config from "../utils/config.json";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// const stripePromise = loadStripe("pk_test_51KMttpCWqn8BHrtlF0Q7NH95ZJZ3ZwFwf0TT1a9YM6ZXQayQhz8bI4apBCA0bojKp1ZFSHn3BSDPwCH6yrgWHdza00UxgjquYg");
const stripePromise = loadStripe("pk_test_51MYpwSDaidsEYsYhKN4s5sHqAgnjyn9j4oauQiFMBV0SL2kLt5vxaVIrrsOjxVyJQIyXOpnE6vC2PJJcryjWIJRt00Y8T7ROBY");

  const Stripe = (props) => {
  const options = {
    clientSecret: props.client_secret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {props.children}
    </Elements>
  );
}
export default Stripe;
