import React, { useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel2";
import RecipieCard from "../recipie-card/recipie-card.component";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../utils/url";
import { recipeSlice } from "../../slices/recipe";
import { CircularProgress } from "@material-ui/core";
import { infoapi } from "../serviceApi/infoapi"
import { useMediaQuery } from "react-responsive";
import { useTranslation} from 'react-i18next';
import { useHistory } from "react-router";
import '../scss-styles/recipesComponent.scss'
const Recipies = () => {
  const car = useRef(null);
  const recipeActions = recipeSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(0)
  useEffect(() => {
    dispatch(recipeActions.setAreRecipesLoading(false));
    infoapi.getmethod("getAllRecipe").then(
      (response) => {
        if (response.data.recipe) {
          dispatch(recipeActions.setAreRecipesLoading(true));
          dispatch(recipeActions.setRecipes(response.data.recipe));
          setloading(1)
        }
      },
      (error) => {
        dispatch(recipeActions.setError(error));
      }
    );
  }, []);
  var recipe = useSelector((state) => state.recipe.recipes);
  const currentlang = localStorage.getItem("lang");
  if (currentlang == "en") {
    recipe = JSON.parse(JSON.stringify(recipe).split('"enName":').join('"Name":'));
    recipe = JSON.parse(JSON.stringify(recipe).split('"enDescription":').join('"Description":'));
  }
  const areRecipesLoading = useSelector((state) => state.recipe.areRecipesLoading);
  var options = {
    items: 4,
    nav: true,
    loop: true,
    // autoplay: true,
    touchDrag: true,
    mergeFit: true,
    autoplayTimeout: 5000,
    margin: 20,
    responsiveRefreshRate: 200,
    navText: [
      '<svg class="right-arrow fill-Orange"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
      ' <svg class="left-arrow fill-Orange" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    dots: false,
  };

  const events = {
    // onDragged: function(event) {...},
    // onChanged: function(event) {...}
  };
  const md = useMediaQuery({ query: "(max-width: 600px)" });
  const tblmin = useMediaQuery({ query: "(min-width: 601px)" });
  const maxtblt = useMediaQuery({ query: "(max-width: 698px)" });
  const mediumtblmin = useMediaQuery({ query: "(min-width: 698px)" });
  const meduimmaxtblt = useMediaQuery({ query: "(max-width: 820px)" });
  const xltblmin = useMediaQuery({ query: "(min-width: 821px)" });
  const xlmaxtblt = useMediaQuery({ query: "(max-width: 940px)" });
  if(tblmin && maxtblt){
     options = {
      // items: 2,
      nav: true,
      loop: true,
      autoplay: true,
      touchDrag: true,
      mergeFit: true,
      autoplayTimeout: 5000,
      margin: 20,
      responsiveRefreshRate: 200,
      navText: [
        '<svg class="black-right-arrow fill-Orange"style="width: 40px;transform: translate(-130px, -230px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
        ' <svg class="black-left-arrow fill-Orange" style="width: 40px;transform: translate(130px, -230px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
      ],
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 2,
        },
        900: {
          items: 2,
        },
      },
      dots: false,
    };
  }
  if(md){
    options = {
     // items: 2,
     nav: true,
     loop: true,
     autoplay: true,
     touchDrag: true,
     mergeFit: true,
     autoplayTimeout: 5000,
    //  margin: 20,
     responsiveRefreshRate: 200,
    //  navText: [
    //    '<svg class="black-right-arrow fill-Orange"style="width: 12%;transform: translate(-190%, -235px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
    //    ' <svg class="black-left-arrow fill-Orange" style="width: 12%;transform: translate(189%, -235px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
    //  ],//185%
    navText: [
      '<svg class="black-right-arrow fill-Orange"style="width: 12%;transform: translate(-175%, -300px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
      ' <svg class="black-left-arrow fill-Orange" style="width: 12%;transform: translate(220%, -300px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
    ],//185%
     responsive: {
       0: {
         items: 1,
       },
       600: {
         items: 1,
       },
       900: {
         items: 1,
       },
     },
     dots: false,
   };
 }
  if(mediumtblmin && meduimmaxtblt){
    options = {
     // items: 2,
     nav: true,
     loop: true,
     autoplay: true,
     touchDrag: true,
     mergeFit: true,
     autoplayTimeout: 5000,
     margin: 20,
     responsiveRefreshRate: 200,
     navText: [
       '<svg class="black-right-arrow fill-Orange"style="width: 40px;transform: translate(-160px, -230px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
       ' <svg class="black-left-arrow fill-Orange" style="width: 40px;transform: translate(160px, -230px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
     ],
     responsive: {
       0: {
         items: 2,
       },
       600: {
         items: 2,
       },
       900: {
         items: 2,
       },
     },
     dots: false,
   };
 }
 if(xltblmin && xlmaxtblt){
  options = {
   // items: 2,
   nav: true,
   loop: true,
   autoplay: true,
   touchDrag: true,
   mergeFit: true,
   autoplayTimeout: 5000,
   margin: 20,
   responsiveRefreshRate: 200,
   navText: [
     '<svg class="black-right-arrow fill-Orange"style="width: 40px;transform: translate(-180px, -230px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
     ' <svg class="black-left-arrow fill-Orange" style="width: 40px;transform: translate(180px, -230px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
   ],
   responsive: {
     0: {
       items: 2,
     },
     600: {
       items: 2,
     },
     900: {
       items: 2,
     },
   },
   dots: false,
 };
}
  // if (md) {
  //   options.dots = false;
  //   options.items = 1;
  //   options.navText[0] = '<svg class="black-right-arrow fill-black"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>'
  //   options.navText[1] = ' <svg class="black-left-arrow fill-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>'
  // }
  const { t } = useTranslation();
  // const languages = useSelector((state) => state.rightDrawer.languages);
  const languages=localStorage.getItem("lang");
  return (
    <div className="recipesComponent" id="recipie">
      <h3 className="titles">
        {t('recipes.title')}
      </h3>
      {loading === 0 ? <b translate="no">{<CircularProgress />}</b> :
        <div>
          <OwlCarousel
            ref={car}
            options={options}
            events={events}
            className="rel"
          >
            {recipe.length === 0
              ? ""
              : recipe.map((recipes) => {
                return (
                  <RecipieCard key={recipes.id}
                    image={`${imageurl}images/${recipes.Image}`}
                    name={recipes.Name}
                    description={recipes.Description}
                    details={recipes}
                  />
                );
              })}
          </OwlCarousel>
        </div>
      }
      <button
        onClick={() => {
          if(languages==="de"){
            history.push("/AlleRezepte");
          }
          else{history.push("/AllRecipes");}
        }}>
         {t('recipes.forallrecipes')}
      </button>
    </div>
  );
};
export default Recipies;
