import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { faTrashAlt, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { cartSlice } from "../../slices/cart";
import { useEffect, useState } from "react";
import FetchCartImages from "../utils/fetch_cart_images";
import { productsSlice } from "../../slices/products";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import Close from "@material-ui/icons/Close";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { productsApi } from "../serviceApi/productsApi";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { adminServiceApi } from "../serviceApi/adminServiceApi";
import { Col, Row } from "react-bootstrap";
import cartSymbol from "../../new-assets/cartImage2.PNG";
import { orderServiceAPI } from "../serviceApi/orderServiceAPI";
import { orderSlice } from "../../slices/order";
import { useMediaQuery } from "react-responsive";
import { countryToAlpha2, countryToAlpha3 } from "country-to-iso";
const Cart = () => {
  const productImages = useSelector((state) => state.cart.productImages);
  const currencies = useSelector((state) => state.cart.currencies);
  const percentages = useSelector((state) => state.cart.percentages);
  const orderActions = orderSlice.actions;
  const cartIcon = <FontAwesomeIcon icon={faShoppingCart} size="5x" />;
  var cartItems = useSelector((state) => state.cart.cartItems);
  const removeIcon = <FontAwesomeIcon icon={faTrashAlt} />;
  const plusIcon = <FontAwesomeIcon icon={faPlus} />;
  const minusIcon = <FontAwesomeIcon icon={faMinus} />;
  const rightDrawerActions = rightDrawerSlice.actions;
  const productActions = productsSlice.actions;
  const cartActions = cartSlice.actions;
  const isUserLogged = useSelector(state => state.login.isUserLogged);
  const [SaveBView, setSaveBView] = useState(false);
  const [OrderedStatus, setOrderedStatus] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const actions = rightDrawerSlice.actions;
  const currentlang = localStorage.getItem("lang");
  const closeRightModal = () => {
    orderServiceAPI.getUserOrder("getUserOrdered", localStorage.getItem("user_id")).then(
      (response) => {
        dispatch(orderActions.setOrdersLoading(false));
        dispatch(orderActions.setOrderItem(response.data.result));
      },
      (error) => {
        dispatch(orderActions.setError(error));
      }
    );
    dispatch(actions.hideDrawer());
    document.body.style.overflow = "visible";
    if (OrderedStatus) {
      cartItems.forEach((cartItem) => {
        dispatch(cartActions.removeCartItem(cartItem.product.id));
      });
    }
  };
  const [loading, setloading] = useState(0)
  const type = useSelector((state) => state.rightDrawer.type);
  let headerText = "";
  const { t } = useTranslation();
  switch (type) {
    case "login":
      headerText = t('profile.Login')
      break;
    case "profile":
      headerText = t('profile.Profile')
      break;
    case "cart":
      headerText = t('profile.cart')
      break;
    case "register":
      headerText = t('profile.Register')
      break;
  }
  const userId = localStorage.getItem("user_id");
  useEffect(() => {
      /////////////////////////
  let storedData = localStorage.getItem("myArray")|| [];
  if(storedData.length>0){
    let retrievedArray = JSON.parse(storedData);
    retrievedArray.forEach(element => {
      dispatch(cartActions.addCartItem({ product: element.product, quantity: element.quantity }));
    });
    // cartItems= retrievedArray;
  }
  //////////////////
    const userId = localStorage.getItem("user_id");
    productsApi.getmethod("getPercentageDiscount").then(
      (response) => {
        dispatch(cartActions.setPercentageDiscounts(response.data.result));
      },
      (error) => {
        dispatch(cartActions.setError(error));
      }
    );
    orderServiceAPI.getUserOrder("getUserOrdered", userId).then(
      (response) => {
        if (response.data.result) {
          dispatch(orderActions.setOrdersLoading(false));
          localStorage.setItem("statusCart","false")
          dispatch(orderActions.setOrderItem(response.data.result));
        }
      },
      (error) => {
        dispatch(orderActions.setError(error));
      }
    );
    adminServiceApi.getmethod("getLatestCurrency").then(
      (response) => {
        dispatch(cartActions.setCurrencies(response.data.currency));
      },
      (error) => {
        dispatch(cartActions.setError(error));
      }
    );
    userServiceApi.getParamMethod("getcheckUserId", userId).then(
      (response) => {
        dispatch(cartActions.setCheckuserId(response.data.check));
      },
      (error) => {
        dispatch(cartActions.setError(error));
      }
    );
    orderServiceAPI.getCountryName().then((response) => {
      if (response.data.country_name) {
          const isEuMember = require("is-eu-member").isEuMember;
          dispatch(orderActions.setCountryName(response.data.country_name));
          localStorage.setItem("EumemberStatus",EumemberStatus)
         dispatch(cartActions.setEUMemberStatus(isEuMember(countryToAlpha2(response.data.country_name))));
        orderServiceAPI.getSingleShippingValue(response.data.country_name).then(
          (response) => {
            if (response.data.shipping) {
              dispatch(cartActions.setSingleShipping(response.data.shipping));
              dispatch(cartActions.setShippingStatus(true));
            }
          },
          (error) => {
            dispatch(orderActions.setError(error));
          }
        );
      }
    }).catch((error) => {
      return error;
    });
  }, [userId]);
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  const hideDrawer = () => {
    const userId = localStorage.getItem("user_id");
    if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
      showRightDrawer("login");
      return;
    } else {
      dispatch(rightDrawerActions.hideDrawer());
      document.body.style.overflow = "visible";
      if (OrderedStatus) {
        cartItems.forEach((cartItem) => {
          dispatch(cartActions.removeCartItem(cartItem.product.id));
        });
      }
    }
  };
  const removeCartItem = (id) => {
    dispatch(cartActions.removeCartItem(id));
  };
  const increaseQuantity = (id) => {
    dispatch(cartActions.increaseQuantity(id));
  };
  const decreaseQuantity = (id) => {
    dispatch(cartActions.decreaseQuantity(id));
  };
  const shippingStatus = useSelector((state) => state.cart.shippingStatus);
  var shippingValue = useSelector((state) => state.cart.shippingValue);
  const getTotalShipping = () => {
    let total = 0;
    if (!shippingStatus) {
      var replaceShippingValue = "9,9";
      replaceShippingValue = replaceShippingValue.replace(/,/g, '.')
      total = parseFloat(replaceShippingValue).toFixed(2);
    }
    else {
      var replaceShippingValue1 = shippingValue.shippingValue;
      replaceShippingValue1 = replaceShippingValue1.replace(/,/g, '.')
      total = parseFloat(replaceShippingValue1).toFixed(2);
    }
    return total;
  };
  const getTotalPrice = () => {
    let total = 0;
    cartItems.forEach((cartItem) => {
      var replacePrice = cartItem.product.Price;
      replacePrice = replacePrice.replace(/,/g, '.')
      total += replacePrice * cartItem.quantity;
    });
    return parseFloat(total.toFixed(2));
  };
  const getTotalTax = () => {
    let total = 0;
    const perc = 15;
    cartItems.forEach((cartItem) => {
      var replacePrice = cartItem.product.Price;
      replacePrice = replacePrice.replace(/,/g, '.')
      total += replacePrice * percentages.percentage / 100 * cartItem.quantity;
    });
    return parseFloat(total.toFixed(2));
  };
  const totalVat = () => {
      var totalVat = percentages.percentage;
    return totalVat;
  };
  const checkUsers = useSelector((state) => state.cart.checkUsers);
  const saveCart = () => {
    setSaveBView(true);
    if (!isUserLogged) {
      toast.info("Log in or register to save your cart! ", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(cartActions.setSavewoutLogincart(true));
      showRightDrawer("login");
      return;
    }
    setloading(1)
    const userId = localStorage.getItem("user_id");
    const cartItemsForDb = [];
    cartItems.forEach((cartItem) => {
      cartItemsForDb.push({
        user_id: userId,
        product_id: cartItem.product.id,
        quantity: cartItem.quantity,
      });
    });
    dispatch(cartActions.setSavingCart(true));
    dispatch(cartActions.setStatuscart(true));
    localStorage.setItem("statusCart","true");
    userServiceApi.postCart(cartItemsForDb).then(
      (response) => {
        if (response.data.success) {
          const cartData = { cartItemsForDb }
          userServiceApi.saveCart(cartData).then(
            (response) => {
              if (response.data.success) {
                toast.info("Order successfully submitted!", {
                  autoClose: 20000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setOrderedStatus(true);
                // cartItems.forEach((cartItem) => {
                //   dispatch(cartActions.removeCartItem(cartItem.product.id));
                // });
                orderServiceAPI.getUserOrder("getUserOrdered", localStorage.getItem("user_id")).then(
                  (response) => {
                    if (response.data.result) {
                      dispatch(orderActions.setOrdersLoading(false));
                      dispatch(orderActions.setOrderItem(response.data.result));
                      dispatch(cartActions.setStatuscart(false));
                      localStorage.setItem("statusCart","false")
                      dispatch(cartActions.setStatusOrder(true));
                      dispatch(rightDrawerActions.hideDrawer());
                      document.body.style.overflow = "visible";
                      ///////
                      // localStorage.removeItem("myArray");
                      // localStorage.removeItem("numberofProducts");
                      // ///
                      // cartItems.forEach((cartItem) => {
                      //   dispatch(cartActions.removeCartItem(cartItem.product.id));
                      // });
                      // ///
                      // switch(currentlang){
                      //   case "de":
                      //     history.push("/Bestellung");
                      //     break;
                      //     case "en":history.push("/order");
                      //     break;
                      //     default:history.push("/Bestellung")
                      // }
                    }
                  },
                  (error) => {
                    dispatch(cartActions.setStatusOrder(false));
                    dispatch(orderActions.setError(error));
                  }
                );
                ////////////
                // dispatch(cartActions.setStatusOrder(false));
                switch (currentlang) {
                  case "de":
                    history.push("/Bestellung");
                    break;
                  case "en": history.push("/order");
                    break;
                  default: history.push("/Bestellung")
                }
                ///
                cartItems.forEach((cartItem) => {
                  dispatch(cartActions.removeCartItem(cartItem.product.id));
                });
                localStorage.removeItem("myArray");
                localStorage.removeItem("numberofProducts");
                ///
                dispatch(rightDrawerActions.hideDrawer());
                document.body.style.overflow = "visible";
                return
                ////////////////
              }
              else{
                setloading(1);
                dispatch(cartActions.setSavingCart(false));
              }
              // dispatch(cartActions.setSavingCart(false));
            },
            (error) => {
              dispatch(cartActions.setError(error));
            }
          );
          setloading(2);
        }
        else{
          setloading(1);
          dispatch(cartActions.setSavingCart(false));
        }
      },
      (error) => {
        dispatch(cartActions.setError(error));
      }
    );
  };
  const grandTotalf1 = getTotalPrice() + parseFloat(getTotalShipping());
  // const grandTotalf1 = getTotalTax() + getTotalPrice() + parseFloat(getTotalShipping());
  const GRANDTOTALf2 = parseFloat(grandTotalf1);
  const languages = localStorage.getItem("lang");
  if (cartItems.length > 0) {
    var grandTotalf2display = GRANDTOTALf2.toFixed(2).toString().replace(/\./g, ',');
    var subTotal = getTotalPrice().toFixed(2).toString().replace(/\./g, ',');
    var totalTax = getTotalTax().toFixed(2).toString().replace(/\./g, ',');
    var TotalShipping = getTotalShipping().toString().replace(/\./g, ',');
  }
  const smallDevices = useMediaQuery({ query: "(max-width: 600px)" });
  const orderStatus = useSelector((state) => state.cart.statusOrder);
  const EumemberStatus = useSelector((state) => state.cart.EumemberStatus);
  return (
    <>
      <FetchCartImages />
      <div className="mini-cart">
        <div className="header">
          <div className="text">{headerText}</div>
          <div className="close-drawer" onClick={closeRightModal}>
            {" "}
            <Close />{" "}
          </div>
        </div>
        {cartItems.length === 0 ? (
          <>
            {" "}
            <div className="cartIcon">
              {/* {cartIcon} */}
              <img src={cartSymbol} alt="sefiegrui" />
            </div>
            <div className="text" translate="no">{t('cart.m11')}</div>
          </>
        ) : (
          <div className="cart-list">
            {" "}
            {cartItems.map((cartItem) => {
              let productImage = "";
              productImages.forEach((item) => {
                if (item.product_id === cartItem.product.id)
                  productImage = item.product_image;
              });
              if (currentlang == "en") {
                var Naming = cartItem.product.enme
              }
              else {
                Naming = cartItem.product.deme
              }
              return (
                <div className="cart-item">
                  <div className="right">
                    <Link>
                      <div className="product-title">
                        {Naming}
                      </div>
                    </Link>
                    <div className="price">
                      {currencies.CurrencySymbol}{" "}{cartItem.product.Price}
                    </div>
                    <Row>
                      <Col sm={10}>
                        <div className="quantity">
                          <div
                            className="minus icon"
                            onClick={() => {
                              decreaseQuantity(cartItem.product.id);
                            }}
                          >
                            {minusIcon}
                          </div>
                          <div className="amount">{cartItem.quantity}</div>
                          <div
                            className="plus icon"
                            onClick={() => {
                              increaseQuantity(cartItem.product.id);
                            }}
                          >
                            {plusIcon}
                          </div>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div
                          className="remove"
                          onClick={() => {
                            removeCartItem(cartItem.product.id);
                          }}
                        >
                          {removeIcon}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}
            {/* <div className="pricing" >
              <div className="item">
                <div className="text">{t('cart.SUBTOTAL')}</div>
                <div className="price">{currencies.CurrencySymbol}{" "}{subTotal}</div>
              </div>
              <div className="item">
                <div className="text">{t('cart.VAT')}({totalVat()}%):</div>
                <div className="price">{currencies.CurrencySymbol}{" "}{totalTax}</div>
              </div>
              <div className="item">
                <div className="text">{t("order.shippinval")}</div>
                <div className="price">{currencies.CurrencySymbol}{" "}{TotalShipping}</div>
              </div>
              <div className="item">
                <div className="text">{t('cart.GRANDTOTAL')}</div>
                <div className="price" translate="no">
                  {currencies.CurrencySymbol}{" "}{grandTotalf2display}
                </div>
              </div>
            </div> */}
            {/* {orderStatus? */}
            {loading === 1 &&
              <b> <CircularProgress /> </b>}
            {languages === "de" ? <>
              {" "}
              <div className="btn save-cart btn-block" onClick={saveCart}>
                {t('cart.SaveCart')}
              </div>{" "}
              <div className="btn view-cart btn-block" onClick={() => {
                if (languages === "de") {
                  history.push("/Gewürze");
                  hideDrawer();
                }
                else { history.push("/shop"); }
              }}>
                {t('cart.viewCart')}
              </div>
           {" "}</>
              : <>
                {" "}
                <div className="btn save-cart btn-block" onClick={saveCart}>
                  {t('cart.SaveCart')}
                </div>{" "}
                <div className="btn view-cart btn-block" onClick={() => {
                  if (languages === "de") {
                    hideDrawer();
                    history.push("/Gewürze");
                  }
                  else {
                    hideDrawer();
                    history.push("/shop");
                  }
                }}>
                  {t('cart.viewCart')}
                </div>
                {/* {EumemberStatus?<><div className="btn save-cart btn-block" onClick={saveCart}>
                  {t('cart.SaveCart')}
                </div>{" "}
                <div className="btn view-cart btn-block" onClick={() => {
                  if (languages === "de") {
                    hideDrawer();
                    history.push("/Gewürze");
                  }
                  else {
                    hideDrawer();
                    history.push("/shop");
                  }
                }}>
                  {t('cart.viewCart')}
                </div></>:<><div className="btn save-cartDisable btn-block" onClick={saveCart}>
                  {t('cart.SaveCart')}
                </div>{" "}
                <div className="btn view-cartDisable btn-block" onClick={() => {
                  if (languages === "de") {
                    hideDrawer();
                    history.push("/Gewürze");
                  }
                  else {
                    hideDrawer();
                    history.push("/shop");
                  }
                }}>
                  {t('cart.viewCart')}
                </div></>} */}
                {" "}
              </>}
          </div>
        )}
        {smallDevices ? <p className="carttopDown"><br /><br /></p> : ""}
      </div>
    </>
  );
};

export default Cart;
