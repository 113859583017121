import Close from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import Dimmer from "../utils/dimmer";
import EditProfileContent from "./recover_password_content";
import { useMediaQuery } from "react-responsive";
import { loginSlice } from "../../slices/login";
import { useTranslation } from 'react-i18next';
const RecoverPasswordModal = () => {
  const xs = useMediaQuery({ query: "(max-width: 576px" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const xl = useMediaQuery({ query: "(min-width: 1200px)" });
  let width = "inherit";
  if (xl) width = "40%";
  else if (lg) width = "50%";
  else if (md) width = "60%";
  else if (sm) width = "70%";
  else if (xs) width = "90%";
  const style = {
    width: width,
    maxHeight: "90%",
  };
  const mobilestyle = {
    width: width,
    maxHeight: "90%",
    marginTop:"150px"
  };
  const recoverPasswowrdActions = loginSlice.actions;
  const dispatch = useDispatch();
  const closeModal = () => {
      dispatch(recoverPasswowrdActions.setIsOpen(false))
    document.body.style.overflow = "visible";
  };
  const { t } = useTranslation();
  const md3 = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <>
   {md3 ?<><Dimmer />
      <div className="recover-password-modal" style={mobilestyle}>
        <div className="header">
       <span className="modalText">{t('recoverpaswword.forgotyourpassword')}</span>
          <div className="close-modal" onClick={closeModal}>
            <Close />
          </div>
        </div>
        <EditProfileContent />
      </div></>:<><Dimmer />
      <div className="recover-password-modal" style={style}>
        <div className="header">
       <span className="modalText">{t('recoverpaswword.forgotyourpassword')}</span>
          <div className="close-modal" onClick={closeModal}>
            <Close />
          </div>
        </div>
        <EditProfileContent />
      </div></>}
    </>
  );
};

export default RecoverPasswordModal;
