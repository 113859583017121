import { createSlice } from "@reduxjs/toolkit";
export const recipeSlice = createSlice({
    name:'recipe',
    initialState: {
        activeColumn:0,
        recipes:[],
        areRecipesLoading:false,
        error:"",
        allRecipes:[]
    },
    reducers: {
        setActiveColumn:(state,action) => {
            state.activeColumn = action.payload;
        },
        setRecipes:(state,action) => {
            state.recipes = action.payload;
        },
        setAllRecipes:(state,action) => {
            state.allRecipes = action.payload;
        },
        setAreRecipesLoading:(state,action) => {
            state.areRecipesLoading = action.payload;
        },
        setError:(state,action) => {
            state.error = action.payload;
        },
    }
});