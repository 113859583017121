import badge from "../../new-assets/badge.png";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../utils/url";
import { productsSlice } from "../../slices/products";
import { cartSlice } from "../../slices/cart";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { productsApi } from "../serviceApi/productsApi";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import '../scss-styles/featuredProductStyle.scss'
import { useMediaQuery } from "react-responsive";
const shoppingCartIcon = <FontAwesomeIcon icon={faShoppingCart} />;
const Featured = () => {
  const dispatch = useDispatch();
  const [loadingimg, setloadingimg] = useState(true);
  const productsActions = productsSlice.actions;
  const cartActions = cartSlice.actions;
  const rightDrawerActions = rightDrawerSlice.actions;
  const history = useHistory();
  const featuredproductArray = [];
  useEffect(() => {
    dispatch(productsActions.setIsLoadingPopular(true));
    productsApi.getmethod("getfeaturedProducts").then(
      (response) => {
        dispatch(productsActions.setIsLoadingPopular(false));
        if (response.data.products)
          dispatch(productsActions.setFeaturedProduct(response.data.products));
      },
      (error) => {
        dispatch(productsActions.setError(error));
      }
    );
  }, []);
  const featured = useSelector((state) => state.products.featuredProduct);
  useEffect(() => {
    setloadingimg(true)
    featured.forEach((product) => {
      userServiceApi.getParamMethod("getProductImages", product.id).then(
        (response) => {
          setloadingimg(false)
          if (response.data.productImages) {
            featuredproductArray.push({
              Name: product.Name,
              Description: product.Description,
              enName: product.enName,
              enDescription: product.enDescription,
              product: product,
              product_image: response.data.productImages[0].product_image
            })
            dispatch(productsActions.setfeaturedproductArray(featuredproductArray[0]));
          }
        },
        (error) => {
          dispatch(productsActions.setError(error));
        }
      );
    });

  }, [featured]);
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  const { t } = useTranslation();
  const currentlang = localStorage.getItem("lang");
  var featuredProducts = useSelector((state) => state.products.featuredproductArray);
  if (currentlang == "en") {
    featuredProducts = JSON.parse(JSON.stringify(featuredProducts).split('"enName":').join('"Name":'));
    featuredProducts = JSON.parse(JSON.stringify(featuredProducts).split('"enDescription":').join('"Description":'));
  }
  const md1 = useMediaQuery({ query: "(max-width: 1245px)" });
  const md2 = useMediaQuery({ query: "(min-width: 1246px)" });
  const md3 = useMediaQuery({ query: "(max-width: 1290px)" });
  const middlDevice=md2 && md3;
  const mdbadge1 = useMediaQuery({ query: "(min-width: 600px)" });
  const mdbadge2 = useMediaQuery({ query: "(max-width: 900px)" });
  const mdbadge=mdbadge1 && mdbadge2;
  const minXL = useMediaQuery({ query: "(min-width: 900px)" });
  const maxXL = useMediaQuery({ query: "(max-width: 1281px)" });
  const xl=minXL && maxXL;
  return (
    <div className="featuredProductStyle">
      {mdbadge ?<div className="badge">
        <img src={badge} alt="badge" className="badge-imgsmall" />
       </div>:<div className="badge">
        <img src={badge} alt="badge" className="badge-img" />
       </div>}
       {middlDevice ? <><div className="middlDevicefeaturedproduct">
        {!featuredProducts ? <div className="newprodutloading">{<CircularProgress />}</div> :
          <img src={`${imageurl}images/${featuredProducts.product_image}`} alt="" className="product-img" />}
      </div></>:<>{md1 ?<div className="mobilefeaturedproduct">
        {!featuredProducts ? <div className="newprodutloading">{<CircularProgress />}</div> :
          <img src={`${imageurl}images/${featuredProducts.product_image}`} alt="" className="product-img" />}
      </div>:<div className="featuredproduct">
        {!featuredProducts ? <div className="newprodutloading">{<CircularProgress />}</div> :
          <img src={`${imageurl}images/${featuredProducts.product_image}`} alt="" className="product-img" />}
      </div>}</>}
      <div className="desc">
        <h3>{featuredProducts.Name}</h3>
        <p  dangerouslySetInnerHTML={{ __html: featuredProducts.Description}}>
        </p>
        {mdbadge ?<div
          className="middledeviceButton"
          onClick={() => {
              dispatch(
                cartActions.addCartItem({ product: featuredProducts.product, quantity: 1 })
              );
              toast("Product added to cart", {
                position: "top-right",
                autoClose: 1700,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          }}>
            <button className="middleButton"> {shoppingCartIcon}
              {t('recipes.featured')}
            </button>
        </div>:<>{xl ?<div
          className="largedeviceButton"
          onClick={() => {
              dispatch(
                cartActions.addCartItem({ product: featuredProducts.product, quantity: 1 })
              );
              toast("Product added to cart", {
                position: "top-right",
                autoClose: 1700,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          }}>
          <div className="middleButton">
            <button> {shoppingCartIcon}
              {t('recipes.featured')}
            </button>
          </div>
        </div>:<div
          className="cart-btn btn btn-block"
          onClick={() => {
              dispatch(
                cartActions.addCartItem({ product: featuredProducts.product, quantity: 1 })
              );
              toast("Product added to cart", {
                position: "top-right",
                autoClose: 1700,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          }}>
          <div className="product-btn-content">
            <button> {shoppingCartIcon}
              {t('recipes.featured')}
            </button>
          </div>
        </div>}</>}
      </div>
    </div>
  );
};

export default Featured;
