import Title from "./title"
import TermsContent from './termsContent'
const Terms= (props) => {
     const location = props.location.pathname
     const route = location.substring(location.lastIndexOf('/') + 1)
    return <div className="terms">
       <Title title={route}/>
       <TermsContent terms={route}/>
    </div>
}
export default Terms;