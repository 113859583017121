import { createSlice } from "@reduxjs/toolkit";
export const contactSlice = createSlice({
    name: "contact",
    initialState: {
      inputValues: {
        fullName: "",
        email: "",
        phone: "+49 ",
        message: "",
      },
  
      inputErrors: {
        fullNameErr: "",
        emailErr: "",
        phoneErr: "",
      },
      isLoading: false,
      contactregistrationSuccessful:false,
      newsubscriptionSuccessful:false,
      error:""
    },
    reducers: {
      setFullName: (state, action) => {
        state.inputValues.fullName = action.payload;
      },
      setError:(state,action) => {
        state.error = action.payload;
    },
      setEmail: (state, action) => {
        state.inputValues.email = action.payload;
      },
      setPhone: (state, action) => {
        state.inputValues.phone = action.payload;
      },
      setMessage: (state, action) => {
        state.inputValues.message = action.payload;
      },
      setFullNameErr: (state, action) => {
        state.inputErrors.fullNameErr = action.payload;
      },
      setEmailErr: (state, action) => {
        state.inputErrors.emailErr = action.payload;
      },
      setPhoneErr: (state, action) => {
        state.inputErrors.phoneErr = action.payload;
      },
      setIsLoading: (state, action) => {
        state.isLoading = action.payload;
      },
      setRegistrationSuccessful: (state, action) => {
          state.contactregistrationSuccessful = action.payload;
        },
        setSubscriptionSuccessful: (state, action) => {
          state.newsubscriptionSuccessful = action.payload;
        },
    },
});