import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifySlice } from "../../slices/verfiy";
import { url } from "../../utils/url";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { useHistory } from "react-router";
import { useTranslation} from 'react-i18next';
import { registerSlice } from "../../slices/register";
const Verify = (props) => {
  const location = props.location.pathname;
  const route = location.substring(location.lastIndexOf("/") + 1);
  const isLoading = useSelector((state) => state.verify.isLoading);
  const text = useSelector((state) => state.verify.text);
  const verifyActions = verifySlice.actions;
  const rightDrawerActions = rightDrawerSlice.actions;
  const registrationActions = registerSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(verifyActions.setIsLoading(true));
    axios.get(`${url}user/verify/${route}`).then(
      (response) => {
        if (response.data.verify) {
          dispatch(verifyActions.setIsLoading(false));
          dispatch(verifyActions.setText("Account verified successfully ! "));
          dispatch(registrationActions.setVerificationSuccessful(true));
          history.push("/");
        return;
        }
        else{history.push("/");
        return;
      }
      },
      (error) => { dispatch(verifyActions.setError(error));}
    );
  }, []);
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  const gologin = () => {showRightDrawer("login");}
  const { t } = useTranslation();
  return (
    <div className="verify">
      {/* <div className="text"> {text}</div>
      {text === "Account verified successfully ! " && (
        <Link to={"/"}>
          {" "}
          <div className=" adimera-btn " onClick={gologin}>
            {t('accountsucess.m1')}
          </div>{" "}
        </Link>
      )} */}
    </div>
  );
};

export default Verify;
