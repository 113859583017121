import React from "react";
import cup from "../../new-assets/cup-and-stick.png";
import { useHistory } from "react-router";
import { useTranslation} from 'react-i18next';
import '../scss-styles/homecarousel.scss'
import {useSelector } from "react-redux";
const HomeCarousel = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const languages=localStorage.getItem("lang");
  return (
      <div className="homecarousel">
      <div className="white-transparent">
        <img src={cup} alt="cup" />
        <span className="titles">{t('homecarousel.title')}</span>
        <p>
        {t('homecarousel.subtitle')}
        </p>
        <button onClick={() => {
          if(languages==="de"){
            history.push("/Gewürze");
          }
          else{history.push("/shop");}
        }}>{t('homecarousel.spices')}
          </button>
      </div>
    </div>
  );
};

export default HomeCarousel;
