import Dimmer from "../utils/dimmer";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { paymentSlice } from "../../slices/paymentCompleted";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation} from 'react-i18next';
import { userServiceApi } from "../serviceApi/userServiceApi";
import { useEffect } from "react";
const PaymentSuccess = () => {
  const xs = useMediaQuery({ query: "(max-width: 576px" });
  const msm = useMediaQuery({ query: "(max-width: 450px" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const xl = useMediaQuery({ query: "(min-width: 1200px)" });
  let width = "inherit";
  if (xl) width = "35%";
  else if (lg) width = "40%";
  else if (md) width = "50%";
  else if (sm) width = "60%";
  else if (msm) width = "90%";
  else if (xs) width = "70%";
  const style = {
    width: width,
    height: "85%",
  };
  const successIcon = <FontAwesomeIcon icon={faCheckCircle} size="5x" />;
  const paymentActions = paymentSlice.actions;
  const dispatch = useDispatch();
  useEffect(() => {
    const currentlang = localStorage.getItem("lang");
    const companyemail="info@adey-spices.de";
    if(localStorage.getItem("getUserEmail")?.length>0){
      var emaildata = {
        from: "dev@adeymailserver.de",
        to: localStorage.getItem("getUserEmail"),
        cc: "yemie008@gmail.com",
        bcc: "adeymeselesh@gamil.com",
        subject: "Vielen Dank für Ihren Kauf ",
        text: "<p><b>" +"Guten Tag  "+ localStorage.getItem("getFullName") + "</b>,</p>" + "<br>Vielen Dank für Ihre Bestellung bei Adey Meselesh Spices."+"<br/> Hiermit bestätigen wir Ihre Bestellung und haben nachfolgend dieser zur Kontrolle noch einmal aufgelistet: "+"<br/>"+"Produkte: " + localStorage.getItem("ProductsList")+"<br/>" +"Bestellnummer: "+localStorage.getItem("getUserOrderID")+"<br/>Gesamtpreis:"+localStorage.getItem("geTotalPayment")+"<br>Ihre Bestellung wird nun so schnell wie möglich bearbeitet und an Sie versendet. Bei Fragen sind wir jederzeit für Sie da:"+"<br> Schreiben Sie einfach an info@adey-spices.de<br><br>" +" Vielen Dank und herzliche Grüße,<br> Ihr Adey Meslesh Spices - Team."
      }
      var  toCompanyMailerdata = {
        from: "dev@adeymailserver.de",
        to: companyemail,
        cc: companyemail,
        bcc: "adeymeselesh@gamil.com",
        subject: "Erfolgreiche Zahlung und Produktbestellung durch"+localStorage.getItem("getFullName"),
        text: "<p><b> Sehr geehrter Herr / Frau"+"</b>,</p>" + "<br>Ich schreibe Ihnen, um Ihnen das mitzuteilen "+localStorage.getItem("getFullName")+" ihre Zahlung für den Kauf von erfolgreich abgeschlossen hat" + localStorage.getItem("ProductsList") + "auf Ihrer E-Commerce-Plattform.<br>Wir freuen uns, Ihnen mitteilen zu können, dass die Transaktion erfolgreich war und das Geld Ihrem Konto gutgeschrieben wurde!.</br><br/>Auf Wunsch des Kunden bitten wir Sie, die Anlieferung zu arrangieren <br/>des gekauften Produkts so schnell wie möglich an ihre bevorzugte Adresse.<br><br>Nachfolgend finden Sie die Details der Produktbestellung:<br>Kundenname:"+localStorage.getItem("getFullName")+"<br>Bestellnummer:"+localStorage.getItem("getUserOrderID")+"<br>Zahlungsbetrag:"+localStorage.getItem("geTotalPayment")+"<br><br>Mit freundlichen Grüßen,<br> Dein Adey Spices team",
      }
      if (currentlang == "en") {
        emaildata = {
          from: "dev@adeymailserver.de",
          to: localStorage.getItem("getUserEmail"),
          cc: "yemie008@gmail.com",
          bcc: "adeymeselesh@gamil.com",
          subject: "Thank you for your purchase",
          text: "<p><b>" +"Hello "+ localStorage.getItem("getFullName") + "</b>,</p>" + "<br>Thank you for placing your order with Adey Meselesh Spices."+"<br/> We hereby confirm your order and have listed it below for your reference: "+"<br/>"+"Products: " + localStorage.getItem("ProductsList") +"<br/>"+"Order number: "+localStorage.getItem("getUserOrderID")+"<br/>Total price:"+localStorage.getItem("geTotalPayment")+"<br>Your order will now be processed and shipped to you as soon as possible. If you have any questions, please do not hesitate to contact us:"+"<br> Just write to info@adey-spices.de<br><br>" +" Thank you very much and best regards,<br> Your Adey Meslesh Spices Team."
        }
       toCompanyMailerdata = {
          from: "dev@adeymailserver.de",
          to: companyemail,
          cc: companyemail,
          bcc: "adeymeselesh@gamil.com",
          subject: "Successful Payment and Product Order by"+localStorage.getItem("getFullName"),
          text: "<p><b> Dear Sir/Madam"+"</b>,</p>" + "<br>I am writing to inform you that "+localStorage.getItem("getFullName")+" has successfully completed their payment for the purchase of" + localStorage.getItem("ProductsList") + "on your ecommerce platform .<br> We are pleased to inform you that the transaction was successful, and the funds have been deposited into your account!.</br><br/>As per the customer's request, we kindly request you to make arrangements for the delivery <br/>of the purchased product to their preferred address as soon as possible.<br><br>Please find below the details of the product order:<br>Customer Name:"+localStorage.getItem("getFullName")+"<br>Order Number:"+localStorage.getItem("getUserOrderID")+"<br>Payment Amount:"+localStorage.getItem("geTotalPayment")+"<br><br>Best regards,<br> Your Adey Spices team",
        }
      }
      userServiceApi.sendEmailUser(emaildata).then(
        (response) => {
          if(response){
            localStorage.removeItem("getFullName");
            localStorage.removeItem("getUserEmail");
            localStorage.removeItem("ProductsList");
            localStorage.removeItem("getUserOrderID");
            localStorage.removeItem("geTotalPayment");
          }
        },
        (error) => {
          return error;
          // dispatch(registerActions.setError(error));
        });
        userServiceApi.sendEmailToCompany(toCompanyMailerdata).then(
          (response) => {
            if(response){
              localStorage.removeItem("getFullName");
              localStorage.removeItem("getUserEmail");
              localStorage.removeItem("ProductsList");
              localStorage.removeItem("getUserOrderID");
              localStorage.removeItem("geTotalPayment");
            }
          },
          (error) => {
            return error;
            // dispatch(registerActions.setError(error));
          });
    }
  }, []);
  const closeModal = () => {
    dispatch(paymentActions.setPaymentSuccessful(false));
    localStorage.removeItem("getFullName");
    localStorage.removeItem("getUserEmail");
    localStorage.removeItem("ProductsList");
    localStorage.removeItem("getUserOrderID");
    localStorage.removeItem("geTotalPayment");
  };
  const { t } = useTranslation();  
  return (
    <>
      <Dimmer />
      <div className="payment-success" style={style}>
        <div className="top">
          <div className="icon">{successIcon}</div>
          {/* <div className="text">{t('paymentsuccess.succes')}</div> */}
        </div>
        <div className="bottom">
          <div className="text">
            {/* <h4>
              {" "}
              {t('paymentsuccess.message1')}
            </h4> */}
            <p>
            {t('paymentsuccess.message1')}
              {" "}
              {t('paymentsuccess.message2')}
            </p>
            <p>
              {" "}
              {t('paymentsuccess.message3')}
            </p>
          </div>
          <div className="continue">
            <div className="btn" onClick={closeModal}>
            <Link to={"/"}>
          {" "}
          <div className=" adimera-btn " >
            {t('paymentsuccess.Continue')}
          </div>{" "}
        </Link>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default PaymentSuccess;
