import { createSlice } from "@reduxjs/toolkit";
export const orderSlice = createSlice({
  name: "order",
  initialState: {
    products: [],
    cartItems: [],
    savingCart: false,
    test:1,
    productImages:[],
     successResponse:[],
    orderLoading:false,
    error:"",
    orderFailure:false,
    countryName:"",
    countryCode:""
  },
  reducers: {
    setOrdeFailure:(state,action) => {
      state.orderFailure = action.payload;
  },
  setCountryCode:(state, action) => {
    state.countryCode = action.payload;
  },
  setCountryName:(state, action) => {
    state.countryName = action.payload;
  },
    addProduct: (state, action) => {
      state.products.push(action.payload);
    },
    setError:(state,action) => {
      state.error = action.payload;
  },
    setSavingCart: (state, action) => {
      state.savingCart = action.payload;
    },
    setTest: (state, action) => {
      state.savingCart = action.payload;
    },
    addProductImage: (state, action) => {
      state.productImages.push(action.payload)  ;
    },
    setOrderItem: (state, action) => {
        state.cartItems = action.payload;
    },
    setSuccessResponse: (state, action) => {
      state.successResponse = action.payload;
  },
    setOrdersLoading:(state,action) => {
      state.orderLoading = action.payload;
  },
    removeCartItem: (state, action) => {
      const newCartItems = state.cartItems.filter(
        (cartItem) => cartItem.product.id !== action.payload
      );
      state.cartItems = newCartItems;
    },
    increaseQuantity: (state, action) => {
      state.cartItems.forEach((cartItem) => {
        if (cartItem.product.id === action.payload) {
          cartItem.quantity += 1;
        }
      });
    },
    decreaseQuantity: (state, action) => {
      state.cartItems.forEach((cartItem) => {
        if (cartItem.product.id === action.payload) {
          if (cartItem.quantity > 1) {
            cartItem.quantity -= 1;
          }
        }
      });
    },
  },
});
