import React, { useState } from "react";
import AllRecipieCard from "../recipie-card/allRecipesCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageurl } from "../../utils/url";
import { recipeSlice } from "../../slices/recipe";
import { CircularProgress } from "@material-ui/core";
import { infoapi } from "../serviceApi/infoapi"
import { Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import '../scss-styles/recipesComponent.scss'
const AllRecipes = () => {
  const recipeActions = recipeSlice.actions;
  const dispatch = useDispatch();
  const [loading, setloading] = useState(0);
  const [PageNum, setPageNum] = useState(0);
  const [modulos,setRecipesModulo]=useState(0)
  const [totalRecipes, settotalRecipes] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  useEffect(() => {
    dispatch(recipeActions.setAreRecipesLoading(false));
    infoapi.getAllRecipePagination(0).then(
      (response) => {
        if (response.data.recipe) {
          dispatch(recipeActions.setAreRecipesLoading(true));
          dispatch(recipeActions.setAllRecipes(response.data.recipe));
          settotalRecipes(response.data.recipe.length)
          setloading(1);
          if(response.data.totalRecipes.totalRecipes-response.data.recipe.length<1){setRecipesModulo(0)}
          else{setRecipesModulo(response.data.totalRecipes.totalRecipes-response.data.recipe.length);}
        }
      },
      (error) => {
        dispatch(recipeActions.setError(error));
      }
    );
  }, []);
  const pagination = (page) => {
    infoapi.getAllRecipePagination(page).then(
      (response) => {
        if (response.data.recipe) {
          dispatch(recipeActions.setAreRecipesLoading(true));
          dispatch(recipeActions.setAllRecipes(response.data.recipe));
          setloading(1);
          if(modulos-response.data.recipe.length<1){setRecipesModulo(0)}
          else{setRecipesModulo(modulos-response.data.recipe.length);}
          settotalRecipes(response.data.recipe.length)
        }
      },
      (error) => {
        dispatch(recipeActions.setError(error));
      }
    );
  };
  var allRecipes = useSelector((state) => state.recipe.allRecipes);
  var error = useSelector((state) => state.recipe.error);
  const currentlang = localStorage.getItem("lang");
  if (currentlang == "en") {
    allRecipes = JSON.parse(JSON.stringify(allRecipes).split('"enName":').join('"Name":'));
    allRecipes = JSON.parse(JSON.stringify(allRecipes).split('"enDescription":').join('"Description":'));
  }
  const areRecipesLoading = useSelector((state) => state.recipe.areRecipesLoading);
  const { t } = useTranslation();
  const md1 = useMediaQuery({ query: "(max-width: 1368px)" });
  const md2 = useMediaQuery({ query: "(min-width: 650px)" });
  const md = md1 && md2
  const md3 = useMediaQuery({ query: "(max-width: 600px)" });
  const middl1 = useMediaQuery({ query: "(min-width: 601px)" });
  const middl2 = useMediaQuery({ query: "(max-width: 920px)" });
  const middle = middl1 && middl2
  return (
    <div className="allRecipes">
      {middle ?<><div className="middleTablet">
        <h4>
          {t('recipes.allrecipes')}</h4>
        <div className="recipes-list">
          {loading === 0 && allRecipes.length === 0 ? <b translate="no">{<CircularProgress />}</b>
            : allRecipes.map((recipes) => {
              return (
                <Col key={recipes.id} >
                  <AllRecipieCard key={recipes.id}
                    image={`${imageurl}images/${recipes.Image}`}
                    name={recipes.Name}
                    description={recipes.Description}
                    details={recipes}
                  />
                </Col>);
            })}
        </div>
        <br />
        {!md3 ?
          <div className="padg">
            <a onClick={
              () => {
                if (PageNum < 0) { }
                else {
                  setPageNum(PageNum - 1);
                  pagination(PageNum - 1)
                }
              }
            } className="previousRecipe">&laquo; {t('recipes.Previous')}</a>
            {modulos===0 ?<a className="nextRecipeInactive">{t('recipes.Next')} &raquo;</a>:<a onClick={
              () => {
                  setPageNum(PageNum + 1);
                  pagination(PageNum + 1);
              }
            } className="nextRecipe">{t('recipes.Next')} &raquo;</a>}
          </div> :
          <div className="padg">
            <a onClick={() => {
              setPageNum(PageNum - 1);
              pagination(PageNum - 1)
              setRecipesModulo(modulos + totalRecipes);
            }} className="previousRecipe round">&#8249;</a>
            {modulos===0?<a className="nextRecipeInactive round">&#8250;</a>:<a onClick={() => {
              setPageNum(PageNum + 1);
              pagination(PageNum + 1)
            }} className="nextRecipe round">&#8250;</a>}
          </div>
        }
      </div> </>:<>{md ? <div className="ipadview">
        <h4>
          {t('recipes.allrecipes')}</h4>
        <div className="recipes-list">
          {loading === 0 && allRecipes.length === 0 ? <b translate="no">{<CircularProgress />}</b>
            : allRecipes.map((recipes) => {
              return (
                <Col key={recipes.id} >
                  <AllRecipieCard key={recipes.id}
                    image={`${imageurl}images/${recipes.Image}`}
                    name={recipes.Name}
                    description={recipes.Description}
                    details={recipes}
                  />
                </Col>);
            })}
        </div>
        <br />
        {!md3 ?
          <div className="padg">
            <a onClick={
              () => {
                if (PageNum < 0) { }
                else {
                  setPageNum(PageNum - 1);
                  pagination(PageNum - 1);
                  setTotalCount(2);
                  setRecipesModulo(modulos +modulos+ totalRecipes);
                }
              }
            } className="previousRecipe">&laquo; {t('recipes.Previous')}</a>
            {modulos===0 ?<a className="nextRecipeInactive">{t('recipes.Next')} &raquo;</a>:<a onClick={
              () => {
                  setPageNum(PageNum + 1);
                  pagination(PageNum + 1);
              }
            } className="nextRecipe">{t('recipes.Next')} &raquo;</a>}
          </div> :
          <div className="padg">
            <a onClick={() => {
              setPageNum(PageNum - 1);
              pagination(PageNum - 1)
              setRecipesModulo(modulos + totalRecipes);
            }} className="previousRecipe round">&#8249;</a>
            {modulos===0?<a className="nextRecipeInactive round">&#8250;</a>:<a onClick={() => {
              setPageNum(PageNum + 1);
              pagination(PageNum + 1)
            }} className="nextRecipe round">&#8250;</a>}
          </div>
        }
      </div> : <>
        <h4>
          {t('recipes.allrecipes')}</h4>
        <div className="recipes-list">
          {loading === 0 && allRecipes.length === 0 ? <b translate="no">{<CircularProgress />}</b>
            : allRecipes.map((recipes) => {
              return (
                <Col key={recipes.id} >
                  <AllRecipieCard key={recipes.id}
                    image={`${imageurl}images/${recipes.Image}`}
                    name={recipes.Name}
                    description={recipes.Description}
                    details={recipes}
                  />
                </Col>);
            })}
        </div>
        <br />
        {!md3 ?
          <div className="padg">
            <a onClick={
              () => {
                if (PageNum < 0) { }
                else {
                  setPageNum(PageNum - 1);
                  pagination(PageNum - 1)
                  setRecipesModulo(modulos + totalRecipes);
                }
              }
            } className="previousRecipe">&laquo; {t('recipes.Previous')}</a>
           {modulos===0 ?<a className="nextRecipeInactive">{t('recipes.Next')} &raquo;</a>:<a onClick={
              () => {
                  setPageNum(PageNum + 1);
                  pagination(PageNum + 1);
              }
            } className="nextRecipe">{t('recipes.Next')} &raquo;</a>}
          </div> :
          <div className="padg">
            <a onClick={() => {
              setPageNum(PageNum - 1);
              pagination(PageNum - 1)
            }} className="previousRecipe round">&#8249;</a>
            {modulos===0?<a className="nextRecipeInactive round">&#8250;</a>:<a onClick={() => {
              setPageNum(PageNum + 1);
              pagination(PageNum + 1)
            }} className="nextRecipe round">&#8250;</a>}
          </div>
        }
      </>}</>}
    </div>
  );
};

export default AllRecipes;

