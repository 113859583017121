import Dimmer from "./dimmer";
import Header from "./header";
import DrawerContent from "./drawer_content";
const RightDrawer = () => {
  return (
    <>
    <Dimmer />
      <div className="right-drawer">
        <Header/>
        <DrawerContent/>
      </div>      
    </>
  );
};
export default RightDrawer;
