import axios from "axios";
import { url,smtpurl} from "../../utils/url";
import {config} from "../../utils/header";
import { configMode } from "./configMode";
const userServiceApi={
   getmethod:(route) => axios.get(`${url}user/${route}`),
   postmethod:(route)=>axios.post(`${url}user/${route}`),
   postParamMethod:(route,id) => axios.post(`${url}user/${route}/${id}`),
   getParamMethod:(route,id) => axios.get(`${url}user/${route}/${id}`),
   postCart:(data)=>axios.post(`${url}user/createCart`,data),
   registerUser:(data)=>axios.post(`${url}user/registerUsers`,data),
   sendEmailUser:(data)=>axios.post(smtpurl,data,configMode),
   sendEmailToCompany:(data)=>axios.post(smtpurl,data,configMode),
   saveCart:(data)=>axios.post(`${url}user/saveToCart`,data),
   editProfile:(user_id,data)=>axios.post(`${url}user/editProfile/${user_id}`,data),
   recoverPassword:(data)=>axios.post(`${url}admin/recoverPassword`,data),
   sentEmail:(data)=>axios.post(`${url}admin/sentEmail`,data),
   changePassword:(data)=>axios.post(`${url}admin/changePassword`,data,config),
   getAllHistory:(userId)=>axios.get(`${url}user/getAllHistory/${userId}`,config),
   getAllProfile:(userId)=>axios.get(`${url}profile/getAllProfile/${userId}`),
   deleteOrders: (id) => axios.get(`${url}admin/deleteOrders/${id}`, config),
}
export {userServiceApi};