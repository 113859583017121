import { Col, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
const CartListHeader = () => {
  const { t } = useTranslation();
   return <div className="cart-list-header">
        <Row>
            <Col lg={4}>
            {t('cart.Product')}
            </Col>
            <Col lg={2}>
            {t('cart.Weight')}
            </Col>
            <Col lg={2}>
            {t('cart.Price')}
            </Col>
            <Col lg={2}>
            {t('cart.Quantity')}
            </Col>
            {/* <Col lg={2} style={{textAlign:'right'}}> */}
            <Col lg={2} style={{paddingLeft:"150px"}}>
            {t('cart.Total')}
            </Col>
        </Row> 
    </div>
}
export default CartListHeader;