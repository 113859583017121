import { useDispatch, useSelector } from "react-redux";
import { contactSlice } from "../../slices/contact";
import { infoapi } from "../serviceApi/infoapi"
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
import '../scss-styles/contactusStyle.scss'
const Contacts = () => {
  const contactActions = contactSlice.actions;
  const {
    fullName,
    email,
    phone,
    message,
  } = useSelector((state) => state.contact.inputValues);
  const {
    fullNameErr,
    emailErr,
    phoneErr,
  } = useSelector((state) => state.contact.inputErrors);
  const isLoading = useSelector((state) => state.contact.isLoading);
  const dispatch = useDispatch();
  const validate = (e) => {
    e.preventDefault();
    dispatch(contactActions.setFullNameErr(""));
    dispatch(contactActions.setEmailErr(""));
    dispatch(contactActions.setPhoneErr(""));
    let isValid = true;
    if (fullName.length < 2) {
      dispatch(
        contactActions.setFullNameErr(
          "Full Name must be atleast 2 characters!"
        )
      );
      isValid = false;
    }
    if (phone.replaceAll(' ', '').length !== 13) {
      dispatch(
        contactActions.setPhoneErr(
          "Invalid phone number"
        )
      );
      isValid = false;
    }
    if (isValid) {
      sendRequest();
    }
  };
  const sendRequest = () => {
    dispatch(contactActions.setIsLoading(true));
    dispatch(contactActions.setRegistrationSuccessful(false));
    const contactData = { fullName: fullName, phone: phone.replaceAll(' ', ''), email: email, message: message }
    infoapi.createContact(contactData).then((response) => {
      dispatch(contactActions.setIsLoading(false));
      if (response.data) {
        dispatch(contactActions.setRegistrationSuccessful(true));
        document.body.style.overflow = "visible";
      } else if (response.data.errs) {
        if (response.data.errs === "email duplicated credentials!.") {
          dispatch(contactActions.setEmailErr("Email already exists"));
        }
      }
    },
      (error) => {
        dispatch(contactActions.setError(error));
        dispatch(contactActions.setIsLoading(false));
      }
    );
  };
  const md = useMediaQuery({ query: "(max-width: 600px)" });
  const { t } = useTranslation();
  // window.scrollTo(0, 0)
  return (
    <div className="contactDetails">
      {md ?
        <div className="contactmobile">
          <h1><b>{t('contactus.subtitle')}</b></h1>
          <p>{t('contactus.text')}</p>
          <form onSubmit={validate}>
            <div className="form-grp">
              <label htmlFor="fullName">{t('contactus.FullName')}*</label>
              <input
                className="input"
                type="text"
                id="fullName"
                required
                value={fullName}
                onChange={(e) => {
                  dispatch(contactActions.setFullName(e.target.value));
                }}
              />
              <div className="form-error"></div>
            </div>
            <div className="form-grp">
              <label htmlFor="phone">{t('contactus.Phone')}*</label>
              <input
                className="input"
                type="tel"
                id="phone"
                required
                value={phone}
                onChange={(e) => {
                  dispatch(contactActions.setPhone(e.target.value));
                }}
                placeholder="example:+49...."
              />
              <div className="form-error">{phoneErr}</div>
            </div>
            <div className="form-grp">
              <label htmlFor="email">{t('contactus.Email')}*</label>
              <input
                className="input"
                type="email"
                id="email"
                required
                value={email}
                onChange={(e) => {
                  dispatch(contactActions.setEmail(e.target.value));
                }}
              />
              <div className="form-error"></div>
            </div>
            <div className="form-grp">
              <label htmlFor="message">{t('contactus.Message')}*</label>
              <textarea
                className="textarea"
                type="text"
                id="message"
                required
                value={message}
                onChange={(e) => {
                  dispatch(contactActions.setMessage(e.target.value));
                }}
              />
              <div className="form-error"></div>
            </div>
            <div className="form-grp">
              {" "}
              <div className="loading"></div>
              <input type="submit" value={t("contactus.sendMessage")} className="btn btn-block" />
            </div>
          </form>
        </div> :
        <div className="contact">
          <h1><b>{t('contactus.subtitle')}</b></h1>
          <p>{t('contactus.text')}</p>
          <form onSubmit={validate}>
            <div className="form-grp">
              <label htmlFor="fullName">{t('contactus.FullName')}*</label>
              <input
                className="input"
                type="text"
                id="fullName"
                required
                value={fullName}
                onChange={(e) => {
                  dispatch(contactActions.setFullName(e.target.value));
                }}
              />
              <div className="form-error"></div>
            </div>
            <div className="form-grp">
              <label htmlFor="phone">{t('contactus.Phone')}*</label>
              <input
                className="input"
                type="tel"
                id="phone"
                required
                value={phone}
                onChange={(e) => {
                  dispatch(contactActions.setPhone(e.target.value));
                }}
                placeholder="example:+49...."
              />
              <div className="form-error">{phoneErr}</div>
            </div>
            <div className="form-grp">
              <label htmlFor="email">{t('contactus.Email')}*</label>
              <input
                className="input"
                type="email"
                id="email"
                required
                value={email}
                onChange={(e) => {
                  dispatch(contactActions.setEmail(e.target.value));
                }}
              />
              <div className="form-error"></div>
            </div>
            <div className="form-grp">
              <label htmlFor="message">{t('contactus.Message')}*</label>
              <textarea
                className="textarea"
                type="text"
                id="message"
                required
                value={message}
                onChange={(e) => {
                  dispatch(contactActions.setMessage(e.target.value));
                }}
              />
              <div className="form-error"></div>
            </div>
            <div className="form-grp">
              {" "}
              <div className="loading"></div>
              <input type="submit" value={t("contactus.sendMessage")} className="btn btn-block" />
            </div>
          </form>
        </div>}
    </div>
  );
}

export default Contacts;