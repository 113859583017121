import { createSlice } from "@reduxjs/toolkit";
export const aboutSlice = createSlice({
    name:'about',
    initialState: {
        activeColumn:0,
        aboutus:[],
        aboutusDocument:[],
        areAboutLoading:false,
        error:"",
    },
    reducers: {
        setActiveColumn:(state,action) => {
            state.activeColumn = action.payload;
        },
        setAbout:(state,action) => {
            state.aboutus = action.payload;
        },
        setAboutDocument:(state,action) => {
            state.aboutusDocument = action.payload;
        },
        setAreAboutLoading:(state,action) => {
            state.areAboutLoading = action.payload;
        },        
        setError:(state,action) => {
            state.error = action.payload;
        },
    }
});