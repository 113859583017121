import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentSlice } from "../../slices/paymentCompleted";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import { faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
const PaymentFailed = (props) => {
  const isLoading = useSelector((state) => state.payment.isLoading);
  const text = useSelector((state) => state.payment.text);
  const paymentActions = paymentSlice.actions;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const faExclamationTriangl = <FontAwesomeIcon icon={faExclamationTriangle} size="4x" />;
  useEffect(() => {
    dispatch(paymentActions.setIsLoading(true));
    dispatch(paymentActions.setPaymentFailedMessage(true));
          dispatch(paymentActions.setIsLoading(false));
          dispatch(paymentActions.setText("Payment Failed Try Again ! "));
          history.push("/");
          return;
  }, []);
  const contents={"op":"&&","back":"Back to home page"}
  const smallDevice = useMediaQuery({ query: "(max-width: 800px)" });
  return (
<>
{smallDevice?<div className="smalldevicepaymentFailed">
     <div className="ss" style={{color:"red"}}>{faExclamationTriangl}</div><br/>
      <div className="loading"translate="no">{isLoading && <CircularProgress />}</div>
      <div className="text">{t('paymentsuccess.paymentfailed')}</div><br/><b translate="no">
      {text === "Payment Failed Try Again ! " && (
        <Link to={"/"}>
          {" "}
          <div className="paymentfail-btn" >
          {t('paymentsuccess.backtohome')}
          </div>{" "}
        </Link>
      )}</b>
    </div>:<div className="paymentFailed">
    <div className="ss" style={{color:"red"}}>{faExclamationTriangl}</div>
    <br/>
      <div className="loading"translate="no">{isLoading && <CircularProgress />}</div>
      <div className="text">{t('paymentsuccess.paymentfailed')}</div><br/><b translate="no">
      {text === "Payment Failed Try Again ! " && (
        <Link to={"/"}>
          {" "}
          <div className="paymentfail-btn" >
          {t('paymentsuccess.backtohome')}
          </div>{" "}
        </Link>
      )}</b>
    </div>}
</>
  );
};

export default PaymentFailed;
