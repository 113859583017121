import { createSlice } from "@reduxjs/toolkit";
export const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    productImages: [],
    isLoading: false,
    Popularproducts: [],
    PopularproductImages: [],
    isLoadingPopular: false,
    Lastproducts: [],
    isLoadingLast: false,
    LastproductImages: [],
    ProductsByCategory: [],
    isLoadingProductCategory: false,
    sortMethod: null,
    forcompanies: [],
    areForcompanyLoading: false,
    isOpen: false,
    product: {},
    activeImageUrl: '',
    images: [],
    pageClicked: false,
    totalReview: 0,
    productPachaging: {},
    error:"",
    featuredproductArray:{},
    featuredProduct:[]
  },
  reducers: {
    setFeaturedProduct: (state, action) => {
      state.featuredProduct = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setfeaturedproductArray: (state, action) => {
      state.featuredproductArray = action.payload;
    },
    setError:(state,action) => {
      state.error = action.payload;
  },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    addProductImages: (state, action) => {
      state.productImages.push(action.payload);
    },
    setPopularProducts: (state, action) => {
      state.Popularproducts = action.payload;
    },
    setIsLoadingPopular: (state, action) => {
      state.isLoadingPopular = action.payload;
    },
    addPopularProductImages: (state, action) => {
      state.PopularproductImages.push(action.payload);
    },
    setLastProducts: (state, action) => {
      state.Lastproducts = action.payload;
    },
    setIsLoadingLast: (state, action) => {
      state.isLoadingLast = action.payload;
    },
    addLastProductImages: (state, action) => {
      state.LastproductImages.push(action.payload);
    },
    setProductsByCategory: (state, action) => {
      state.ProductsByCategory = action.payload;
    },
    setIsLoadingProductCategory: (state, action) => {
      state.isLoadingProductCategory = action.payload;
    },
    setSortMethod: (state, action) => {
      state.sortMethod = action.payload;
    },
    showModal: (state) => {
      state.isOpen = true;
    },
    hideModal: (state) => {
      state.isOpen = false;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setActiveImageUrl: (state, action) => {
      state.activeImageUrl = action.payload;
    },
    setImages: (state, action) => {
      state.images = action.payload;
    },
    togglePageClicked: (state) => {
      state.pageClicked = !state.pageClicked;
    },
    setTotalReview: (state, action) => {
      state.totalReview = action.payload;
    },
    setProductPachaging: (state, action) => {
      state.productPachaging = action.payload;
    },
    setForcompany: (state, action) => {
      state.forcompanies = action.payload;
    },
    setAreForcompanyLoading: (state, action) => {
      state.areForcompanyLoading = action.payload;
    },
  },
});
