import Close from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { useTranslation} from 'react-i18next';
const Header = () => {
  const actions = rightDrawerSlice.actions;
  const dispatch = useDispatch();
  const closeRightModal = () => {
    dispatch(actions.hideDrawer());
    document.body.style.overflow = "visible";
  };
  const { t } = useTranslation();
  const type = useSelector((state) => state.rightDrawer.type);
  let headerText = "";
  switch (type) {
    case "login":
      headerText= t('profile.Login')
      break;
      case "profile":
      headerText= t('profile.Profile')
      break;
    case "cart":
      headerText= t('profile.cart')
      break;
    case "register":
      headerText= t('profile.Register')
        break;
  }
  return (
    <div className="header">
      <div className="text">{headerText}</div>
      <div className="close-drawer" onClick={closeRightModal}>
        {" "}
        <Close />{" "}
      </div>
    </div>
  );
};
export default Header;
