import profileIcon from "../../icons/profile.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { useEffect } from "react";
import { url, imageurl } from "../../utils/url";
import { loginSlice } from "../../slices/login";
import Close from "@material-ui/icons/Close";
import { useTranslation, Trans } from 'react-i18next';
import { userServiceApi } from "../serviceApi/userServiceApi";
import { profileSlice } from "../../slices/profile";
import { faUser, faShoppingCart, faCaretDown, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Profile = () => {
  const closeRightModal = () => {
    dispatch(actions.hideDrawer());
    document.body.style.overflow = "visible";
  };
  const type = useSelector((state) => state.rightDrawer.type);
  const profileActions = profileSlice.actions;
  const signOutIcon = <FontAwesomeIcon icon={faSignOutAlt} size="lg" />;
  const { t } = useTranslation();
  let headerText = "";
  switch (type) {
    case "login":
      headerText = t('profile.Login')
      break;
    case "profile":
      headerText = t('profile.Profile')
      break;
    case "cart":
      headerText = t('profile.cart')
      break;
    case "register":
      headerText = t('profile.Register')
      break;
  }
  const dispatch = useDispatch();
  const actions = rightDrawerSlice.actions;
  const loginActions = loginSlice.actions;
  const rightDrawerActions = rightDrawerSlice.actions;
  const user = useSelector((state) => state.login.loggedUser);
  useEffect(() => {
    if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
    }
    else {
      // showEditProfileModal();
    }
  }, []);
  const logout = () => {
    userServiceApi.getParamMethod("logout", localStorage.getItem("user_id")).then(
      (response) => { },
      (error) => { dispatch(loginActions.setError(error)); }
    );
    dispatch(rightDrawerActions.setType('login'));
    // localStorage.clear()
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("lang");
    localStorage.removeItem("server");
    localStorage.removeItem("myArray");
    localStorage.removeItem("numberofProducts");
    dispatch(loginActions.setIsUserLogged(false));
    dispatch(loginActions.setLoggedUser([]));
    window.location.href = window.location.href;
  }
  const closeDrawer = () => {
    dispatch(actions.hideDrawer());
    document.body.style.overflow = "visible";
  };
  const showEditProfileModal = () => {
    dispatch(profileActions.setShowEditProfile(true));
    document.body.style.overflow = "hidden";
  };
  const languages=localStorage.getItem("lang");
  return (
    <div className="mini-profile">
      <div className="header">
        <div className="text">{headerText}</div>
        <div className="close-drawer" onClick={closeRightModal}>
          {" "}
          <Close />{" "}
        </div>
      </div>
      <div className="image">
        {" "}
        <img src={user.profilePicture ? `${imageurl}images/${user.profilePicture}` : profileIcon} alt="" />{" "}
      </div>
      <div className="name">{user.firstName} {user.lastName}</div>
      <span className="logoutButton" onClick={logout}>{signOutIcon}{t('profile.logout')}</span>
      <div className="buttons">
        {languages === "de" ? <><Link
          to="/Profil"
          onClick={() => {
            closeDrawer();
          }}
        >
          {" "}
          <div className="btn btn-block view-cart">{t('profile.goprofile')}</div>
        </Link>
          <Link
            to="/Bestellung"
            onClick={() => {
              closeDrawer();
            }}
          >
            {" "}
            <div className="btn btn-block save-cart">{t('profile.goOrders')}</div>
          </Link></>
          : <><Link
            to="/profile"
            onClick={() => {
              closeDrawer();
            }}
          >
            {" "}
            <div className="btn btn-block view-cart">{t('profile.goprofile')}</div>
          </Link>
            <Link
              to="/order"
              onClick={() => {
                closeDrawer();
              }}
            >
              {" "}
              <div className="btn btn-block save-cart">{t('profile.goOrders')}</div>
            </Link></>
        }
        {/* <div className="btn btn-block logout-button" onClick={logout}>{t('profile.logout')}{signOutIcon}</div> */}
      </div>
    </div>
  );
};

export default Profile;
