import Close from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import Dimmer from "../utils/dimmer";
import { profileSlice } from "../../slices/profile";
import EditProfileContent from "./edit_profile_content";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from 'react-i18next';
const EditProfileModal = () => {
  const xs = useMediaQuery({ query: "(max-width: 576px" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const xl = useMediaQuery({ query: "(min-width: 1200px)" });
  let width = "inherit";
  if (xl) width = "40%";
  else if (lg) width = "50%";
  else if (md) width = "60%";
  else if (sm) width = "70%";
  else if (xs) width = "90%";
  const style = {
    width: width,
    maxHeight: "90%",
    marginTop:"60px",
  };
  const stylemobile = {
    width: width,
    maxHeight: "90%",
    marginTop:"100px",
  };
  const profileActions = profileSlice.actions;
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(profileActions.setShowEditProfile(false));
    document.body.style.overflow = "visible";
  };
  const { t } = useTranslation();
   const md3 = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <>
  {md3 ?<><Dimmer />
      <div className="edit-profile-modal" style={stylemobile}>
        <div className="header">
        <span className="mobilemodalText">{t('profile.EditProfile')}</span>
          <div className="close-modal" onClick={closeModal}>
            <Close />
          </div>
        </div>
        <EditProfileContent />
      </div></>:<><Dimmer />
      <div className="edit-profile-modal" style={style}>
        <div className="header">
        <span className="modalText">{t('profile.EditProfile')}</span>
          <div className="close-modal" onClick={closeModal}>
            <Close />
          </div>
        </div>
        <EditProfileContent />
      </div></>}
    </>
  );
};
export default EditProfileModal;
