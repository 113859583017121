import Dimmer from "../utils/dimmer";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import {loginSlice} from "../../slices/login"
const FailureMessage = () => {
  const xs = useMediaQuery({ query: "(max-width: 576px" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const xl = useMediaQuery({ query: "(min-width: 1200px)" });
  let width = "inherit";
  if (xl) width = "25%";
  else if (lg) width = "30%";
  else if (md) width = "40%";
  else if (sm) width = "50%";
  else if (xs) width = "60%";
  const style = {
    width: width,
    height: "55%",
  };
  const failureIcon = <FontAwesomeIcon icon={faTimesCircle} size="4x" />;
  const loginActions = loginSlice.actions;
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(loginActions.setLoadError(false));
  };
const { t } = useTranslation();  
return (
    <>
      <Dimmer />
      <div className="error-message" style={style}>
        <div className="top">
          <div className="icon">{failureIcon}</div>
          <div className="text">{t('failureMessages.text')}</div>
        </div>
        <div className="bottom">
          <div className="text">
            <h4>
              {" "}
              {t('failureMessages.message1')}
            </h4>
            <p>
              {" "}
              {t('failureMessages.message2')}
            </p>
          </div>
          <div className="continue">
            <div className="btn" onClick={closeModal}>
            {t('failureMessages.close')}
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default FailureMessage;
