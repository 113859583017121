import { useTranslation } from 'react-i18next';
const PageNotFound = () => {
const { t } = useTranslation(); 
    return <>
    <div className="page-not-found">
        <div className="code">{t('pageNotfound.m1')}</div>
        <div className="text">{t('pageNotfound.m2')}</div>
    </div>
    </>
}
export default PageNotFound