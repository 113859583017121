import topImage from "../../new-assets/shop-top-image.png";
import { useTranslation} from 'react-i18next';
const Title = ({title}) => {
  const { t } = useTranslation();
  return (
    <div
      className="title"
      style={{
        background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${topImage})`,
      }}>
      <div className="main-title">{t('contactus.title')}</div>
      <div className="sub-title">
      </div>
    </div>
  );
};

export default Title;
