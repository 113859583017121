import { useDispatch, useSelector } from "react-redux";
import Home from "./components/home/home";
import RightDrawer from "./components/right_drawer/right_drawer";
import { useMediaQuery } from "react-responsive";
import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import NewNav from "./components/new-nav/nav.component";
import PageNotFound from "./components/utils/page_not_found";
import Footer from "./components/footer/footer";
import Profile from "./components/profile/profile";
import ChangePassword from "./components/profile/change_password";
import Cart from "./components/cart/cart";
import Order from "./components/order/order";
import RegistrationSuccess from "./components/success_message/registration_success";
import VerificationSuccess from "./components/success_message/verify_message";
import ContactRegistrationSuccess from "./components/success_message/contact_register_success";
import PaymentSuccess from "./components/success_message/payment_success";
import PaymentFailedMessage from "./components/success_message/payment_failedMessage";
import ErrorMessage from "./components/success_message/error_messages";
import ConcentMessage from "./components/success_message/concent_message"
import NewsSubscribeSuccess from "./components/success_message/news_subscribe_success";
import { useEffect,useState } from "react";
import { loginSlice } from "./slices/login";
import FetchData from "./components/utils/fetch_data";
import EditProfileModal from "./components/edit_profile/edit_profile_modal";
import RecoverPasswordModal from "./components/recover_password/recover_password_modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Verify from "./components/verify/verify";
import PasswordRecovered from "./components/success_message/password_recovered";
import SlipModal from "./components/slip/slip_modal";
import Auth from "./components/auth/auth";
import Payment from "./components/paymentCompleted/paymentCompleted";
import PaymentFailed from "./components/paymentCompleted/paymentFailed";
import ShopOverview from "./components/shop-overview/shop-overview.component";
import aboutus from "./components/about-us/about.component";
import recipes from "./components/recipies/recipies.component";
import spices from "./components/new-products/new-products";
import privacy from "./components/privacypolicy/privacy";
import Contact from "./components/contact/contact";
import Imprint from "./components/imprint/imprint";
import newProductDetail from "./components/newproduct-detail/product-detail.component";
import { userServiceApi } from "./components/serviceApi/userServiceApi";
import AllPartners from "./components/partners/AllPartners";
import AllRecipes from "./components/recipies/allRecipes"
import RecipeDetail from "./components/recipies/recipesdetail";
import terms from "./components/terms&conditions/terms";
import { useHistory } from "react-router";
import ReactGA from 'react-ga';
import { rightDrawerSlice } from "./slices/right_drawer";
import StripeWrapper from "./components/paymentCompleted/StripeWrapper";
import enviornmentalPolicy from "./components/enviornmentalPolicy/enviornmentalPolicy";
import humanrightcomplaint from "./components/humanrightcomplaint/humanrightcomplaint";
import rulesandregulations from "./components/rulesandregulations/rulesandregulations";
import ComplainForm from "./components/humanrightcomplaint/humanform";
function App() {
  const rightDrawerActions = rightDrawerSlice.actions;
  useEffect(() => {
    var timeoutDuration = 600000; // 5 minutes (in milliseconds)
    var timeoutID;
    function resetTimeout() {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(logout, timeoutDuration);
    }
    document.addEventListener("mousemove", resetTimeout);
    document.addEventListener("keypress", resetTimeout);
    concentAgrees();
    setLoginInfo();
    fetchCart();
  }, []);
  // const TRACKING_ID = "341647253"; 
  const TRACKING_ID = "UA-248930702-1";
  ReactGA.initialize(TRACKING_ID);
  const showRightDrawer = useSelector((state) => state.rightDrawer.isOpen);
  const currentUser = useSelector((state) => state.login.loggedUser);
  const showRecoverPassword = useSelector(
    (state) => state.login.isOpen
  );
  const showSlip = useSelector((state) => state.slip.isOpen);
  const showEditProfile = useSelector((state) => state.profile.showEditProfile);
  const showChangePassword = useSelector(
    (state) => state.profile.showChangePassword
  );
  const loginActions = loginSlice.actions;
  const dispatch = useDispatch();
  const registrationSuccessful = useSelector(
    (state) => state.register.registrationSuccessful
  );
  const verificationSuccessful = useSelector(
    (state) => state.register.verificationSuccessful
  );
  const contactregistrationSuccessful = useSelector(
    (state) => state.contact.contactregistrationSuccessful
  );
  const paymentSuccessful = useSelector(
    (state) => state.payment.paymentSuccessful
  );
  const paymentFailedMessage = useSelector(
    (state) => state.payment.paymentFailedMessage
  );
  const newsubscriptionSuccessful = useSelector(
    (state) => state.contact.newsubscriptionSuccessful
  );
  const passwordRecovered = useSelector(
    (state) => state.login.passwordRecovered
  );
  const errorMessage = useSelector(
    (state) => state.login.loadError
  );
  const concentAgree = localStorage.getItem("concent.acceptedval");
  const concentError = useSelector(
    (state) => state.login.concentError
  );
  const concentAgrees = () => {
    if (localStorage.getItem("concent.acceptedval") || (concentError)) {
      return false
    }
    else {
      return true
    }
  };
  const logout = () => {
    userServiceApi.getParamMethod("logout", localStorage.getItem("user_id")).then(
      (response) => { },
      (error) => { dispatch(loginActions.setError(error)); }
    );
    dispatch(rightDrawerActions.setType('login'));
    // localStorage.clear()
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("lang");
    localStorage.removeItem("server");
    localStorage.removeItem("myArray");
    localStorage.removeItem("numberofProducts");
    dispatch(loginActions.setIsUserLogged(false));
    dispatch(loginActions.setLoggedUser([]));
  }
  const md = useMediaQuery({ query: "(max-width: 576px)" });
  const path = document.location.pathname;
  const isDashboardPage = () => {
    if (path === "/login" || path === "/admin" || path === "/seller") {
      return true;
    } else return false;
  };
  const history = useHistory();
  const setLoginInfo = () => {
    if (!localStorage.getItem("user_id") || !localStorage.getItem("token"))
      return;
    else {
      const userId = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");
      userServiceApi.getParamMethod("getChekedProfile", token).then(
        (response) => {
          if (response.data.result) {
            if (response.data.result.token == token && response.data.result.user_id == userId) {
              userServiceApi.getAllProfile(userId).then(
                (response) => {
                  if (response.data.User) {
                    dispatch(loginActions.setIsUserLogged(true));
                    dispatch(loginActions.setLoggedUser(response.data.User[0]));
                  }
                  dispatch(loginActions.setIsLoading(false));
                },
                (error) => { dispatch(loginActions.setError(error)); }
              );
            }
            else {
              localStorage.clear()
              dispatch(loginActions.setIsUserLogged(false));
              dispatch(loginActions.setLoggedUser([]));
              history.push("/");
            }
          }
        },
        (error) => { dispatch(loginActions.setError(error)); }
      );
    }
  };
  const fetchCart = () => { };
  return (
    <div className="root" style={{ paddingTop: "100px" }}>
      <StripeWrapper>
        <FetchData />
        <ToastContainer />
        <BrowserRouter>
          {showRightDrawer && <RightDrawer />}
          {showSlip && <SlipModal />}
          {showRecoverPassword && <RecoverPasswordModal />}
          {showEditProfile && <EditProfileModal />}
          {showChangePassword && <ChangePassword />}
          {registrationSuccessful && <RegistrationSuccess />}
          {verificationSuccessful && <VerificationSuccess />}
          {contactregistrationSuccessful && <ContactRegistrationSuccess />}
          {paymentSuccessful && <PaymentSuccess />}
          {paymentFailedMessage && <PaymentFailedMessage />}
          {newsubscriptionSuccessful && <NewsSubscribeSuccess />}
          {passwordRecovered && <PasswordRecovered />}
          {passwordRecovered && <PasswordRecovered />}
          {errorMessage && <ErrorMessage />}
          {concentAgrees() && <ConcentMessage />}
          {!isDashboardPage() && <NewNav />}
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/Gewürze" exact>
              <ShopOverview />
            </Route>
            <Route path="/Alle Partner" exact>
              <AllPartners />
            </Route>
            <Route path="/AlleRezepte" exact>
              <AllRecipes />
            </Route>
            <Route path="/Rezept-details" component={RecipeDetail}>
            </Route>
            <Route path="/humanform" component={ComplainForm}>
            </Route>
            <Route path="/humanformde" component={ComplainForm}>
            </Route>
            <Route path="/Überuns" component={aboutus}></Route>
            <Route path="/Produkt-detail" component={newProductDetail}></Route>
            <Route path="/Warenkorb"><Cart /></Route>
            <Route path="/Bestellung"><Order /></Route>
            <Route path="/Datenschutz-Bestimmungen" component={privacy}></Route>
            <Route path="/Umweltpolitik" component={enviornmentalPolicy}></Route>
            <Route path="/RegelnundVorschriften" component={rulesandregulations}></Route>
            <Route path="/Menschenrechtsbeschwerde" component={humanrightcomplaint}></Route>
            <Route path="/Agb" component={terms}></Route>
            <Route path="/Kontakt" component={Contact}></Route>
            <Route path="/Impressum" component={Imprint}></Route>
            <Route path="/Profil"><Profile /></Route>
            <Route path="/shop" exact>
              <ShopOverview />
            </Route>
            <Route path="/AllPartners" exact>
              <AllPartners />
            </Route>
            <Route path="/AllRecipes" exact>
              <AllRecipes />
            </Route>
            <Route path="/Recipe-Detail" component={RecipeDetail}>
            </Route>
            <Route path="/aboutus" component={aboutus}></Route>
            <Route path="/recipes" component={recipes}></Route>
            <Route path="/spices" component={spices}></Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/post"></Route>
            <Route path="/cart">
              <Cart />
            </Route>
            <Route path="/order">
              <Order />
            </Route>
            <Route path="/verify" component={Verify}></Route>
            <Route path="/payment" component={Payment}></Route>
            <Route path="/paymentFailed" component={PaymentFailed}></Route>
            <Route path="/socialoauth" component={Auth}></Route>
            <Route path="/Privacy Policy" component={privacy}></Route>
            <Route path="/EnviornmentalPolicy" component={enviornmentalPolicy}></Route>
            <Route path="/Rulesandregulations" component={rulesandregulations}></Route>
            <Route path="/Humanrightcomplaint" component={humanrightcomplaint}></Route>
            <Route path="/contacts" component={Contact}></Route>
            <Route path="/imprint" component={Imprint}></Route>
            <Route path="/Product-Detail" component={newProductDetail}></Route>
            <Route path="/terms&conditions" component={terms}></Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
          {!isDashboardPage() && <Footer />}
        </BrowserRouter>
      </StripeWrapper>
    </div>
  );
}

export default App;
