import { createSlice } from "@reduxjs/toolkit";
export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    isLoading: false,
    text: "Payment Failed Try Again !",
    paymentSuccessful:false,
    error:"",
    paymentFailed:"",
    paymentFailedMessage:false
  },
  reducers: {
    setPaymentFailedMessage:(state, action) => {
      state.paymentFailedMessage= action.payload;
    },
    setPaymentFailed:(state, action) => {
      state.paymentFailed= action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError:(state,action) => {
      state.error = action.payload;
  },
    setText: (state, action) => {
      state.text = action.payload;
    },
    setPaymentSuccessful: (state, action) => {
      state.paymentSuccessful = action.payload;
    },
  },
});
