import OrderList from "./order_list";
import Title from "./title";
import { useSelector} from "react-redux";
const Order = () => {
    const cartItems = useSelector((state) => state.order.cartItems);
    return (
        <>
        {cartItems.length===0?<div className="cartNodata">
        <Title/>
        <OrderList/>
        </div>:
          <div className="cart">
          <Title/>
        <OrderList/>
     </div>} </>
    );}

export default Order;