import axios from "axios";
import { url} from "../../utils/url";
import { configMode } from "./configMode";
import {config} from "../../utils/header"
const orderServiceAPI={
   saveOrder:(data)=>axios.post(`${url}api/createCharge/`,data,configMode),
   purchaseOrder:(data)=>axios.post(`${url}user/purchaseOrder/`,data,configMode),
   getUserOrder:(route,id) => axios.get(`${url}user/${route}/${id}`,config),
   getCopounDiscount:(route,id) => axios.get(`${url}product/${route}/${id}`),
   getCountryName:() => axios.get(`https://ipapi.co/json/`),
   getSingleShippingValue:(countryName) => axios.get(`${url}admin/getSingleShippingValue/${countryName}`),
}
export {orderServiceAPI};