import { useSelector, useDispatch } from "react-redux";
import OrderItem from "./order-item";
import OrderListHeader from "./order_list_header";
import { useMediaQuery } from "react-responsive";
import cartSymbol from "../../new-assets/cartImage2.PNG";
import { Col, Row } from "react-bootstrap";
import { url } from "../../utils/url";
import { orderSlice } from "../../slices/order";
import { toast } from "react-toastify";
import { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { stripeSlice } from '../../slices/stripePayment';
import { paymentSlice } from "../../slices/paymentCompleted";
import { cartSlice } from "../../slices/cart";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import { rightDrawerSlice } from "../../slices/right_drawer";
import { useTranslation } from 'react-i18next';
import { orderServiceAPI } from "../serviceApi/orderServiceAPI";
import { productsApi } from "../serviceApi/productsApi";
import { adminServiceApi } from "../serviceApi/adminServiceApi";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { countryToAlpha2, countryToAlpha3 } from "country-to-iso";
import countries from "countries-list";
import stripeCardScss from "../scss-styles/AddPayMethod.module.scss";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { CardElement} from "@stripe/react-stripe-js";
import { getEuMembers } from "is-eu-member";
import { faAppleAlt,faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userServiceApi } from "../serviceApi/userServiceApi";
const OrderList = () => {
  const cartActions = cartSlice.actions;
  const percentages = useSelector((state) => state.cart.percentages);
  const currencies = useSelector((state) => state.cart.currencies);
  const copounPercentage = useSelector((state) => state.cart.CopounDiscount);
  const responseSuccess = useSelector((state) => state.order.successResponse);
  const isUserLogged = useSelector((state) => state.login.isUserLogged);
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const applIcon = <FontAwesomeIcon icon={faAppleAlt} size={"xs"} />;
  const lockIcon = <FontAwesomeIcon icon={faLock} size={"sm"} />;
  const orderActions = orderSlice.actions;
  const rightDrawerActions = rightDrawerSlice.actions;
  const showHeader = useMediaQuery({ query: "(min-width: 992px)" });
  const paymentActions = paymentSlice.actions;
  const {
    couponCode
  } = useSelector((state) => state.cart.inputValues);
  const {
    couponCodeErr,
  } = useSelector((state) => state.cart.inputErrors);
  const userId = localStorage.getItem("user_id");
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [success, setSuccess] = useState(false);
  const stripeActions = stripeSlice.actions;
  const [wait, setWait] = useState(false);
  const [formvalidate, setFormValidate] = useState("");
  var [selectedCountry,setSlectedcountry]=useState("");
  const [EumemberStatus,setEumember]=useState(localStorage.getItem("EumemberStatus"));
  const isLoading = useSelector((state) => state.stripePayment.isLoading);
  const text = useSelector((state) => state.stripePayment.text);
  useEffect(() => {
    if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
      history.push("/");
      showRightDrawer("login");
      return;
    } else {
      dispatch(orderActions.setOrdersLoading(false));
      orderServiceAPI.getUserOrder("getUserOrdered", userId).then(
        (response) => {
          dispatch(orderActions.setOrdersLoading(true));
          dispatch(orderActions.setOrderItem(response.data.result));
          if(response.data.result){
            localStorage.setItem("statusCart","false");
          }
        },
        (error) => {
          dispatch(orderActions.setError(error));
        }
      );
      productsApi.getmethod("getPercentageDiscount").then(
        (response) => {
          dispatch(cartActions.setPercentageDiscounts(response.data.result));
        },
        (error) => {
          dispatch(orderActions.setError(error));
        }
      );
      adminServiceApi.getmethod("getLatestCurrency").then(
        (response) => {
          dispatch(cartActions.setCurrencies(response.data.currency));
        },
        (error) => {
          dispatch(orderActions.setError(error));
        }
      );
      orderServiceAPI.getCopounDiscount("getCopounDiscount", userId).then(
        (response) => {
          dispatch(cartActions.setCopounDiscount(response.data.result));
        },
        (error) => {
          dispatch(orderActions.setError(error));
        }
      );
      adminServiceApi.getAllFaqs().then(
        (response) => {
          if (response.data.result) {
            dispatch(cartActions.setFaq(response.data.result));
          }
        },
        (error) => {
          dispatch(orderActions.setError(error));
        }
      );
      orderServiceAPI.getCountryName().then((response) => {
        if (response.data.country_name) {
          //   const isEuMember = require("is-eu-member").isEuMember;
          // const member= isEuMember(countryToAlpha2(response.data.country_name));
          // if(member){
          //   var countryName="EU";
          // }else{var countryName=response.data.country_name;}
          const isEuMember = require("is-eu-member").isEuMember;
         dispatch(cartActions.setEUMemberStatus(isEuMember(countryToAlpha2(response.data.country_name))));
         setEumember(isEuMember(countryToAlpha2(response.data.country_name)));
         localStorage.setItem("EumemberStatus",EumemberStatus)
          dispatch(orderActions.setCountryName(response.data.country_name));
          dispatch(orderActions.setCountryCode(countryToAlpha2(response.data.country_name)));
          orderServiceAPI.getSingleShippingValue(response.data.country_name).then(
            (response) => {
              if (response.data.shipping) {
                dispatch(cartActions.setSingleShipping(response.data.shipping));
                dispatch(cartActions.setShippingStatus(true));
              }
            },
            (error) => {
              dispatch(orderActions.setError(error));
            }
          );
        }
      }).catch((error) => {
        return error;
      });
    }
  }, [userId]);
  var cartItems = useSelector((state) => state.order.cartItems);
  if (cartItems) {
    dispatch(orderActions.setOrdeFailure(true));
  }
  else { dispatch(orderActions.setOrdeFailure(false)); }
  const getTotalPrice = () => {
    let total = 0;
    cartItems.forEach((cartItem) => {
      var replacePrice = cartItem.price;
      replacePrice = replacePrice.replace(/,/g, '.')
      total += parseFloat(replacePrice) * parseFloat(cartItem.quantity);
    });
    return parseFloat(total.toFixed(2));
  };
  const getUserEmail = () => {
    let userEmail="";
    cartItems.forEach((cartItem) => {
      userEmail=cartItem.email;
    });
    return userEmail;
  };
  const getUserOrderID = () => {
    let userOrderID="";
    cartItems.forEach((cartItem) => {
      userOrderID=cartItem.OrderID;
    });
    return userOrderID;
  };
  const getFullName = () => {
    let fullName="";
    cartItems.forEach((cartItem) => {
      fullName=cartItem.firstName+" "+cartItem.lastName;
    });
    return fullName;
  };
  const shippingStatus = useSelector((state) => state.cart.shippingStatus);
  var shippingValue = useSelector((state) => state.cart.shippingValue);
  const countryName = useSelector((state) => state.order.countryName);
  const countryCode = useSelector((state) => state.order.countryCode);
  const getTotalShipping = () => {
    var total = 0;
    const totalWeight = getTotalWeight();
    if (shippingStatus) {
      if ((countryCode =="DE")||(countryCode =="de")||(countryName=="Germany")) {
        if (totalWeight >= 0 && totalWeight <= 2000) {
          var replaceShippingValue1 = shippingValue.shippingValue;
          replaceShippingValue1 = replaceShippingValue1.replace(/,/g, '.')
        return  total = parseFloat(replaceShippingValue1).toFixed(2);
        }
        else if (totalWeight >= 2001 && totalWeight <= 4999) {
         return total = 5.99;
        }
      }
      else {
        if (totalWeight >= 0 && totalWeight <= 2000) {
          var replaceShippingValue1 = shippingValue.shippingValue;
          replaceShippingValue1 = replaceShippingValue1.replace(/,/g, '.')
       return   total = parseFloat(replaceShippingValue1).toFixed(2);
        }
        else if (totalWeight >= 2001 && totalWeight <= 4999) {
        return  total = 15.99;
        }
      }
    }
    else {
      // if (totalWeight >= 0 && totalWeight <= 2000) {
      //   var replaceShippingValue = "3,4";
      //   replaceShippingValue = replaceShippingValue.replace(/,/g, '.')
      //   total = parseFloat(replaceShippingValue).toFixed(2);
      // }
      // else if (totalWeight >= 2001 && totalWeight <= 4999) {
      // return  total = 6.99;
      // }
      if ((countryCode =="DE")||(countryCode =="de")||(countryName=="Germany")) {
        if (totalWeight >= 0 && totalWeight <= 2000) {
         return total = 3.99;
        }
        else if (totalWeight >= 2001 && totalWeight <= 4999) {
         return total = 5.99;
        }
      }
      else {
        if (totalWeight >= 0 && totalWeight <= 2000) {
       return   total = 10.49;
        }
        else if (totalWeight >= 2001 && totalWeight <= 4999) {
        return  total = 15.99;
        }
      }
    }
  };
  const getTotalTax = () => {
    let total = 0;
    if (!copounPercentage) {
      var vat = percentages.percentage / 100;
    }
    else { var vat = copounPercentage.percentage / 100; }
    cartItems.forEach((cartItem) => {
      var replacePrice = cartItem.price;
      replacePrice = replacePrice.replace(/,/g, '.')
      total += parseFloat(replacePrice) * vat * parseFloat(cartItem.quantity);
    });
    return parseFloat(total.toFixed(2));
  };
  const totalVat = () => {
    if (!copounPercentage) {
      var totalVat = percentages.percentage;
    }
    else { var totalVat = copounPercentage.percentage; }
    return totalVat;
  };
  const getTotalProductsQuantity = () => {
    let total = 0;
    cartItems.forEach((cartItem) => {
      total += parseFloat(cartItem.quantity);
    });
    return total;
  };
  const ProductsList = () => {
    let productList = "";
    cartItems.forEach((cartItem) => {
      productList = productList + " " + cartItem.Name + ",";
    });
    return productList;
  };
  const getTotalWeight = () => {
    let total = 0;
    cartItems.forEach((cartItem) => {
      if (cartItem.size?.length > 0) {
        let productWeight = cartItem.size.split(" ");
        var weight = productWeight[0];
        var measurement = productWeight[1];
        if ((measurement =="Kg")||(measurement =="kg")||(measurement =="kG")||(measurement =="KG")) {
         var replaceWeights = weight.replace(/,/g, '.');
         weight = parseFloat(replaceWeights) *1000;
          // weight = weight * 1000;
        }
        else{
          var replaceWeights = weight.replace(/,/g, '.');
         weight = parseFloat(replaceWeights);
        }
      }
      else {
        weight = 0;
        measurement = 0;
      }
      // var replaceSize = weight;
      // replaceSize = replaceSize.replace(/,/g, '.')
      // total += parseFloat(replaceSize) * parseFloat(cartItem.quantity);
      total += parseFloat(weight) * parseFloat(cartItem.quantity);
    });
    return parseFloat(total.toFixed(2));
  };
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  const currentlang = localStorage.getItem("lang");
  if (currentlang == "en") {
    cartItems = JSON.parse(JSON.stringify(cartItems).split('"enName":').join('"Name":'));
  }
  var grandTotal = getTotalPrice() + parseFloat(getTotalShipping());
  const papalGrandTotal=getTotalPrice() + parseFloat(getTotalShipping());
  const papalGrandTotalFixed=papalGrandTotal.toFixed(2);
  const purchase = () => {
    const userId = localStorage.getItem("user_id");
    const paypalPurchaseData = { user_id: userId, vat: getTotalTax(), grandTotal: grandTotal, netPrice: getTotalPrice() }
      if((getTotalPrice()?.length===0)||(getTotalProductsQuantity()?.length===0)||(ProductsList()?.length===0)||(grandTotal?.length===0)||(userId?.length===0)||(currencies.currency?.length===0)||(isNaN(Number(grandTotal)))){
        toast.info("All Data are not correctly filled!. ", {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }else{
        var data = getTotalPrice() + "+" + getTotalProductsQuantity() + "+" + ProductsList() + "+" + papalGrandTotalFixed + "+" + userId + "+" + currencies.currency
        window.location.href = `${url}pay/${data}`;
      }
  }
  const style = {
    color: "red",
    padding: "10px",
    fontFamily: "Arial",
  };
  const grandTotalf1 = getTotalPrice() + parseFloat(getTotalShipping());
  const grandTotalf2 = parseFloat(grandTotalf1)
  const { t } = useTranslation();
  const languages = localStorage.getItem("lang");
  const validateCoupon = (e) => {
    e.preventDefault();
    dispatch(cartActions.setcouponCodeErr(""));
    let isValid = true;
    if (couponCode.length < 1) {
      dispatch(
        cartActions.setcouponCodeErr(
          "Coupon Code is incorrect!"
        )
      );
      isValid = false;
    }
    if (isValid) {
      sendCouponRequest();
    }
  };
  const sendCouponRequest = () => {
    dispatch(cartActions.setIsLoading(true));
    const userId = localStorage.getItem("user_id");
    const data = { couponCode: couponCode, userId: userId };
    productsApi.postCoupon(data).then(
      (response) => {
        dispatch(cartActions.setIsLoading(false));
        if (response.data.result) {
          dispatch(cartActions.setcopounCodes(response.data.result));
          dispatch(cartActions.setCouponSucessMessage("Coupon Added Successfully !."))
          toast.info("Coupon Added Successfully !. ", {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        else if (response.data.failer == "true") { dispatch(cartActions.setcouponCodeErr("Coupon Code is incorrect!.")); }
        else {
          toast.info("Copoun Code is Incorrect !. ", {
            position: "top-left",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
      },
      (error) => {
        dispatch(cartActions.setError(error));
        dispatch(cartActions.setIsLoading(false));
      }
    );
  };
  const couponSucessMessage = useSelector((state) => state.cart.couponSucessMessage);
  window.scrollTo(0, 0)
  const md1 = useMediaQuery({ query: "(max-width: 780px)" });
  const md2 = useMediaQuery({ query: "(min-width: 781px)" });
  const md3 = useMediaQuery({ query: "(max-width: 1200px)" });
  const md4 = useMediaQuery({ query: "(max-width: 780px)" });
  const md5 = useMediaQuery({ query: "(min-width: 449px)" });
  const ipadMobileView = md2 && md3;
  const smallDevice2 = md4 && md5;
  const mdsmax = useMediaQuery({ query: "(max-width: 820px)" });
  const smmin = useMediaQuery({ query: "(min-width: 800px)" });
  const smmmaxs = useMediaQuery({ query: "(max-width: 1024px)" });
  const smallDevicecountry = smmin && smmmaxs;
  const mlbutton1 = useMediaQuery({ query: "(min-width: 1201px)" });
  const mlbutton2 = useMediaQuery({ query: "(max-width: 1380px)" });
  const mlDesktop = mlbutton1 && mlbutton2;
  /////////
  const md430 = useMediaQuery({ query: "(max-width: 448px)" });
  const md300 = useMediaQuery({ query: "(min-width: 341px)" });
  const ipadMobileView430 = md300 && md430;
  if (cartItems.length > 0) {
    var grandTotalf2display = grandTotalf2.toFixed(2).toString().replace(/\./g, ',');
    var subTotal = getTotalPrice().toFixed(2).toString().replace(/\./g, ',');
    var totalTax = getTotalTax().toFixed(2).toString().replace(/\./g, ',');
    var totalShippingValue = parseFloat(getTotalShipping()).toFixed(2).toString().replace(/\./g, ',');
  }
  var faq = useSelector((state) => state.cart.faq);
  if (currentlang == "en") {
    cartItems = JSON.parse(JSON.stringify(cartItems).split('"enName":').join('"Name":'));
    faq = JSON.parse(JSON.stringify(faq).split('"enquestion":').join('"question":'));
    faq = JSON.parse(JSON.stringify(faq).split('"enanswer":').join('"answer":'));
  }
  const countryCodes = Object.keys(countries.countries);
  const countryNames = countryCodes.map(code => countries.countries[code].name);
  const countryList = countryNames.sort();
  const statusCart = useSelector((state) => state.cart.statusCart);
  var statusCart1=localStorage.getItem("statusCart");
  if (statusCart1=="true") {
    window.location.href = window.location.href;
    localStorage.setItem("statusCart","false");
    // statusCart=false
  }
  // else if(statusCart1=="false"){
  //   window.location.href = window.location.href;
  //   localStorage.setItem("statusCart","false");
  //   statusCart=false
  // }
  // if(statusCart && (cartItems.length<1)){
  //   window.location.href = window.location.href;
  // }
  // else{
  //   localStorage.removeItem("statusCart")
  // }
  const stripe = useStripe();
  const elements = useElements();
  const card = useRef();
  const [cardInfo, setCardInfo] = useState({
    name: "",
    expiry: "",
    number: "",
    address: {
      line: "",
      postalCode: "",
      email: ""
    },
  });
  const [locations, setLocations] = useState({ countries: "", states: "", cities: "" });
  const [selectedLocation, setSelectedLocation] = useState({ country: {}, city: {}, state: {} });
  function handleChangeAddressLine(e) {
    const { value } = e.target;
    setCardInfo((prev) => {
      return { ...prev, address: { ...prev.address, line: value } };
    });
  }
  function handleChangePostalCode(e) {
    const { value } = e.target;
    setCardInfo((prev) => {
      return { ...prev, address: { ...prev.address, postalCode: value } };
    });
  }
  function handleChangeCity(e) {
    const { value } = e.target;
    setCardInfo((prev) => {
      return { ...prev, address: { ...prev.address, city: value } };
    });
  }
  function handleChangeHouseNumber(e) {
    const { value } = e.target;
    setCardInfo((prev) => {
      return { ...prev, address: { ...prev.address, houseNumber: value } };
    });
  }
  function handleChangeEmail(e) {
    const { value } = e.target;
    setCardInfo((prev) => {
      return { ...prev, address: { ...prev.address, email: value } };
    });
  }
  function handleChangeName(e) {
    const { value } = e.target;
    setCardInfo((prev) => {
      return { ...prev, name: value };
    });
  }
  function parseForSelect(arr) {
    return arr.map((item) => ({
      label: item.name,
      value: item.isoCode ? item.isoCode : item.name,
    }));
  }
  function handleSelectCountry(country) {
    if (country.value?.length === 0) { setFormValidate(t("order.countryrequired")); }
    else {
      const states = State.getStatesOfCountry(country.value);
      setSelectedLocation((prev) => {
        return { ...prev, country };
      });
      setLocations((prev) => ({ ...prev, states: parseForSelect(states) }));
    }
  }
  function handleSelectCity(city) {
    setSelectedLocation((prev) => {
      return { ...prev, city };
    });
  }
  const cardElementOptions = {
    hidePostalCode: true, // hide the Zip code input field
    // other options...
  };
  async function validateRequest() {
    const address = cardInfo.address;
    const cardElement = elements.getElement(CardElement);
    if (selectedCountry?.length === 0) {selectedCountry=countryName; }
    if (address.houseNumber?.length === 0) { setFormValidate(t("order.hrequired")); }
    else if (cardInfo.name?.length === 0) { setFormValidate(t("order.namerequired")); }
    else if (address.line?.length === 0) { setFormValidate(t("order.streetrequired")); }
    else if (address.email?.length === 0) { setFormValidate(t("order.emailrequired")); }
    else if (selectedCountry?.length === 0) {setFormValidate(t("order.countryrequired")); }
    // else if (address.postalCode?.length === 0) { setFormValidate(t("order.postalCoderequired")); }
    else if (address.city?.length === 0) { setFormValidate(t("order.cityrequired")); }
     else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(address.email)) {
      setFormValidate(t("order.invaliemail"))
  }
    else if (!stripe || !elements) {
      return;
    }
    else {
      if (selectedCountry?.length > 0 && cardElement) {
        handleSubmit();
      }
    }
  }
  async function handleSubmit() {
    const address = cardInfo.address;
    const oneTime = true;
    const amount = getTotalPrice() + parseFloat(getTotalShipping());
    // const amount = getTotalTax() + getTotalPrice() + parseFloat(getTotalShipping());
    const userId = localStorage.getItem("user_id");
    // else {
    dispatch(paymentActions.setIsLoading(true));
    dispatch(paymentActions.setPaymentSuccessful(false));
    const billingDetails = {
      name: cardInfo.name,
      email: address.email,
      address: {
        country: selectedLocation.country.value,
        // state: selectedLocation.state.value,
        city: selectedLocation.city.value,
        line1: address.line,
      },
    };
    try {
      stripe.createPaymentMethod({
        type: "card",
        billing_details: billingDetails,
        card: elements.getElement(CardElement),
      })
        .then((resp) => {
          const cardToken = stripe.createToken(elements.getElement(CardElement)).then(function (result) {
            if (typeof result.error === 'undefined' || result.error === null || result.error === undefined) {
              setError(null);
              setCardComplete(true);
              setFormValidate("");
              setWait(true);
              if (selectedCountry?.length === 0) {selectedCountry=countryName; }
              const paymentData = {
                email: address.email,
                houseNumber: address.houseNumber,
                tokenID: result.token.id,
                country: selectedCountry,
                city: address.city,
                oneTime: oneTime,
                amount: amount,
                userId: userId,
                productName: ProductsList(),
                currency: currencies.currency,
                streetAddress: address.line,
                name: cardInfo.name
              }
              orderServiceAPI.saveOrder(paymentData).then(
                (response) => {
                  if (response.data.Success) {
                    dispatch(paymentActions.setIsLoading(false));
                    dispatch(orderActions.setSuccessResponse(response.data.Success));
                    setSuccess(true);
                    handleClose();
                    dispatch(paymentActions.setPaymentSuccessful(true));
                    dispatch(paymentActions.setText("Payment Completed successfully ! "));
                    document.body.style.overflow = "visible";
                  } else if (response.data.error) {
                    setWait(false);
                    setFormValidate("Some thing went wrong try again.");
                    dispatch(paymentActions.setPaymentFailed("Some thing went wrong try again."));
                    return;
                  }
                },
                (error) => {
                  setWait(false);
                  setFormValidate("Some thing went wrong try again.");
                  dispatch(orderActions.setError(error));
                  dispatch(stripeActions.setIsLoading(false));
                  return;
                }
              )
                .catch(error => {
                  setWait(false);
                  setFormValidate("Some thing went wrong try again.");
                  dispatch(orderActions.setError(error));
                  dispatch(stripeActions.setIsLoading(false));
                  return
                });
            }
            else {
              setWait(false);
              setFormValidate(result.error.message);
            }
          });
        });
    } catch (err) {
      setWait(false);
      setFormValidate("Payment Failed try again.");
      dispatch(paymentActions.setPaymentFailed("Payment Failed try again."));
    }
  }
  useEffect(() => {
    const allCountry = Country.getAllCountries();
    setLocations((prev) => {
      return { ...prev, countries: parseForSelect(allCountry) };
    });
  }, []);
  var paymentFailureMessage = useSelector((state) => state.payment.paymentFailed);
  //   const isInEu = Intl.DateTimeFormat().resolvedOptions().timeZone.indexOf("Europe") == 0
  //   const isEuMember = require("is-eu-member").isEuMember,
  // getEuMembers = require("is-eu-member").getEuMembers;
  // console.log(getEuMembers());
  // console.log(isEuMember("DE")); // true
  // const EumemberStatus = useSelector((state) => state.cart.EumemberStatus);
  // const EumemberStatus = localStorage.getItem("EumemberStatus");
  const paymentSuccessful = useSelector(
    (state) => state.payment.paymentSuccessful
  );
  // if(paymentSuccessful){
    localStorage.setItem("getUserEmail",getUserEmail());
    localStorage.setItem("getFullName",getFullName());
    localStorage.setItem("ProductsList",ProductsList());
    localStorage.setItem("getUserOrderID",getUserOrderID());
    localStorage.setItem("geTotalPayment",currencies.CurrencySymbol+" "+grandTotalf2display);
  // }
  // if(paymentSuccessful){
  //   var emaildata = {
  //     from: "dev@adeymailserver.de",
  //     to: getUserEmail(),
  //     cc: "yemie008@gmail.com",
  //     bcc: "adeymeselesh@gamil.com",
  //     subject: "Zahlungsbenachrichtigung",
  //     text: "<p><b>"+getFullName()+"</b>,</p>"+"<br>Wir möchten bestätigen, dass Ihre Zahlung für" +ProductsList()+" ist gewesen .<br> erfolgreich abgeschlossen. Vielen Dank, dass Sie sich entschieden haben, bei uns einzukaufen!</br>,<br/>Ihre Bestellung wird nun so schnell wie möglich bearbeitet und an Sie versendet. Wenn Sie welche haben <br/>weitere Fragen oder Bedenken, Bitte zögern Sie nicht, unser Kundenservice-Team zu kontaktieren.<br><br>Vielen Dank für Ihr Geschäft!<br><br>Mit freundlichen Grüßen,<br> Your Adey Spices team",
  //   }
  //   if(currentlang=="en"){
  //     emaildata = {
  //       from: "dev@adeymailserver.de",
  //       to: getUserEmail(),
  //       cc: "yemie008@gmail.com",
  //       bcc: "adeymeselesh@gamil.com",
  //       subject: "Payment Notification",
  //       text: "<p><b>"+getFullName()+"</b>,</p>"+"<br>We would like to confirm that your payment for" +ProductsList()+" has been .<br> successfully completed. Thank you for choosing to shop with us!</br>,<br/>Your order will now be processed and shipped to you as soon as possible. If you have any <br/>further questions or concerns, please don't hesitate to contact our customer service team.<br><br>Thank you for your business!<br><br>Best regards,<br> Your Adey Spices team",
  //     }
  //   }
  //   userServiceApi.sendEmailUser(emaildata).then(
  //       (response) => {
  //       },
  //       (error) => {
  //         return error;
  //         // dispatch(registerActions.setError(error));
  //       });
  // }
  return (
    <>{EumemberStatus ?<>{md1 ? <div className="orderlistMobile">{cartItems.length === 0 ? <div className="OrderNoitem">
    {statusCart ? <CircularProgress size="6rem" style={{marginLeft: "40%",marginTop:"20px"}}/> : <>
      <img src={cartSymbol} className="nodataImage" style={{ width: "80px", marginLeft: "40%" }} />
      <p style={{ textAlign: "center" }}>{t('order.notapproved')}</p>
      <div className="nodataButtonMobile" onClick={() => {
        if (languages === "de") {
          history.push("/Gewürze");
        }
        else { history.push("/shop"); }
      }}>
        {t("cart.noDataCartButton")}</div>
    </>}
  </div> : <Row>
    <Col lg={8}>
      <br /><br />
      <div className="ordertItembordered">
        {/* {cartItems.length === 0 ? "" : showHeader ? <OrderListHeader /> : ""} */}
        {cartItems.length === 0 ? "" :
          cartItems.map((cartItem) => {
            return <OrderItem cartItem={cartItem} />;
          })}
      </div>
      {cartItems.length === 0 ? "" :
        <div className="coupon">
          <form onSubmit={validateCoupon}>
            <label>{t("order.couponcode")}</label>
            <div className="form-inline">
              <input
                className="input"
                type="text"
                id="couponCode"
                required
                value={couponCode}
                onChange={(e) => {
                  dispatch(cartActions.setCouponCode(e.target.value));
                }}
                placeholder={t("order.couponPlaceholder")}
              />
              <label><button type="submit" value="APPLY COUPON" className="">{t("order.apply")}</button></label>
            </div>
            <div className="sucessmessage">{couponSucessMessage}</div>
            <div className="form-error">{couponCodeErr}</div>
          </form>
        </div>
      }
      <>
        {responseSuccess.length > 0 ? setShow(false) :
          <Modal show={show} onHide={handleClose} background-color="black" >
            <div className={stripeCardScss.wrapper} >
              <div className={stripeCardScss.innerWrapper}>
                <div className={stripeCardScss.title} ><b>{applIcon}{" "}{t("order.stripePayment")}</b></div>
                <span className="stripeCardScss"><i>{t("order.paywithcard")}</i></span><br/>
                {paymentFailureMessage.length === 0 ? <>{wait ? <h style={style}><span className="loading" tra><br/><b>{wait && <CircularProgress />}</b></span>{t('order.loading')} </h> : ""}</> : <></>}
                <p style={{ color: "red" }}>{formvalidate}</p>
                <div className={stripeCardScss.row}>
                      <label>{t("order.cardholderName")}</label>
                      <input
                        onChange={handleChangeName}
                        type="text"
                        name="name"
                        required
                        placeholder={t("order.cardholderName")}
                        style={{ height: "40px" }}
                      />
                    </div>
                    <label>{t("order.cardInformation")}</label>
                    <div className={stripeCardScss.rowPaymentInput}>
                      <CardElement ref={card} options={{ style: { height: "40px" }, postalCode: true }} />
                    </div>
                    <div className={stripeCardScss.addressWrapper}>
                      <div className={stripeCardScss.rowSelect}>
                        <div>
                          <label>{t("order.CountryCode")}</label>
                          <select
                            isClearable={true}
                            isSearchable={true}
                            required
                            name="country"
                            onChange={(e) => {
                              setSlectedcountry(e.target.value);
                            }}
                            style={{ height: "40px" }}
                          >
                            <option value={countryToAlpha2(countryName)} selected>{countryName}</option>
                           {!countryList ? "" : countryList.map((countryList) => {
                            return (<option value={countryToAlpha2(countryList)}>{countryList}</option>)
                          })} 
                          </select>
                        </div>
                        <div>
                          <label>{t("order.email")}</label>
                          <input onChange={handleChangeEmail} type="email" placeholder={t("order.enteremail")} required style={{ height: "40px" }} />
                        </div>
                      </div>
                      <div className={stripeCardScss.row}>
                        <div>
                          <label>{t("order.city")}</label>
                          <input onChange={handleChangeCity} type="text" name="city" required placeholder={t("order.city")} style={{ height: "40px" }} />
                        </div>
                        {/* <div>
                          <label>{t("order.PostalCode")}</label>
                          <input onChange={handleChangePostalCode} type="number" required placeholder={t("order.postalCode")} style={{ height: "40px" }} />
                        </div> */}
                      </div>
                      <div className={stripeCardScss.rowSelect}>
                          <div className={stripeCardScss.row}>
                          <label>{t("order.streetAddress")}</label>
                          <input
                            onChange={handleChangeAddressLine}
                            type="text"
                            name="address"
                            required
                            placeholder={t("order.enterStreetAdrress")}
                            style={{ height: "40px" }}
                          />
                        </div>
                        <div>
                          <label>{t("order.houseNumber")}</label>
                          <input onChange={handleChangeHouseNumber} type="text" placeholder={t("order.enterhouseNumber")} required style={{ height: "40px" }} />
                        </div>
                      </div>
                  <div className={stripeCardScss.btnContainer}>
                    {wait ? <button disabled onClick={validateRequest} style={{ width: "100%", cursor: "not-allowed", filter: "blur(0.5px)", background: "#8A9A5B" }}>{wait && <CircularProgress size={13} style={{ color: 'white' }} />}{t("order.SubmitingPayment")}</button> :
                      <button onClick={validateRequest} style={{ width: "100%" }} >{t("order.SubmitPayment")}</button>
                    }{" "}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        }
        <br />
        {cartItems.length === 0 ? "" :
          <div className="mobilePricing">
            <div className="items">
              {" "}
              <div className="item">
                <div className="text">{t('order.SUBTOTAL')}</div>
                <div className="price">{currencies.CurrencySymbol} {subTotal} </div>
                <div className="text">{t('order.inc')}{t('order.VAT')}({totalVat()}%):</div>
                <div className="price">{currencies.CurrencySymbol} {totalTax}</div>
                <div className="text">{t("order.shippinval")}</div>
                <div className="price">{currencies.CurrencySymbol} {totalShippingValue}</div>
                <div className="text">{t('order.GRANDTOTAL')}</div>
                <div className="price" translate="no">
                  {currencies.CurrencySymbol} {grandTotalf2display}
                </div>
              </div>
              <br />
              {localStorage.getItem("lang") === "en" ? <Row><div className="termcondition"><p>Our <a href="/terms&conditions" target="_blank">General Terms and Conditions</a> apply. Information on data protection can be found in our <a href="/Privacy Policy" target="_blank">Privacy Policy</a> </p></div></Row>
                : <Row><div className="termcondition"><p>Es gelten unsere <a href="/Agb" target="_blank">Allgemeinen Geschäftsbedingungen</a> Informationen zum Datenschutz finden Sie in unserer <a href="/Datenschutz-Bestimmungen" target="_blank">Datenschutzrichtlinie</a> </p></div></Row>}
              <div className="item">{ipadMobileView430?<div className="mobilePaymentButtons" style={{ marginLeft: "20%",marginRight: "25%",display:"block" }}>
                  {localStorage.getItem("lang") ? <><div className="stripeMobile-buttonde" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                  {/* <div className="payPal-buttonMobilede" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                  <button class="paypalde-button" onClick={purchase}><span class="paypal-button-title">{t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                  </> : <><div className="stripeMobile-button" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                  <button class="paypal-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                  {/* <div className="payPal-buttonMobile" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                  </>}
                </div>:<> {smallDevice2 ? <div className="mobilePaymentButtons" style={{ marginLeft: "32%" }}>
                  {localStorage.getItem("lang") ? <><div className="stripeMobile-buttonde" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                  <button class="paypalde-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                  {/* <div className="payPal-buttonMobilede" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                  </> : <><div className="stripeMobile-button" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                  <button class="paypal-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                  {/* <div className="payPal-buttonMobile" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                  </>}
                </div> : <div className="mobilePaymentButtons" style={{ marginLeft: "3%" }}>
                  {localStorage.getItem("lang") ? <><div className="stripeMobile-buttonde" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                  {/* <div className="payPal-buttonMobilede" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                  <button class="paypalde-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                  </> : <><div className="stripeMobile-button" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                  <button class="paypal-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                  {/* <div className="payPal-buttonMobile" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                  </>}
                </div>}</>}
              </div>
            </div>
            {" "}
          </div>}
        <br />
      </>
    </Col>
  </Row>
  }
    {cartItems.length === 0 ? "" :
      <div className="questions">
        <h6><b style={{ paddingLeft: "23px" }}>{t("order.faqTitle")}</b></h6>
        <div className="sectionedQuestions">
          <div>
            <AppBar position="static">
            </AppBar>
            {faq.length === 0 ? "" :
              faq.map((faqItem) => {
                return (
                  <Accordion key={faqItem.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon></ExpandMoreIcon>} >
                      <p className="post__description" dangerouslySetInnerHTML={{ __html: faqItem.question }}></p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="post__description" dangerouslySetInnerHTML={{ __html: faqItem.answer }}></p>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
          </div>
        </div>
      </div>}
  </div>
    ///////////mobile user
    :
    <div className="cart-list">
      {cartItems.length === 0 ? <div className="OrderNoitem">
        {statusCart ? <CircularProgress size="6rem" style={{marginLeft: "40%",marginTop:"20px"}} /> : <>
          <img src={cartSymbol} alt="sefiegrui" />
          <p>{t('order.notapproved')}</p>
          <div className="nodataButton" onClick={() => {
            if (languages === "de") {
              history.push("/Gewürze");
            }
            else { history.push("/shop"); }
          }}>
            {t("cart.noDataCartButton")}</div></>}
      </div> : <Row>
        <br /><br />
        {ipadMobileView ? <div className="ipadordertItembordered">
          {cartItems.length === 0 ? "" : <div className="cart-list-header">
            <Row>
              <Col lg={6} sm={6}>
                <span> {t('order.Product')}</span>
              </Col>
              <Col lg={2} sm={2}>
                {t('order.Quantity')}
              </Col>
              <Col lg={2} sm={2}>
                {t('order.Price')}
              </Col>
              <Col lg={2} sm={2}>
                {t('order.Total')}
              </Col>
            </Row>
          </div>}
          {cartItems.length === 0 ? "" :
            cartItems.map((cartItem) => {
              return <OrderItem cartItem={cartItem} />;
            })}
        </div> : <div className="ordertItembordered">
          {cartItems.length === 0 ? "" : showHeader ? <OrderListHeader /> : ""}
          {cartItems.length === 0 ? "" :
            cartItems.map((cartItem) => {
              return <OrderItem cartItem={cartItem} />;
            })}
        </div>}
        <Row>
          <Col sm={6} lg={6}>
            {cartItems.length === 0 ? "" :
              <>
                <div className="coupon">
                  <form onSubmit={validateCoupon}>
                    <label>{t("order.couponcode")}</label>
                    <div className="form-inline">
                      <input
                        className="input"
                        type="text"
                        id="couponCode"
                        required
                        value={couponCode}
                        onChange={(e) => {
                          dispatch(cartActions.setCouponCode(e.target.value));
                        }}
                        placeholder={t("order.couponPlaceholder")}
                      />
                      <label><button type="submit" value="APPLY COUPON" className="">{t("order.apply")}</button></label>
                    </div>
                    <div className="sucessmessage">{couponSucessMessage}</div>
                    <div className="form-error">{couponCodeErr}</div>
                  </form>
                </div>
              </>
            }</Col>
          <Col sm={6} lg={6}>
            {ipadMobileView ? <>{cartItems.length === 0 ? "" :
              <div className="ipadPricing">
                <div className="pricingHeader">{t("order.ShopingOrder")}</div>
                <div className="ipadItems">
                  {" "}
                  <div className="ipaditem">
                    <div className="text">{t('order.SUBTOTAL')}</div>
                    <div className="price">{currencies.CurrencySymbol} {subTotal} </div>
                  </div>
                  <div className="ipaditem">
                    <div className="text">{t('order.inc')}{t('order.VAT')}({totalVat()}%):</div>
                    <div className="price">{currencies.CurrencySymbol} {totalTax}</div>
                  </div>
                  <div className="ipaditem">
                    <div className="text">{t("order.shippinval")}</div>
                    <div className="price">{currencies.CurrencySymbol} {totalShippingValue}</div>
                  </div>
                  <div className="ipaditem">
                    <div className="text">{t('order.GRANDTOTAL')}</div>
                    <div className="price" translate="no">
                      {currencies.CurrencySymbol} {grandTotalf2display}
                    </div>
                  </div>
                  {localStorage.getItem("lang") === "en" ? <Row><div className="termcondition"><p>Our <a href="/terms&conditions" target="_blank">General Terms and Conditions</a> <br />apply. Information on  data protection <br />can be found in our <a href="/Privacy Policy" target="_blank">Privacy Policy</a> </p></div></Row>
                    : <Row><div className="termcondition"><p>Es gelten unsere <a href="/Agb" target="_blank">Allgemeinen<br /> Geschäftsbedingungen</a> Informationen <br />zum Datenschutz finden Sie in unserer <br /><a href="/Datenschutz-Bestimmungen" target="_blank">Datenschutzrichtlinie</a> </p></div></Row>}
                  <div className="ipaditem">
                    <div className="text"></div>
                    <Row>
                      <div className="ipadButtons">
                        {localStorage.getItem("lang") === "de" ? <><div className="ipadpay-buttonde" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                        {/* <div className="ipadpayPal-buttonde" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                        <button class="paypalde-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                        </> : <><div className="ipadpay-button" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                        <button class="paypal-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                        {/* <div className="ipadpayPal-button" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                        </>}  {" "}
                      </div>
                    </Row>
                  </div>
                </div>{" "}
              </div>}</> : <>{cartItems.length === 0 ? "" :
                <div className="desktopPricing">
                  <div className="pricingHeader">{t("order.ShopingOrder")}</div>
                  <div className="items">
                    {" "}
                    <div className="item">
                      <div className="text">{t('order.SUBTOTAL')}</div>
                      <div className="price">{currencies.CurrencySymbol} {subTotal} </div>
                    </div>
                    <div className="item">
                      <div className="text">{t('order.inc')}{t('order.VAT')}({totalVat()}%):</div>
                      <div className="price">{currencies.CurrencySymbol} {totalTax}</div>
                    </div>
                    <div className="item">
                      <div className="text">{t("order.shippinval")}</div>
                      <div className="price">{currencies.CurrencySymbol} {totalShippingValue}</div>
                    </div>
                    <div className="item">
                      <div className="text">{t('order.GRANDTOTAL')}</div>
                      <div className="price" translate="no">
                        {currencies.CurrencySymbol} {grandTotalf2display}
                      </div>
                    </div>
                    <br />
                    {localStorage.getItem("lang") === "en" ? <Row><p>Our <a href="/terms&conditions" target="_blank">General Terms and Conditions</a> apply. Information on <br /> data protection can be found in our <a href="/Privacy Policy" target="_blank">Privacy Policy</a> </p></Row>
                      : <Row><p>Es gelten unsere <a href="/Agb" target="_blank">Allgemeinen Geschäftsbedingungen</a><br /> Informationen zum Datenschutz finden Sie in unserer <br /><a href="/Datenschutz-Bestimmungen" target="_blank">Datenschutzrichtlinie</a> </p></Row>}
                    {mlDesktop?<div className="mlipaditem">
                    <div className="text"></div>
                    <Row>
                      <div className="mlipadButtons">
                        {localStorage.getItem("lang") === "de" ? <><div className="mlipadpay-buttonde" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                        {/* <div className="mlipadpayPal-buttonde" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                        {localStorage.getItem("lang") === "de" ?
                           <button class="paypalde-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                            //  <div className="payPal-buttonde" onClick={purchase}>{t('order.BuyWithPaypal')}</div>
                              :
                            //  <div className="payPal-button" onClick={purchase}>{t('order.BuyWithPaypal')}</div>
                            <button class="paypal-button"><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                             }{" "}
                        </> : <><div className="mlipadpay-button" onClick={handleShow}>{t('order.BuyWithStripe')}</div>
                        {localStorage.getItem("lang") === "de" ?
                            <button class="paypalde-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                            //  <div className="payPal-buttonde" onClick={purchase}>{t('order.BuyWithPaypal')}</div>
                              :
                            //  <div className="payPal-button" onClick={purchase}>{t('order.BuyWithPaypal')}</div>
                            <button class="paypal-button" onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                             }{" "}
                        {/* <div className="mlipadpayPal-button" onClick={purchase}>{t('order.BuyWithPaypal')}</div> */}
                        </>}  {" "}
                      </div>
                    </Row>
                  </div>:<div className="item">
                      <div className="text"></div>
                      <div className="desktopPaymentButtons">
                        <Row>
                          <Col sm={6}>
                            {localStorage.getItem("lang") === "de" ? <div className="pay-buttonde" onClick={handleShow}>{t('order.BuyWithStripe')}</div> : <div className="pay-button" onClick={handleShow}>{t('order.BuyWithStripe')}</div>}{" "}
                          </Col>
                          <Col sm={6}>
                            {localStorage.getItem("lang") === "de" ?
                             <button class="paypalde-button"onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                            //  <div className="payPal-buttonde" onClick={purchase}>{t('order.BuyWithPaypal')}</div>
                              :
                            //  <div className="payPal-button" onClick={purchase}>{t('order.BuyWithPaypal')}</div>
                            <button class="paypal-button"onClick={purchase}><span class="paypal-button-title"> {t("order.buywith")}</span><span class="paypal-logo"><i>{t("order.Pay")}</i><i>{t("order.Pal")}</i></span></button>
                             }{" "}
                          </Col></Row>
                      </div>
                    </div>}
                  </div>{" "}
                </div>}</>}
          </Col>
        </Row>
        {/* <div className="tacbox">
          <div class="custom-control custom-checkbox checkbox-lg">
            <input type="checkbox" class="custom-control-input" id="checkbox-2" />
            <span class="custom-control-label" for="checkbox-2"> Our <a href=""target="_blank">*General Terms and Conditions*</a> apply. Information on data protection can be found in our <a href="/Privacy Policy" target="_blank">*Privacy Policy*</a></span>
          </div>
          <br />
        </div> */}
        <div className="questions">
          <h6><b style={{ paddingLeft: "23px" }}>{t("order.faqTitle")}</b></h6>
          <div className="sectionedQuestions">
            <div>
              <AppBar position="static">
              </AppBar>
              {faq.length === 0 ? "" :
                faq.map((faqItem) => {
                  return (
                    <Accordion key={faqItem.id}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon></ExpandMoreIcon>} >
                        <p className="post__description" dangerouslySetInnerHTML={{ __html: faqItem.question }}></p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="post__description" dangerouslySetInnerHTML={{ __html: faqItem.answer }}></p>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
            </div>
          </div>
        </div>
        <>
          {responseSuccess.length > 0 ? setShow(false) :
            <div className="modalClass"><br /><br />
              <Modal show={show} onHide={handleClose} background-color="black" >
                <div className={stripeCardScss.wrapper} >
                  <div className={stripeCardScss.innerWrapper}>
                    <div className={stripeCardScss.title} ><b>{applIcon}{" "}{t("order.stripePayment")}</b></div>
                    <span className="stripeCardScss"><i>{t("order.paywithcard")}</i></span>
                    {paymentFailureMessage.length === 0 ? <>{wait ? <h style={style}><span className="loading" tra><br/><b>{wait && <CircularProgress />}</b></span>{t('order.loading')} </h> : ""}</> : <></>}
                    <p style={{ color: "red" }}>{formvalidate}</p>
                    <div className={stripeCardScss.row}>
                      <label>{t("order.cardholderName")}</label>
                      <input
                        onChange={handleChangeName}
                        type="text"
                        name="name"
                        required
                        placeholder={t("order.cardholderName")}
                        style={{ height: "40px" }}
                      />
                    </div>
                    <label>{t("order.cardInformation")}</label>
                    <div className={stripeCardScss.rowPaymentInput}>
                      <CardElement ref={card}  options={{ style: { height: "40px" }, postalCode: true }} />
                    </div>
                    <div className={stripeCardScss.addressWrapper}>
                      <div className={stripeCardScss.rowSelect}>
                        <div>
                          <label>{t("order.CountryCode")}</label>
                          <select
                            isClearable={true}
                            isSearchable={true}
                            required
                            name="country"
                            onChange={(e) => {
                              setSlectedcountry(e.target.value);
                            }}
                            style={{ height: "40px" }}
                          >
                            <option value={countryToAlpha2(countryName)} selected>{countryName}</option>
                           {!countryList ? "" : countryList.map((countryList) => {
                            return (<option value={countryToAlpha2(countryList)}>{countryList}</option>)
                          })} 
                          </select>
                        </div>
                        <div>
                          <label>{t("order.email")}</label>
                          <input onChange={handleChangeEmail} type="email" placeholder={t("order.enteremail")} required style={{ height: "40px" }} />
                        </div>
                      </div>
                      <div className={stripeCardScss.row}>
                        <div>
                          <label>{t("order.city")}</label>
                          <input onChange={handleChangeCity} type="text" name="city" required placeholder={t("order.city")} style={{ height: "40px" }} />
                        </div>
                        {/* <div>
                          <label>{t("order.PostalCode")}</label>
                          <input onChange={handleChangePostalCode} type="number" required placeholder={t("order.postalCode")} style={{ height: "40px" }} />
                        </div> */}
                      </div>
                      <div className={stripeCardScss.rowSelect}>
                          <div className={stripeCardScss.row}>
                          <label>{t("order.streetAddress")}</label>
                          <input
                            onChange={handleChangeAddressLine}
                            type="text"
                            name="address"
                            required
                            placeholder={t("order.enterStreetAdrress")}
                            style={{ height: "40px" }}
                          />
                        </div>
                        <div>
                          <label>{t("order.houseNumber")}</label>
                          <input onChange={handleChangeHouseNumber} type="text" placeholder={t("order.enterhouseNumber")} required style={{ height: "40px" }} />
                        </div>
                      </div>
                      <div className={stripeCardScss.btnContainer}>
                        {wait ? <button disabled onClick={validateRequest} style={{ width: "100%", cursor: "not-allowed", filter: "blur(0.5px)", background: "#8A9A5B" }}>{wait && <CircularProgress size={13} style={{ color: 'white' }} />}{t("order.SubmitingPayment")}</button> :
                          <button onClick={validateRequest} style={{ width: "100%" }} >
                             {/* {lockIcon}{" "} */}
                             {t("order.SubmitPayment")}</button>
                        }{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          }
        </>
      </Row>}
    </div>}</>:<div className="notavailablecountry">{mdsmax?<div className="OrderNotavailablecountryMobile">
    {statusCart ? <CircularProgress size="6rem" style={{marginLeft: "40%",marginTop:"20px"}}/> : <>
      <img src={cartSymbol} className="nodataImage" style={{ width: "80px", marginLeft: "40%" }} />
      <p style={{ textAlign: "center" }}>{t('order.notavailableiincoutry')}</p>
      <div className="nodataButtonMobile" onClick={() => {
        if (languages === "de") {
          history.push("/");
        }
        else { history.push("/"); }
      }}>
        {t("order.visitwebshop")}</div>
    </>}
  </div>:<>{smallDevicecountry?<div className="OrderNotavailablecountryTablet">
    {statusCart ? <CircularProgress size="6rem" style={{marginLeft: "40%",marginTop:"20px"}}/> : <>
      <img src={cartSymbol} className="nodataImage" style={{ width: "80px", marginLeft: "40%" }} />
      <p style={{ textAlign: "center" }}>{t('order.notavailableiincoutry')}</p>
      <div className="nodataButtonMobile" onClick={() => {
        if (languages === "de") {
          history.push("/");
        }
        else { history.push("/"); }
      }}>
        {t("order.visitwebshop")}</div>
    </>}
  </div>:<div className="OrderNotavailablecountry">
    {statusCart ? <CircularProgress size="6rem" style={{marginLeft: "40%",marginTop:"20px"}}/> : <>
      <img src={cartSymbol} className="nodataImage" style={{ width: "80px", marginLeft: "45%" }} />
      <p style={{ textAlign: "center" }}>{t('order.notavailableiincoutry')}</p>
      <div className="nodataButton" onClick={() => {
        if (languages === "de") {
          history.push("/");
        }
        else { history.push("/"); }
      }}>
        {t("order.visitwebshop")}</div>
    </>}
  </div>}</>}</div>

    }
    </>
  );
};

export default OrderList;