import titleBackground from "../../new-assets/shop-top-image.png";
import { useTranslation } from 'react-i18next';
const Title = ({title}) => {
  const { t } = useTranslation();
  return (
    <div
      className="title"
      style={{
        background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${titleBackground})`,
      }}>
      <div className="main-title">{t('privacyPolicy.mainTitle')}</div>
      <div className="sub-title">
        {/* {t('privacyPolicy.title')} */}
      </div>
    </div>
  );
};

export default Title;
