import { rightDrawerSlice } from "../../slices/right_drawer";
import { useDispatch, useSelector } from "react-redux";
import { registerSlice } from "../../slices/register";
import { CircularProgress } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { useTranslation} from 'react-i18next';
import { userServiceApi } from "../serviceApi/userServiceApi";
const Register = () => {
  const rightDrawerActions = rightDrawerSlice.actions;
  const registerActions = registerSlice.actions;
  const currentlang = localStorage.getItem("lang");
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    password1,
    password2,
  } = useSelector((state) => state.register.inputValues);
  const actions = rightDrawerSlice.actions;
  const closeRightModal = () => {
    dispatch(actions.hideDrawer());
    document.body.style.overflow = "visible";
  };
  const type = useSelector((state) => state.rightDrawer.type);
  const { t } = useTranslation();
  let headerText = "";
  switch (type) {
    case "login":
      headerText= t('profile.Login')
      break;
      case "profile":
      headerText= t('profile.Profile')
      break;
    case "cart":
      headerText= t('profile.cart')
      break;
    case "register":
      headerText= t('profile.Register')
        break;
  }
  const {
    firstNameErr,
    lastNameErr,
    emailErr,
    phoneErr,
    addressErr,
    password1Err,
    password2Err,
  } = useSelector((state) => state.register.inputErrors);
  const isLoading = useSelector((state) => state.register.isLoading);
  const dispatch = useDispatch();
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.showDrawer());
    dispatch(rightDrawerActions.setType(type));
    document.body.style.overflow = "hidden";
  };
  const validate = (e) => {
    e.preventDefault();
    dispatch(registerActions.setFirstNameErr(""));
    dispatch(registerActions.setLastNameErr(""));
    dispatch(registerActions.setEmailErr(""));
    dispatch(registerActions.setPassword1Err(""));
    dispatch(registerActions.setPassword2Err(""));
    dispatch(registerActions.setPhoneErr(""));
    dispatch(registerActions.setAddressErr(""));
    let isValid = true;
    if (firstName.length < 2) {
      dispatch(
        registerActions.setFirstNameErr(t("recoverpaswword.firstNamerequ")));
      isValid = false;
    }
    if (address.length < 1) {
      dispatch(
        registerActions.setAddressErr(t("recoverpaswword.addressmandatory"))
      );
      isValid = false;
    }
    if (lastName.length < 2) {
      dispatch(
        registerActions.setLastNameErr(t("recoverpaswword.lastNamerequ")));
      isValid = false;
    }
    if (phone.replaceAll(' ', '').length !== 13) {
      dispatch(
        registerActions.setPhoneErr(t("recoverpaswword.invalidPhone")));
      isValid = false;
    }
    //  if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{6,}$/.test(password1)) {
    //   dispatch(registerActions.setPassword1Err(t('recoverpaswword.passwordcomplex')));
    //   }
      if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{6,}$/.test(password2)) {
        dispatch(registerActions.setPassword2Err(t('recoverpaswword.passwordcomplex')));
        } 
    if (password1 !== password2) {
      dispatch(registerActions.setPassword2Err(t("recoverpaswword.passwordmatch")));
      isValid = false;
    } else if (password1.length < 6) {
      dispatch(
        registerActions.setPassword1Err(t("recoverpaswword.passwordError"))
      );
      isValid = false;
    } else if (password2.length < 6) {
      dispatch(
        registerActions.setPassword2Err(t("recoverpaswword.passwordError"))
      );
      isValid = false;
    }
    if (isValid) {
      sendRequest();
    }
  };
  const sendRequest = () => {
    // var code = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
    dispatch(registerActions.setIsLoading(true));
    dispatch(registerActions.setRegistrationSuccessful(false));
    const userData = {
      firstName: firstName,
      lastName: lastName,
      phone: phone.replaceAll(' ', ''),
      email: email,
      password: password1,
      address: address,
      currentlang:currentlang
      // code: code,

    }
    userServiceApi.registerUser(userData).then(
      (response) => {
        dispatch(registerActions.setIsLoading(false));
        if(response.data.strongpassword){
          registerActions.setPassword1Err(t('recoverpaswword.passwordcomplex'));
        }
        else if (response.data.accountexist) {
          dispatch(registerActions.setEmailErr("Account already exists try to login!"));
        }
        else if (response.data.success) {
          dispatch(rightDrawerActions.hideDrawer());
          dispatch(registerActions.setRegistrationSuccessful(true));
          document.body.style.overflow = "visible";
        } else if (response.data.errs) {
          if (response.data.errs === "email duplicated credentials!.") {
            dispatch(registerActions.setEmailErr("Email already exists"));
          }
        }
        else if(response.data.emailnotsent){
          dispatch(registerActions.setEmailErr("Account not created try again!."));
        }
      },
      (error) => {
        dispatch(registerActions.setError(error));
        dispatch(registerActions.setIsLoading(false));
      }
    );
    // var urlLink = "web-shop.adeymeselesh.de/"
    // var link = "https://" + urlLink + "verify/" + code;
    // var emaildata = {
    //   from: "dev@adeymailserver.de",
    //   to: email,
    //   cc: "yemie008@gmail.com",
    //   bcc: "adeymeselesh@gamil.com",
    //   subject: "Bitte bestätigen Sie die Email von Adey Spices",
    //   text: "<p><b>Sehr geehrte Damen und Herren</b>,</p><br> Schön, dass Sie sich für die Produkte von Adey Spices interessieren.<br> Um Bestellungen tätigen zu können, bitte wir Sie auf den folgenden Link zu klicken, um ihren <br> Account zu bestätigen.<br><a href=" + link + ">Bitte hier klicken</a><br>Wir wünschen Ihnen ein Leben voller Würze!<br>Herzliche Grüße,Ihr Adey Spices-Team",
    // }
    // if(currentlang=="en"){
    //   emaildata = {
    //     from: "dev@adeymailserver.de",
    //     to: email,
    //     cc: "yemie008@gmail.com",
    //     bcc: "adeymeselesh@gamil.com",
    //     subject: "Please confirm the email from Adey Spices",
    //     text: "<p><b>Dear Sir or Madam</b>,</p><br> Thank you for your interest in Adey Spices products.<br> In order to be able to place orders, we ask you to click on the following link to place your order <br> to confirm account.<br><a href=" + link + ">Please click here</a><br>We wish you a life full of spice!<br>Kind regards, Your Adey Spices team",
    //   }
    // }
    // userServiceApi.sendEmailUser(emaildata).then(
    //     (response) => {
    //     },
    //     (error) => {
    //       dispatch(registerActions.setError(error));
    //     });
  };
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <div className="register">
      <div className="header">
        <div className="text">{headerText}</div>
        <div className="close-drawer" onClick={closeRightModal}>
          {" "}
          <Close />{" "}
        </div>
      </div>
      <form onSubmit={validate}>
        <div className="form-grp">
          <div className="form-error">{emailErr}</div>
          <label htmlFor="firstName" >{t('profile.FirstName')}</label>
          <input
            className="input"
            type="text"
            id="firstName"
            required
            value={firstName}
            onChange={(e) => {
              dispatch(registerActions.setFirstName(e.target.value));
            }}
          />
          <div className="form-error">{firstNameErr}</div>
        </div>
        <div className="form-grp">
          <label htmlFor="lastName">{t('profile.LastName')}</label>
          <input
            className="input"
            type="text"
            id="lastName"
            required
            value={lastName}
            onChange={(e) => {
              dispatch(registerActions.setLastName(e.target.value));
            }}
          />
          <div className="form-error">{lastNameErr}</div>
        </div>
        <div className="form-grp">
          <label htmlFor="phone">{t('profile.Phone')}</label>
          <input
            className="input"
            type="tel"
            id="phone"
            required
            value={phone}
            onChange={(e) => {
              dispatch(registerActions.setPhone(e.target.value));
            }}
            placeholder={t('profile.tel')}
          />
          <div className="form-error">{phoneErr}</div>
        </div>
        <div className="form-grp">
          <label htmlFor="email">{t('profile.Email')}</label>
          <input
            className="input"
            type="email"
            id="emailr"
            required
            value={email}
            onChange={(e) => {
              dispatch(registerActions.setEmail(e.target.value));
            }}
          />
        </div>
        <div className="form-grp">
          <label htmlFor="address">{t('profile.Address')}</label>
          <input
            className="input"
            type="text"
            id="address"
            required
            value={address}
            onChange={(e) => {
              dispatch(registerActions.setAddress(e.target.value));
            }}
          />
          <div className="form-error">{addressErr}</div>
        </div>
        {/* <div className="form-grp">
          <label htmlFor="postalcode">Postal Code</label>
          <input
            className="input"
            type="text"
            id="postalcode"
            required
            // value={postalcode}
            // onChange={(e) => {
            //   dispatch(registerActions.setAddress(e.target.value));
            // }}
          />
          <div className="form-error">{addressErr}</div>
        </div>
        <div className="form-grp">
          <label htmlFor="country">Country</label>
          <input
            className="input"
            type="text"
            id="country"
            required
            // value={country}
            // onChange={(e) => {
            //   dispatch(registerActions.setAddress(e.target.value));
            // }}
          />
          <div className="form-error">{addressErr}</div>
        </div>
        <div className="form-grp">
          <label htmlFor="city">City</label>
          <input
            className="input"
            type="text"
            id="city"
            required
            // value={city}
            // onChange={(e) => {
            //   dispatch(registerActions.setAddress(e.target.value));
            // }}
          />
          <div className="form-error">{addressErr}</div>
        </div>
        <div className="form-grp">
          <label htmlFor="streetNumber">Street Number</label>
          <input
            className="input"
            type="text"
            id="streetNumber"
            required
            // value={city}
            // onChange={(e) => {
            //   dispatch(registerActions.setAddress(e.target.value));
            // }}
          />
          <div className="form-error">{addressErr}</div>
        </div> */}
        <div className="form-grp">
          <label htmlFor="password1">{t('profile.Password')}</label>
          <input
            className="input"
            type="password"
            id="password1"
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            value={password1}
            required
            onChange={(e) => {
              dispatch(registerActions.setPassword1(e.target.value));
            }}
          />
          <div className="form-error">{password1Err}</div>
        </div>
        <div className="form-grp">
          <label htmlFor="password2">{t('profile.ConfirmPassword')}</label>
          <input
            className="input"
            type="password"
            id="password2"
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            required
            value={password2}
            onChange={(e) => {
              dispatch(registerActions.setPassword2(e.target.value));
            }}
          />
          <div className="form-error">{password2Err}</div>
        </div>
        <div className="form-grp">
          {" "}
          <div className="loading" translate="no">{isLoading && <CircularProgress />}</div>
          <input type="submit" value={t('profile.signup')} className="btn" />
        </div>
      </form>
      <div className="bottomButtons">
      <div className="login-bottom">
        <div className="login-bottom-item">
          <div className="text">{t('profile.Alreadyregistered')}</div>
          <div
            className="link"
            onClick={() => {
              showRightDrawer("login");
            }}
          >
            {t('profile.Login')}
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};
export default Register;
