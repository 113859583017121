import { useSelector } from "react-redux";
import Login from "./login";
import Cart from "./cart";
import Register from "./register";
import Profile from "./profile";
import { useEffect } from "react";
const DrawerContent = () => {
  const type = useSelector((state) => state.rightDrawer.type);
 useEffect(() => {
 }, [type]);
  return (
    <div className="right-drawer-content">
      {type === "login" ? (
        <Login />
      ) : type === "profile" ? (
        <Profile/>
      ): type === "cart" ? (
        <Cart />
      ) : type === "register" ? (
        <Register />
      ) 
      : (
        ""
      )}
    </div>
  );
};

export default DrawerContent;
