import { rightDrawerSlice } from "../../slices/right_drawer";
import { useDispatch, useSelector } from "react-redux";
import { loginSlice } from "../../slices/login";
import { CircularProgress } from "@material-ui/core";
import { url } from "../../utils/url";
import { GoogleLoginButton } from "react-social-login-buttons";
import Close from "@material-ui/icons/Close";
import { useTranslation} from 'react-i18next';
import { loginServiceAPI } from "../serviceApi/loginServiceAPI";
import { cartSlice } from "../../slices/cart";
const Login = () => {
  const rightDrawerActions = rightDrawerSlice.actions;
  const loginActions = loginSlice.actions;
  const recoverPasswowrdActions = loginSlice.actions;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email, password } = useSelector((state) => state.login.inputValues);
  const { emailErr, passwordErr } = useSelector(
    (state) => state.login.inputErrors
  );
  const isLoading = useSelector((state) => state.login.isLoading);
const actions = rightDrawerSlice.actions;
const domainURL=window.location.href;
const closeRightModal = () => {
  dispatch(actions.hideDrawer());
  document.body.style.overflow = "visible";
};
const type = useSelector((state) => state.rightDrawer.type);
const cartActions = cartSlice.actions;
let headerText = "";
switch (type) {
  case "login":
    headerText= t('profile.Login')
    break;
    case "profile":
    headerText= t('profile.Profile')
    break;
  case "cart":
    headerText= t('profile.cart')
    break;
  case "register":
    headerText= t('profile.Register')
      break;
}
  const showRightDrawer = (type) => {
    dispatch(rightDrawerActions.setType(type));
    dispatch(rightDrawerActions.showDrawer());
    document.body.style.overflow = "hidden";
  };
  const showRecoverPassword = () => {
    dispatch(rightDrawerActions.hideDrawer())
    dispatch(recoverPasswowrdActions.setIsOpen(true))
    document.body.style.overflow = "hidden";
  }
  const validate = (e) => {
    e.preventDefault();
    dispatch(loginActions.setPasswordErr(""));
    dispatch(loginActions.setEmailErr(""));
    let isValid = true;
    if (password.length < 6) {
      dispatch(
        loginActions.setPasswordErr("Password should be atleast 6 characters!")
      );
      isValid = false;
    }
    if (isValid) {
      requestLogin();
    }
  };
  const savewoutLogincart = useSelector((state) => state.cart.savewoutLogincart);
  const requestLogin = () => {
    dispatch(loginActions.setIsLoading(true));
    const loginData={email: email,password: password,domainURL:domainURL}
    loginServiceAPI.postLogin(loginData).then(
        (response) => {
          if (response.data.emailFailure) {
            dispatch(loginActions.setEmailErr("Account does not exist!"));
          } else if (response.data.passwordFailure) {
            dispatch(loginActions.setPasswordErr("Incorrect email or password!"));
          } 
          else if(response.data.error){
            dispatch(loginActions.setPasswordErr("Too many login attempts from this IP, please try again later."));
          }
          else if(response.data.Notverified) {
            dispatch(loginActions.setEmailErr("Account not verified,go email to verify !"));
          }
          if (response.data.data) {
            if(savewoutLogincart){
              dispatch(rightDrawerActions.setType("profile"));
              showRightDrawer("cart");
              // return;
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("user_id", response.data.data.user_id);
              dispatch(loginActions.setIsUserLogged(true));
              dispatch(loginActions.setLoggedUser(response.data.data));
            }
            else{
              dispatch(rightDrawerActions.setType("profile"));
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("user_id", response.data.data.user_id);
              dispatch(loginActions.setIsUserLogged(true));
              dispatch(loginActions.setLoggedUser(response.data.data));
            }
            // dispatch(rightDrawerActions.setType("profile"));
            // localStorage.setItem("token", response.data.token);
            // localStorage.setItem("user_id", response.data.data.user_id);
            // dispatch(loginActions.setIsUserLogged(true));
            // dispatch(loginActions.setLoggedUser(response.data.data));
          
          }
          dispatch(loginActions.setIsLoading(false));
        },
        (error) => {
          dispatch(loginActions.setError(error));
          dispatch(loginActions.setIsLoading(false));
        }
      );
  };
  var cartItems = useSelector((state) => state.cart.cartItems);
  const googleAuth = () => {
 let jsonString = JSON.stringify(cartItems);
 localStorage.setItem("myArray", jsonString);
 let storedData = localStorage.getItem("myArray");
 localStorage.setItem("numberofProducts:",storedData.length);
 if(storedData.length>0){
   let retrievedArray = JSON.parse(storedData);
   retrievedArray.forEach(element => {
     dispatch(cartActions.addCartItem({ product: element.product, quantity: element.quantity }));
   });
 }
    window.location.href = `${url}auth/google`;
  }
  const handleChange = (e) => {
    e.preventDefault();
  };

return (
    <div className="login">
     <div className="header">
      <div className="text"><b>{t("profile.welcome")}</b></div>
      <div className="close-drawer" onClick={closeRightModal}>
        {" "}
        <Close />{" "}
      </div>
    </div>
    <div className="loginText">{headerText}</div>
      <form onSubmit={validate}>
        <div className="form-grp">
          <label htmlFor="email">{t('profile.Email')}</label>
          <input
            className="input"
            type="email"
            id="email1"
            required
            value={email}
            onChange={(e) => {
              dispatch(loginActions.setEmail(e.target.value));
            }}
          />
          <div className="form-error">{emailErr}</div>
        </div>
        <div className="form-grp">
          <label htmlFor="password">{t('profile.Password')}</label>
          <input
            className="input"
            type="password"
            id="password"
            onCut={handleChange}
           onCopy={handleChange}
           onPaste={handleChange}
            required
            value={password}
            onChange={(e) => {
              dispatch(loginActions.setPassword(e.target.value));
            }}
          />
          <div className="form-error">{passwordErr}</div>
        </div>
        <div className="form-grp">
          {" "}
          <div className="loading" translate="no">{isLoading && <CircularProgress />}</div>
          <input type="submit" value={t('profile.signin')} className="btn btn-block" />
        </div>
      </form>
      <div className="bottomButtons">
      <div className="login-bottom">
        <div className="login-bottom-item">
          <div className="text">{t('profile.Newcustomer')}</div>
          <div
            className="link"
            onClick={() => {
              showRightDrawer("register");
            }}
          >
            {t('profile.Createaccount')}
          </div>
        </div>
        <div className="login-bottom-item">
          <div className="text">{t('profile.Lostpassword')}</div>
          <div className="link" onClick={showRecoverPassword}>{t('profile.Recoverpassword')}</div>
        </div>
      </div>
      <div className="login-with">
        <GoogleLoginButton
          style={{ margin: ".71em 0 ", width: "100%" }}
          size="40px"
          onClick={() => googleAuth()}
        />
      </div>
      </div>
    </div>
  );
};
export default Login;
