import { createSlice } from "@reduxjs/toolkit";

export const teamSlice = createSlice({
    name:'team',
    initialState: {
        activeColumn:0,
        teams:[],
        areTeamsLoading:false,
        AboutAdy:[],
        areAboutAdyLoading:false,
        AboutAdvert:[],
        areAboutAdvertLoading:false,
        Aboutus:[],
        areAboutusLoading:false,
        policies:[],
        documentpolicies:[],
        arePoliciesLoading:false,
        error:""
    },
    reducers: {
        setActiveColumn:(state,action) => {
            state.activeColumn = action.payload;
        },
        setError:(state,action) => {
            state.error = action.payload;
        },
        setTeams:(state,action) => {
            state.teams = action.payload;
        },
        setAreTeamsLoading:(state,action) => {
            state.areTeamsLoading = action.payload;
        },
        setAboutAdy:(state,action) => {
            state.AboutAdy = action.payload;
        },
        setAreAboutAdyLoading:(state,action) => {
            state.areAboutAdyLoading = action.payload;
        },
        setAboutAdvert:(state,action) => {
            state.AboutAdvert = action.payload;
        },
        setAreAboutAdvertLoading:(state,action) => {
            state.areAboutAdvertLoading = action.payload;
        },
        setAboutus:(state,action) => {
            state.Aboutus = action.payload;
        },
        setAreAboutusLoading:(state,action) => {
            state.areAboutusLoading = action.payload;
        },
        setPolicies:(state,action) => {
            state.policies = action.payload;
        },
        setPoliciesDocument:(state,action) => {
            state.documentpolicies = action.payload;
        },
        setArePoliciesLoading:(state,action) => {
            state.arePoliciesLoading = action.payload;
        },
    }
});