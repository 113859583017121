import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { cartSlice } from "../../slices/cart";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { productsApi } from "../serviceApi/productsApi";
import { adminServiceApi } from "../serviceApi/adminServiceApi";
const SlipContent = () => {
  const dispatch = useDispatch();
  const cartActions = cartSlice.actions;
  const percentages = useSelector((state) => state.cart.percentages);
  const currencies = useSelector((state) => state.cart.currencies);
  const cartItems = useSelector(state => state.cart.cartItems)
  const codes = useSelector((state) => state.cart.copounCodes);
  const {
    couponCode
  } = useSelector((state) => state.cart.inputValues);
  const {
    couponCodeErr,
  } = useSelector((state) => state.cart.inputErrors);
  const isLoading = useSelector((state) => state.cart.isLoading);
  const getTotalPrice = () => {
    let total = 0;
    cartItems.forEach((cartItem) => {
      var replacePrice = cartItem.product.Price;
      replacePrice = replacePrice.replace(/,/g, '.')
      total += replacePrice * cartItem.quantity;
    });
    return parseFloat(total.toFixed(2));
  };
  const getTotalTax = () => {
    let total = 0;
    let vat = percentages.percentage / 100;
    cartItems.forEach((cartItem) => {
      var replacePrice = cartItem.product.Price;
      replacePrice = replacePrice.replace(/,/g, '.')
      total += replacePrice * vat * cartItem.quantity;
    });
    return parseFloat(total.toFixed(2));
  };
  const validate = (e) => {
    e.preventDefault();
    dispatch(cartActions.setcouponCodeErr(""));
    let isValid = true;
    if (couponCode.length < 1) {
      dispatch(
        cartActions.setcouponCodeErr(
          "Coupon Code is incorrect!"
        )
      );
      isValid = false;
    }
    if (isValid) {
      sendRequest();
    }
  };
  const sendRequest = () => {
    dispatch(cartActions.setIsLoading(true));
    const userId = localStorage.getItem("user_id");
    const data = { couponCode: couponCode, userId: userId };
    productsApi.postCoupon(data).then(
        (response) => {
          dispatch(cartActions.setIsLoading(false));
          if (response.data.result) {
            dispatch(cartActions.setcopounCodes(response.data.result));
            dispatch(cartActions.setCouponSucessMessage("Coupon Added Successfully !."))
            toast.info("Coupon Added Successfully !. ", {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
          else if (response.data.failer == "true") { dispatch(cartActions.setcouponCodeErr("Coupon Code is incorrect!.")); }
          else {
            toast.info("Copoun Code is Incorrect !. ", {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return;
          }
        },
        (error) => {
          dispatch(cartActions.setError(error));
          dispatch(cartActions.setIsLoading(false));
        }
      );
  };
  useEffect(() => {
    productsApi.getmethod("getPercentageDiscount").then(
      (response) => {
        dispatch(cartActions.setPercentageDiscounts(response.data.result));
      },
      (error) => {
         dispatch(cartActions.setError(error));
      }
    );
    adminServiceApi.getmethod("getLatestCurrency").then(
      (response) => {
        dispatch(cartActions.setCurrencies(response.data.currency));
      },
      (error) => {
         dispatch(cartActions.setError(error));

      }
    );
  }, []);
  const GRANDTOTALf = getTotalTax() + getTotalPrice();
  const GRANDTOTALf2 = parseFloat(GRANDTOTALf.toFixed(2));
  const { t } = useTranslation();
  const couponSucessMessage = useSelector((state) => state.cart.couponSucessMessage);
  return (
    <div className="slip-content">
      <table className="table table-bordered">
        <thead><div class="headerTitle">{t('profile.OrderSummary')}</div></thead>
        <thead>
          <tr>
            <th scope="col">{t('profile.Name')}</th>
            <th scope="col">{t('profile.Price')}</th>
            <th scope="col">{t('profile.Quantity')}</th>
            <th scope="col">{t('profile.Total')}</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map(item => {
            var replacePrice = item.product.Price;
            replacePrice = replacePrice.replace(/,/g, '.')
            const itemPrice = replacePrice;
            const totalPrice = itemPrice * item.quantity;
            const totalpricef = parseFloat(totalPrice.toFixed(2));
            return <tr>
              <td>{item.product.Name}</td>
              <td>{currencies.CurrencySymbol}{item.product.Price}</td>
              <td>{item.quantity}</td>
              <td translate="no">{currencies.CurrencySymbol}{totalpricef}</td>
            </tr>
          })}
        </tbody>
      </table>
      <Row>
        <Col lg={6}>
          <div className="coupon">
            <form onSubmit={validate}>
              <div className="form-grp">
                <input
                  className="input"
                  type="text"
                  id="couponCode"
                  required
                  value={couponCode}
                  onChange={(e) => {
                    dispatch(cartActions.setCouponCode(e.target.value));
                  }}
                  placeholder="Enter copoun code here"
                />
                <label><input type="submit" value="APPLY COUPON" className="" /></label>
                <div className="form-error">{couponCodeErr}</div>
              </div>
              <div className="sucessmessage">{couponSucessMessage}</div>
            </form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="bottom-right">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th scope="row">{t('profile.SUBTOTAL')}</th>
                  <td>{currencies.CurrencySymbol}{getTotalPrice()}</td>
                </tr>
                <tr>
                  <th scope="row">{t('profile.VAT')}</th>
                  <td>{currencies.CurrencySymbol}{getTotalTax()}</td>
                </tr>
                <tr>
                  <th scope="row">{t('profile.GRANDTOTAL')}</th>
                  <td translate="no">{currencies.CurrencySymbol}{GRANDTOTALf2}</td>
                </tr>
              </tbody>
            </table>
          </div></Col></Row>
    </div>
  );
};

export default SlipContent;
