import React, { useRef, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel2";
import { Col } from "react-bootstrap";
import { productsSlice } from "../../slices/products";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import ProductCard from "../product-card/product.component";
import { useMediaQuery } from "react-responsive";
import { productsApi } from "../serviceApi/productsApi";
import { userServiceApi } from "../serviceApi/userServiceApi";
import {loginSlice} from "../../slices/login"
import '../scss-styles/productSlider.scss'
const ProductsSlider = () => {
  const productsActions = productsSlice.actions;
  const loginActions = loginSlice.actions;
  const car = useRef(null);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(0)
  useEffect(() => {
    dispatch(loginActions.setLoadError(false));
    productsApi.getmethod("getPopularProducts").then(
      (response) => {
        if (response.data.products)
          dispatch(productsActions.setPopularProducts(response.data.products));
      },
      (error) => {
        dispatch(loginActions.setLoadError(true));
        dispatch(productsActions.setError(error)); }
    );
  }, []);
  const products = useSelector((state) => state.products.Popularproducts);
  useEffect(() => {
    dispatch(productsActions.setIsLoadingPopular(false));
    products.forEach((product) => {
      userServiceApi.getParamMethod("getPopularProductImages", product.id).then(
        (response) => {
          dispatch(productsActions.setIsLoadingPopular(true));
          setloading(1)
          dispatch(
            productsActions.addPopularProductImages({
              id: product.id,
              photoId: response.data.productImages[0].photoId,
              productImage: response.data.productImages[0].product_image,
              Category_id: product.Category_id,
              Name: product.Name,
              Description: product.Description,
              enName: product.enName,
              enDescription: product.enDescription,
              Price: product.Price,
              uploadedBy: product.uploadedBy,
              date: product.date,
              color: product.color,
              size: product.size,
              quantity: product.quantity,
              totalRate: product.totalRate,
              enme:product.enName,
              deme:product.Name
            })
          );
        },
        (error) => { }
      );
    });

  }, [products]);
  var options = {
    items: 4,
    nav: true,
    loop: true,
    autoplay: true,
    touchDrag: true,
    mergeFit: true,
    autoplayTimeout: 5000,
    margin: 20,
    responsiveRefreshRate: 200,
    navText: [
      '<svg class="right-arrow fill-Green"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
      ' <svg class="left-arrow fill-Green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
    dots: false,
  };
  var PopularproductImages = useSelector((state) => state.products.PopularproductImages);
  const isLoadingPopular = useSelector((state) => state.products.isLoadingPopular);
  const md1 = useMediaQuery({ query: "(max-width: 600px)" });
  const md2 = useMediaQuery({ query: "(max-width: 1368px)" });
  const tblmin = useMediaQuery({ query: "(min-width: 601px)" });
  const maxtblt = useMediaQuery({ query: "(max-width: 900px)" });
  const mediumtblmin = useMediaQuery({ query: "(min-width: 699px)" });
  const meduimmaxtblt = useMediaQuery({ query: "(max-width: 820px)" });
  const xltblmin = useMediaQuery({ query: "(min-width: 821px)" });
  const xlmaxtblt = useMediaQuery({ query: "(max-width: 940px)" });
  if(tblmin && maxtblt){
     options = {
      // items: 2,
      nav: true,
      loop: true,
      autoplay: true,
      touchDrag: true,
      mergeFit: true,
      autoplayTimeout: 5000,
      margin: 20,
      responsiveRefreshRate: 200,
      navText: [
        '<svg class="black-right-arrow fill-Green"style="width: 40px;transform: translate(-130px, -200px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
        ' <svg class="black-left-arrow fill-Green" style="width: 40px;transform: translate(130px, -200px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
      ],
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 2,
        },
        900: {
          items: 2,
        },
      },
      dots: false,
    };
  }
  if(md1){
    options = {
     // items: 2,
     nav: true,
     loop: true,
     autoplay: true,
     touchDrag: true,
     mergeFit: true,
     autoplayTimeout: 5000,
    //  margin: 20,
     responsiveRefreshRate: 200,
    //  navText: [
    //    '<svg class="black-right-arrow fill-Green"style="width: 12%;transform: translate(-190%, -200px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
    //    ' <svg class="black-left-arrow fill-Green" style="width: 12%;transform: translate(189%, -200px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
    //  ],
       navText: [
       '<svg class="black-right-arrow fill-Green"style="width: 12%;transform: translate(-175%, -220px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
       ' <svg class="black-left-arrow fill-Green" style="width: 12%;transform: translate(220%, -220px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
     ],
     responsive: {
       0: {
         items: 1,
       },
       600: {
         items: 1,
       },
       900: {
         items: 1,
       },
     },
     dots: false,
   };
 }
  if(mediumtblmin && meduimmaxtblt){
    options = {
     // items: 2,
     nav: true,
     loop: true,
     autoplay: true,
     touchDrag: true,
     mergeFit: true,
     autoplayTimeout: 5000,
     margin: 20,
     responsiveRefreshRate: 200,
     navText: [
       '<svg class="black-right-arrow fill-Green"style="width: 40px;transform: translate(-160px, -200px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
       ' <svg class="black-left-arrow fill-Green" style="width: 40px;transform: translate(160px, -200px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
     ],
     responsive: {
       0: {
         items: 2,
       },
       600: {
         items: 2,
       },
       900: {
         items: 2,
       },
     },
     dots: false,
   };
 }
 if(xltblmin && xlmaxtblt){
  options = {
   // items: 2,
   nav: true,
   loop: true,
   autoplay: true,
   touchDrag: true,
   mergeFit: true,
   autoplayTimeout: 5000,
   margin: 20,
   responsiveRefreshRate: 200,
   navText: [
     '<svg class="black-right-arrow fill-Green"style="width: 40px;transform: translate(-180px, -200px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>',
     ' <svg class="black-left-arrow fill-Green" style="width: 40px;transform: translate(180px, -200px);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>',
   ],
   responsive: {
     0: {
       items: 2,
     },
     600: {
       items: 2,
     },
     900: {
       items: 2,
     },
   },
   dots: false,
 };
}
  // if (md1) {
  //   options.items = 1;
  //   options.navText[0] = '<svg class="black-right-arrow fill-black"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/></svg>'
  //   options.navText[1] = ' <svg class="black-left-arrow fill-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/></svg>'
  // }
  if(md2){
    options.items=3
    options.responsive[1000]=3
  }
  const currentlang = localStorage.getItem("lang");
  if (currentlang == "en") {
    PopularproductImages = JSON.parse(JSON.stringify(PopularproductImages).split('"enName":').join('"Name":'));
    PopularproductImages = JSON.parse(JSON.stringify(PopularproductImages).split('"enDescription":').join('"Description":'));
  }
  return (
    <div className="productSlider">
      {loading === 0 ? <b>{<CircularProgress />}</b> :
        <OwlCarousel ref={car}
          options={options}
          className="rel">
          {PopularproductImages.length === 0
            ? <div className="aa">
            </div>
            : PopularproductImages.map((product) => {
              return (
                <Col key={product.photoId}>
                  <ProductCard
                    prid={product.id}
                    name={product.Name}
                    price={product.Price}
                    totalRate={product.totalRate}
                    product_image={product.productImage}
                    Category_id={product.Category_id}
                    details={product}
                  />
                </Col>
              );
            })}
        </OwlCarousel>
      }
    </div>
  );
};
export default ProductsSlider;
