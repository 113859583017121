import Dimmer from "../utils/dimmer";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import {loginSlice} from "../../slices/login"
import { Col, Row } from "react-bootstrap";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';
const ConcentMessage = () => {
  const xs = useMediaQuery({ query: "(max-width: 576px" });
  const sm = useMediaQuery({ query: "(min-width: 576px)" });
  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const lg = useMediaQuery({ query: "(min-width: 992px)" });
  const xl = useMediaQuery({ query: "(min-width: 1200px)" });
  let width = "inherit";
  if (xl) width = "75%";
  else if (lg) width = "75%";
  else if (md) width = "75%";
  else if (sm) width = "75%";
  else if (xs) width = "100%";
  const style = {
    width: width,
    height: "15%"
  };
  const loginActions = loginSlice.actions;
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(loginActions.setConcentError(true));
    localStorage.setItem("concent.accepted",false);
    localStorage.setItem("concent.acceptedval","aaa");
  };
  const gaEventTracker = useAnalyticsEventTracker('Concent Accepted');
  const acceptModal = () => {
    dispatch(loginActions.setConcentError(true));
    localStorage.setItem("concent.accepted",true);
    localStorage.setItem("concent.acceptedval","aaa");
    gaEventTracker('Accepted');
  };
const { t } = useTranslation();  
const localstorageConcent=localStorage.getItem("concent.accepted");
const lang=localStorage.getItem("lang");
const mobile = useMediaQuery({ query: "(max-width: 1310px)" });
const mobile2=useMediaQuery({ query: "(max-width: 600px)" });
return (
    <>
      <Dimmer />
      {mobile2 ?<>
      <div className="concentMessageMobile">
        <div className="bottom">
            <Row>
            <Col sm={8} lg={8}>
          <div className="text">
            <p>
              {" "}
              <div>{t('failureMessages.concentMessage1')} <a href={t('failureMessages.privacyPolicylink')} >{t('failureMessages.concentMessage2')}</a>{t('failureMessages.concentMessage3')}</div>
            </p>
          </div>
          </Col>
          <Col sm={4} lg={4} xs={4}>
            {lang==="de" ?<div className="continuedesmall">
            <div className="continue accept" onClick={acceptModal}>
            {t('failureMessages.accept')}
            </div>
            <div className="continue deny" onClick={closeModal}>
            {t('failureMessages.deny')}
            </div>
          </div>:
          <div className="continuesmall">
            <div className="continue accept" onClick={acceptModal}>
            {t('failureMessages.accept')}
            </div>
            <div className="continue deny" onClick={closeModal}>
            {t('failureMessages.deny')}
            </div>
          </div>
               }
          </Col>
          </Row>
        </div>
      </div>
      </>:<>
      {mobile ?<div className="concentMessageMobile">
        <div className="bottom">
            <Row>
            <Col sm={8} lg={8}>
          <div className="text">
            <p>
              {" "}
           {lang==="de"?"":""}   <div >{t('failureMessages.concentMessage1')} <a href={t('failureMessages.privacyPolicylink')} >{t('failureMessages.concentMessage2')}</a>{t('failureMessages.concentMessage3')}</div>
            </p>
          </div>
          </Col>
          <Col sm={1} lg={1} xs={1}></Col>
          <Col sm={3} lg={3} xs={3}>
            {lang==="de" ?<div className="continuede" style={{paddingTop:"20px"}}>
            <div className="continue accept" onClick={acceptModal}>
            {t('failureMessages.accept')}
            </div>
            <div className="continue deny" onClick={closeModal} >
            {t('failureMessages.deny')}
            </div>
          </div>:
          <div className="continue">
            <div className="continue accept" onClick={acceptModal}>
            {t('failureMessages.accept')}
            </div>
            <div className="continue deny" onClick={closeModal}>
            {t('failureMessages.deny')}
            </div>
          </div>
               }
          </Col>
          </Row>
        </div>
      </div>:
      <div className="concentMessage" style={style}>
        <div className="bottom">
            <Row>
            <Col sm={8} lg={8}>
          <div className="text">
            <p>
              {" "}
              {lang==="de"?<div style={{paddingTop:"20px"}}>{t('failureMessages.concentMessage1')} <a href={t('failureMessages.privacyPolicylink')} >{t('failureMessages.concentMessage2')}</a>{t('failureMessages.concentMessage3')}</div>:<div>{t('failureMessages.concentMessage1')} <a href={t('failureMessages.privacyPolicylink')} >{t('failureMessages.concentMessage2')}</a>{t('failureMessages.concentMessage3')}</div>}
            </p>
          </div>
          </Col>
          <Col sm={1} lg={1} xs={1}></Col>
          <Col sm={3} lg={3} xs={3}>
            {lang==="de" ?<div className="continuede">
            <div className="continue accept" onClick={acceptModal}>
            {t('failureMessages.accept')}
            </div>
            <div className="continue deny" onClick={closeModal}>
            {t('failureMessages.deny')}
            </div>
          </div>:
          <div className="continue">
            <div className="continue accept" onClick={acceptModal}>
            {t('failureMessages.accept')}
            </div>
            <div className="continue deny" onClick={closeModal}>
            {t('failureMessages.deny')}
            </div>
          </div>
               }
          </Col>
          </Row>
        </div>
      </div>}</>}{" "}
    </>
  );
};

export default ConcentMessage;
