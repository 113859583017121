import { Col, Row } from "react-bootstrap";
import { faTrashAlt, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cartSlice } from "../../slices/cart";
import { useDispatch,useSelector } from "react-redux";
const CartItem = ({ cartItem,productImage }) => {
  const currencies = useSelector((state) => state.cart.currencies);
  const removeIcon = <FontAwesomeIcon icon={faTrashAlt} />;
  const plusIcon = <FontAwesomeIcon icon={faPlus} />;
  const minusIcon = <FontAwesomeIcon icon={faMinus} />;
  const cartActions = cartSlice.actions;
  const dispatch = useDispatch();
  const removeCartItem = (id) => {
    dispatch(cartActions.removeCartItem(id));
  };
  const increaseQuantity = (id) => {
    dispatch(cartActions.increaseQuantity(id));
  };
  const decreaseQuantity = (id) => {
    dispatch(cartActions.decreaseQuantity(id));
  };
  var replacePrice = cartItem.product.Price;
  replacePrice = replacePrice.replace(/,/g, '.')
  const totalPrice=replacePrice;
  const totalPricef1=totalPrice * cartItem.quantity
  const totalPricef=parseFloat(totalPricef1.toFixed(2));
  return (
    <div className="cart-item">
      <Row>
        <Col lg={4}>
          <div className="cartProductlistm">
            <img
              className="img-fluid"
              src={productImage}
              alt=""
            />
            <div className="product-left">
              <div className="product-title">{cartItem.product.Name}</div>
              <div
                className="remove icon"
                onClick={() => {
                  removeCartItem(cartItem.product.id);
                }}
              >
                {removeIcon}
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="weight">{cartItem.product.size}</div>
        </Col>
        <Col>
          <div className="price">{currencies.CurrencySymbol}{cartItem.product.Price}</div>
        </Col>
        <Col>
          <div className="quantity">
            <div className="inner">
              {" "}
              <div
                className="minus icon"
                onClick={() => {
                  decreaseQuantity(cartItem.product.id);
                }}
              >
                {minusIcon}
              </div>
              <div className="amount">{cartItem.quantity} </div>
              <div
                className="plus icon"
                onClick={() => {
                  increaseQuantity(cartItem.product.id);
                }}
              >
                {plusIcon}
              </div>
            </div>
          </div>
        </Col>
        <Col>
        <div className="total"translate="no">{currencies.CurrencySymbol}{totalPricef}</div>
        </Col>
      </Row>
    </div>
  );
};
export default CartItem;
