import CartList from "./cart_list";
import Title from "./title";
import { useSelector, useDispatch } from "react-redux";
const Cart = () => {
    const cartItems = useSelector((state) => state.cart.cartItems);
    return (
        <>
        {cartItems.length===0?<div className="cartNodata">
             <Title/>
           <CartList/>
        </div>:
        <div className="cart">
             <Title/>
           <CartList/>
        </div>} </> 
    );
}
export default Cart;