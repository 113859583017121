import Title from "./title"
import PrivacyContent from './privacyContent'
const Privacy= (props) => {
     const location = props.location.pathname
     const route = location.substring(location.lastIndexOf('/') + 1)
    return <div className="category">
       <Title title={route}/>
       <PrivacyContent category={route}/>
    </div>
}
export default Privacy;