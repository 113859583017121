import React, {useEffect,useState } from "react";
import { Col} from "react-bootstrap";
import { productsSlice } from "../../slices/products";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import NewProductCard from "../newproduct-card/product.component";
import { CircularProgress } from "@material-ui/core";
import { userServiceApi } from "../serviceApi/userServiceApi";
import { useTranslation} from 'react-i18next';
import {loginSlice} from "../../slices/login"
import '../scss-styles/newProducts.scss'
import { useMediaQuery } from "react-responsive";
const NewProducts = () => {
  const history = useHistory();
  const productsActions = productsSlice.actions;
  const loginActions = loginSlice.actions;
  const dispatch = useDispatch();
  const [loading ,setloading] = useState(0)
    useEffect(() => {
    dispatch(productsActions.setIsLoading(false));
    dispatch(loginActions.setLoadError(false));
    userServiceApi.getmethod("getAllProducts/0").then(
      (response) => {
        dispatch(productsActions.setIsLoading(true));
        if(response.data.products){
          setloading(1)
        dispatch(productsActions.setProducts(response.data.products));
        }
      },
      (error) => {
        dispatch(loginActions.setLoadError(true));
        dispatch(productsActions.setError(error));
      }
    );
  }, []);
  var products = useSelector((state) => state.products.products);
  const isLoading = useSelector((state) => state.products.isLoading);
  const { t } = useTranslation();
  const currentlang=localStorage.getItem("lang");
  if(currentlang=="en"){
    products = JSON.parse(JSON.stringify(products).split('"enName":').join('"Name":'));
    products = JSON.parse(JSON.stringify(products).split('"enDescription":').join('"Description":'));   
  }
  // const languages = useSelector((state) => state.rightDrawer.languages);
  const languages=localStorage.getItem("lang");
  const md1 = useMediaQuery({ query: "(min-width: 600px)" });
  const md2 = useMediaQuery({ query: "(max-width: 1000px)" });
  const md = md1 &&md2;
  return (
    <div className="newProducts">
      <h4> 
       {t('newproducts.Unsere')}</h4>
       {md ?<div className="ipadproduct-list">
        {loading===0 
          ? <b>{ <CircularProgress />}</b>
          : products.map((product) => {
            return (
              <Col key={product.id} >
                <NewProductCard
                    details={product}
                />
              </Col>
            );
          })}
      </div>:<div className="product-list">
        {loading===0 
          ? <b>{ <CircularProgress />}</b>
          : products.map((product) => {
            return (
              <Col key={product.id} >
                <NewProductCard
                    details={product}
                />
              </Col>
            );
          })}
      </div>}
      <button
        onClick={() => {
          if(languages==="de"){
            history.push("/Gewürze");
          }
          else{history.push("/shop");}
        }}>
         {t('newproducts.Zuallen')}
      </button>
    </div>
  );
};

export default NewProducts;
