import axios from "axios";
import { url} from "../../utils/url";
import { configMode } from "./configMode";
const productsApi={ 
   getmethod:(route) => axios.get(`${url}product/${route}`),
   postmethod:(route,data)=>axios.post(`${url}product/${route}`),
   postmethods:(data)=>axios.post(`${url}product/createProductRating`,data),
   getParamMethod:(route,param) => axios.get(`${url}product/${route}/${param}`),
   postReview:(data)=>axios.post(`${url}product/createProductReview`,data,configMode),
   postCoupon:(data)=>axios.post(`${url}product/checkCouponCode`,data,configMode),
   getProduct:(product_id,data)=>axios.get(`${url}product/getProduct/${product_id}`,data),
   getCarts:(userId)=>axios.get(`${url}user/getCarts/${userId}`),
   getProductImages:(productID)=>axios.get(`${url}user/getProductImages/${productID}`),
   getProductDetail:(product_id)=>axios.get(`${url}product/getProduct/${product_id}`),
   getProductDetailImages:(productID)=>axios.get(`${url}user/getProductImages/${productID}`),
}
export {productsApi};