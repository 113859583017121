    import { useSelector, useDispatch } from "react-redux";
    import CartItem from "./cart-item";
    import CartListHeader from "./cart_list_header";
    import { useMediaQuery } from "react-responsive";
    import cartSymbol from "../../new-assets/cartImage2.PNG";
    import FetchCartImages from "../utils/fetch_cart_images";
    import { slipSlice } from "../../slices/slip";
    import { cartSlice } from "../../slices/cart";
    import { toast } from "react-toastify";
    import 'react-toastify/dist/ReactToastify.css'
    import { useEffect } from "react";
    import { useHistory } from "react-router";
    import { rightDrawerSlice } from "../../slices/right_drawer";
    import { useTranslation } from 'react-i18next';
    import { productsApi } from "../serviceApi/productsApi";
    import { userServiceApi } from "../serviceApi/userServiceApi";
    import { adminServiceApi } from "../serviceApi/adminServiceApi";
    const CartList = () => {
      const cartActions = cartSlice.actions;
      const currencies = useSelector((state) => state.cart.currencies);
      const checkUsers = useSelector((state) => state.cart.checkUsers);
      const percentages = useSelector((state) => state.cart.percentages);
      const cartItems = useSelector((state) => state.cart.cartItems);
      const isUserLogged = useSelector((state) => state.login.isUserLogged);
      const showHeader = useMediaQuery({ query: "(min-width: 992px)" });
      const productImages = useSelector((state) => state.cart.productImages);
      const slipActions = slipSlice.actions;
      const rightDrawerActions = rightDrawerSlice.actions;
      const history = useHistory();
      const dispatch = useDispatch();
      const getTotalPrice = () => {
        let total = 0;
        cartItems.forEach((cartItem) => {
          var replacePrice = cartItem.product.Price;
          replacePrice = replacePrice.replace(/,/g, '.')
          total += replacePrice * cartItem.quantity;
        });
        return parseFloat(total.toFixed(2));
      };
      const getTotalTax = () => {
        let total = 0;
        let vat = percentages.percentage / 100;
        cartItems.forEach((cartItem) => {
          var replacePrice = cartItem.product.Price;
          replacePrice = replacePrice.replace(/,/g, '.')
          total += replacePrice * vat * cartItem.quantity;
        });
        return parseFloat(total.toFixed(2));
      };
      const showRightDrawer = (type) => {
        dispatch(rightDrawerActions.showDrawer());
        dispatch(rightDrawerActions.setType(type));
        document.body.style.overflow = "hidden";
      };
      useEffect(() => {
        // if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
        //   history.push("/");
        //   showRightDrawer("login");
        //   return;
        // } else {
          const userId = localStorage.getItem("user_id");
          productsApi.getmethod("getPercentageDiscount").then(
            (response) => {
              dispatch(cartActions.setPercentageDiscounts(response.data.result));
            },
            (error) => {
              dispatch(cartActions.setError(error));
            }
          );
          adminServiceApi.getmethod("getLatestCurrency").then(
            (response) => {
              dispatch(cartActions.setCurrencies(response.data.currency));
            },
            (error) => {
              dispatch(cartActions.setError(error));
            }
          );
          userServiceApi.getParamMethod("getcheckUserId", userId).then(
            (response) => {
              dispatch(cartActions.setCheckuserId(response.data.check));
            },
            (error) => {
              dispatch(cartActions.setError(error));
            }
          );
        // }
      }, []);
      const openSlip = () => {
        // if (!isUserLogged) {
        //   toast.info("Login or register to purchase ! ", {
        //     position: "top-left",
        //     autoClose: 2000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        //   return;
        // }
            if (!localStorage.getItem("user_id") || !localStorage.getItem("token")) {
              history.push("/");
              showRightDrawer("login");
              return;
            } else {
        const userId = localStorage.getItem("user_id");
        const cartItemsForDb = [];
        cartItems.forEach((cartItem) => {
          cartItemsForDb.push({
            user_id: userId,
            product_id: cartItem.product.id,
            quantity: cartItem.quantity,
          });
        });
        dispatch(cartActions.setSavingCart(true));
        userServiceApi.postmethod("createCart", cartItemsForDb).then(
          (response) => {
            if (response.data.success) {
              const cartData = { cartItemsForDb }
              userServiceApi.saveCart(cartData).then(
                (response) => {
                  if (checkUsers) {
                    toast.info("First You have to make payment for the last time you Ordered!! ", {
                      position: "top-left",
                      autoClose: 20000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    return;
                  }
                  else if (response.data.success) {
                    toast.info("Order successfully submitted!", {
                      autoClose: 20000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    return
                  }
                  dispatch(cartActions.setSavingCart(false));
                },
                (error) => {
                  dispatch(cartActions.setError(error));
                }
              );
            }
            dispatch(cartActions.setSavingCart(false));
          },
          (error) => {
            dispatch(cartActions.setError(error));
          }
        );
        dispatch(slipActions.setIsOpen(true))
        document.body.style.overflow = 'hidden';
      }
    }
      /////to close modal after click on free env't
      // var images_order = document.getElementById('ordermodalid');
      // window.onclick = function (event) {
      //   if (event.target == images_order) {
      //     dispatch(slipActions.setIsOpen(true))
      //   }
      //   else if (event.target != images_order) {
      //     dispatch(slipActions.setIsOpen(false))

      //   }

      // }
      const GRANDTOTALf = getTotalTax() + getTotalPrice();
      const GRANDTOTALf2 = parseFloat(GRANDTOTALf.toFixed(2));
      const { t } = useTranslation();
      const languages=localStorage.getItem("lang");
      return (
        <>
          <FetchCartImages />
          <div className="cart-list">
            {cartItems.length === 0 ? "" : showHeader ? <CartListHeader /> : ""}
            {cartItems.map((cartItem) => {
              let productImage = "";
              productImages.forEach((item) => {
                if (item.product_id === cartItem.product.id)
                  productImage = item.product_image;
              });
              return <CartItem cartItem={cartItem} productImage={productImage} />;
            })}
            <div className="pricing">
            {cartItems.length===0?<div className="Noitem">
               <img src={cartSymbol} alt="sefiegrui"  />
            <p>{t("cart.m1")}</p>
            <div className="nodataButton" onClick={() => {if(languages==="de"){
            history.push("/Gewürze");
          }
          else{history.push("/shop");}}}>
            {t("cart.noDataCartButton")}</div>
            </div>:
              <div className="items">
                {" "}
                <div className="item">
                  <div className="text" >{t('cart.SUBTOTAL')}</div>
                  <div className="price">{currencies.CurrencySymbol}{getTotalPrice()}</div>
                </div>
                <div className="item">
                  <div className="text">{t('cart.VAT')}</div>
                  <div className="price">{currencies.CurrencySymbol}{getTotalTax()} </div>
                </div>
                <div className="item">
                  <div className="text">{t('cart.GRANDTOTAL')}</div>
                  <div className="price" translate="no">
                    {currencies.CurrencySymbol}{GRANDTOTALf2}
                  </div>
                </div>
                <div className="item">
                  <div className="text"></div>
                  <div className="price">
                  {cartItems.length===0?<div className="placeOrder-btn" onClick={openSlip} id="ordermodalid" >{t('cart.PlaceOrder')}</div>:
                    <div className="adimera-btn " onClick={openSlip} id="ordermodalid" >{t('cart.PlaceOrder')}</div>}{" "}
                  </div>
                </div>
              </div>
              }{""}
            </div>
          </div>
          </>
      );
    };

    export default CartList;
